import React from "react";

const SwitchToggle = ({
  rounded = false,
  isToggled,
  setIsToggled,
  title = "",
  onChange,
}) => {
  const handleOnChange = () => {
    if (onChange) {
      onChange();
    }
    setIsToggled(!isToggled);
  };
  return (
    <label
      className="relative inline-block w-10 h-5"
      title={title ? title : ""}
    >
      <input
        type="checkbox"
        checked={isToggled}
        onChange={handleOnChange}
        className="opacity-0 w-0 h-0"
      />
      <span
        className={`absolute before:transition before:duration-300 cursor-pointer top-0 left-0 right-0 bottom-0 before:w-4 before:h-4 before:bg-white before:left-[2px] before:bottom-[2px] before:absolute before:content-['']  ${
          isToggled ? "before:translate-x-5 bg-[#2cccd3]" : "bg-[#ccc]"
        } ${rounded ? "before:rounded-[50%] rounded-[34px]" : ""}`}
      />
    </label>
  );
};

export default SwitchToggle;
