import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    width: "auto",
    height: "auto",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    width: "auto",
    height: "auto",
  },
}));

const BootstrapDialogTitle = ({ title, onClose, ...other }) => {
  return (
    <div>
      <div style={{ fontSize: 24, fontWeight: 600, color: "#2C3030" }}>
        {title}
      </div>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </div>
  );
};

const SoftDialog = ({
  open,
  handleClose,
  title,
  subTitle,
  children,
  ...rest
}) => {
  return (
    <div style={{ padding: 4 }}>
      <BootstrapDialog
        {...rest}
        sx={{ p: 4 }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          title={title}
        />
        <div style={{ fontSize: 16, fontWeight: 500, color: "#687A7D" }}>
          {subTitle}
        </div>
        {children}
      </BootstrapDialog>
    </div>
  );
};

export default SoftDialog;

SoftDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};
