import EditRowForm from "../../components/Form/EditRowForm";
import { updateBankDetails } from "../../api/api";
import { EditRowFormConfig } from "./formConfig";

const EditRowComponent = ({
  formData,
  setFormData,
  setUpdateRows,
  setShowEditRowModal,
}) => {
  const handleSubmit = async (e) => {
    try {
      setShowEditRowModal(false);
      await updateBankDetails(formData);
      setUpdateRows((prev) => !prev);
    } catch (error) {
      console.error(error);
      setShowEditRowModal(false);
    }
  };

  return (
    <EditRowForm
      formConfig={EditRowFormConfig}
      formData={formData}
      setFormData={setFormData}
      handleSubmit={handleSubmit}
    />
  );
};

export default EditRowComponent;
