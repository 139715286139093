import { useDroppable } from "@dnd-kit/core";
import React from "react";

const DroppableContainer = () => {
  const { isOver, setNodeRef } = useDroppable({ id: "droppable" });
  const style = {
    color: isOver ? "green" : undefined,
  };
  return (
    <div
      ref={setNodeRef}
      className="bg-[#E8F0F2] border=[12px] flex flex-col items-center gap-2 p-4"
    >
      <div
        className={`min-h-[180px] flex justify-center items-center border-[1px] w-full border-[#92A9AD] rounded ${
          isOver ? "bg-green-500" : "bg-[#E8F0F2]"
        }`}
      >
        <p className="font-semibold text-xl text-[#526163] capitalize">
          Drop here brand & coupon
        </p>
      </div>
    </div>
  );
};

export default DroppableContainer;
