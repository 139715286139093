import React, { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
	const email = ["social@estulife.com"];
	const [userEmail, setUserEmail] = useState();

	//check if user can access specific feature or route in future
	const canAccess = () => {
		const department = userDepartment(userEmail);
		if (department === "MARKETING") {
			return true;
		}
		return email.includes(userEmail) ? true : false;
	};

	const userDepartment = (email) => {
		switch (email || userEmail) {
			case "social@estulife.com":
				return "MARKETING";

			default:
				return "ADMIN";
		}
	};

	const value = {
		canAccess,
		userDepartment,
		setUserEmail,
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
