import classNames from "classnames";
import React, { useEffect, useState } from "react";
import DownloadCSV from "../../../../shared/downloadCSV/DownloadCSV";
import { CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const ContentBoard = ({
  stats,
  selectedStatus,
  setSelectedStatus,
  csvData,
  columns,
  loading,
}) => {
  const handleButtonClick = (status) => {
    // handle button click and update the state
    setSelectedStatus(status);
  };
  // console.log(stats);
  return (
    <div className="flex flex-col gap-9">
      <div className="flex flex-row justify-start items-center gap-4">
        {/* buttons */}
        {/* <ChipButton
          text={"All"}
          count={stats?.totalCount || 0}
          isSelected={selectedStatus === ""}
          onClick={() => handleButtonClick("")}
        />
        <ChipButton
          text={"Delivered"}
          count={stats?.deliveryCount || 0}
          isSelected={selectedStatus === "DELIVERED"}
          onClick={() => handleButtonClick("DELIVERED")}
        />
        <ChipButton
          text={"Pending"}
          count={stats?.pendingCount || 0}
          isSelected={selectedStatus === "PENDING"}
          onClick={() => handleButtonClick("PENDING")}
        />
        <ChipButton
          text={"Failed"}
          count={stats?.failedCount || 0}
          isSelected={selectedStatus === "FAILED"}
          onClick={() => handleButtonClick("FAILED")}
        /> */}
        <div className="justify-self-end flex-1 flex justify-end items-center">
          <DownloadCSV
            fileName={selectedStatus}
            columns={columns}
            data={csvData}
            fieldsToInclude={[
              "email",
              "phoneNumber",
              "headline",
              "smsMessage",
              "subject",
              "emailBody",
              "image",
              "status",
            ]}
          />
        </div>
      </div>
      <div>
        {loading ? (
          <div className="h-full flex flex-col justify-center items-center">
            <CircularProgress style={{ color: "#2cccd3" }} />
          </div>
        ) : (
          <ContentTable data={csvData} columns={columns} />
        )}
      </div>
    </div>
  );
};
const ChipButton = ({ count, text, isSelected, onClick }) => (
  <button
    className={classNames(
      "text-[#2c3030] cursor-pointer text-center hover:border-[#2cccd3] font-montserrat text-base font-semibold py-2.5 px-6 bg-white min-w-[169px] rounded-full  transition-all",
      {
        "border-2 border-[#2CCCD3] scale-105": isSelected,
        "border-2 border-white": !isSelected,
      }
    )}
    onClick={onClick}
  >
    {`${text}  (${count || 0})`}
  </button>
);
const ContentTable = ({ data = [], columns }) => {
  const [filter, setFilter] = useState("All");
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  //   console.log(data);

  useEffect(() => {
    setLoading(true);
    // setFilteredData(
    //   data.filter((row) =>
    //     filter === "All"
    //       ? true
    //       : row?.status?.toUpperCase() === filter?.toUpperCase()
    //   )
    // );
    setFilteredData(data);
    setLoading(false);
  }, [data, filter]);

  const pageSize = Math.min(10, filteredData.length);

  return (
    <div className="data-grid-container" style={{ height: 500, width: "100%" }}>
      {loading ? (
        <div className="flex items-center justify-center w-full h-full">
          <CircularProgress style={{ color: "#2cccd3" }} />
        </div>
      ) : (
        <DataGrid
          rows={filteredData}
          columns={columns}
          pageSize={pageSize}
          sx={{
            boxShadow: 2,
            border: 2,
            padding: 1,
            backgroundColor: "white",
            borderColor: "primary.light",
            "& .MuiDataGrid-cell:hover": {
              //   color: "primary.main",
            },
          }}
          pageSizeOptions={[5, 10, 15, 20]}
          getRowId={(row) => row?.id || row?.email}
        />
      )}
    </div>
  );
};

export default ContentBoard;
