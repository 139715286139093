import React, { useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import InputComponent from "../components/atoms/InputComponent";
import { addInterceptors, login } from "../api/api";
import { Loader } from "../components";
import { useNavigate } from "react-router-dom";
import SnackBar from "../components/atoms/Snackbar";
import { useAuth } from "../contexts/AuthContext";

const Login = () => {
  const { currentColor, isLoading, setIsLoading, setUser, setSnackbar } =
    useStateContext();
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();
  const { setUserEmail, userDepartment } = useAuth();

  const submitHandler = async (e) => {
    e.preventDefault();
    const email = e.target.email.value.trim().toLowerCase();
    const password = e.target.password.value.trim();

    if (!email || !password) {
      setErrorMsg("Provide an email and password.");
      return;
    }

    setIsLoading(true);
    try {
      const { data } = await login({
        email,
        password,
      });
      localStorage.setItem("token", data?.token);
      //   console.log(data?.data);
      await localStorage.setItem("user_name", data?.data?.user?.name);
      await localStorage.setItem("user_email", data?.data?.user?.email);

      addInterceptors();

      if (data.data.user) {
        setUser(data.data.user);

        let email = data.data.user.email;

        setUserEmail(email);

        const department = userDepartment(email);

        if (department === "ADMIN") {
          navigate("/ads");
        } else {
          navigate("/upload-csv");
        }
      }
    } catch (error) {
      console.log("error", error);
      setSnackbar({
        active: true,
        status: "error",
        message: error.response.data.message,
      });
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className="flex justify-center items-center min-h-screen mx-auto bg-slate-200">
        <div className="gap-9 border p-12 h-fit flex justify-center flex-col max-w-lg border-slate-800 bg-white">
          <div>
            <h2 className="text-2xl font-medium">
              Welcome to Estu Admin Panel
            </h2>
            <p className="mt-5 text-xl">Login to get started</p>
          </div>
          <form
            method="POST"
            className="flex flex-col gap-8"
            onSubmit={submitHandler}
          >
            <InputComponent
              label="Email:"
              type="email"
              name="email"
              id="email"
              placeholder="example@gmail.com"
              required
            />
            <InputComponent
              label="Password:"
              type="password"
              name="password"
              id="password"
              required
            />
            <button
              type="submit"
              style={{ backgroundColor: currentColor }}
              className="text-white mt-4 hover:drop-shadow-xl p-3 text-lg rounded-md font-semibold"
            >
              Log In
            </button>
            {errorMsg !== "" && (
              <span className="block text-red-800 font-semibold">
                {errorMsg}
              </span>
            )}
          </form>
        </div>
      </div>
      <SnackBar />
    </>
  );
};

export default Login;
