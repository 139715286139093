import React, { useState } from "react";
import GenericFormField from "../../../components/Form/FormFields";
import CustomButton from "../../../components/atoms/Button/Button";
import classNames from "classnames";
import AutoGoogleComplete from "../../../shared/AutoComplete/AutoGoogleComplete";
import { toast } from "react-toastify";

const EditRowFormNewUI = ({
  formConfig,
  formData,
  setFormData,
  cols = 2,
  handleSubmit,
  setShowApproveModel,
  setShowRejectionModel,
  setSelectedDataValue,
  row,
}) => {
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  const [showContractGroup, setShowContractGroup] = useState(false);

  const [showDeliveryGroup, setShowDeliveryGroup] = useState(false);

  const handleInputChange = (e) => {
    // console.log("handleInputChange bha", e);

    const { name, value } = e.target;
    if (name === "ein") {
      if (!/^\d*$/.test(value)) {
        toast.info("EIN should be a number");
        return;
      }
      if (value.length > 9) {
        toast.info("EIN should be a maximum of 9 digits");
        return;
      }
    }

    if (name === "serviceRadius" && value !== "") {
      if (!/^\d+(\.\d{1,2})?$/.test(value)) {
        toast.info(
          "Service radius should be a number with minimum 1 decimal points"
        );
        return;
      }
    }
    if (name.includes(".")) {
      const keys = name.split(".");
      setFormData((prevData) => ({
        ...prevData,
        [keys[0]]: {
          ...prevData[keys[0]],
          [keys[1]]: value,
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const handleCheckboxInputChange = (e) => {
    const { name, checked } = e.target;
    if (name === "sameAsContract") {
      setFormData((prevData) => ({
        ...prevData,
        sameAsContract: checked,
        deliveryAddress: checked ? { ...prevData.contractAddress } : {},
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    }
  };

  const handlePlaceSelected = (place, addressType) => {
    const { address1, city, state, zipCode, country, lat, lng } = place;

    setFormData((prevState) => ({
      ...prevState,
      [addressType]: {
        address1,
        city,
        state,
        zipCode,
        country,
        lat,
        lng,
      },
    }));

    if (deliverySameAsContact && addressType === "contractAddress") {
      setFormData((prevState) => ({
        ...prevState,
        deliveryAddress: {
          address1,
          city,
          state,
          zipCode,
          country,
          lat,
          lng,
        },
      }));
    }
  };

  return (
    <div
      className={`flex flex-col gap-2 align-start mt-[15px] overflow-hidden p-4 overflow-x-hidden max-h-[80vh] overflow-y-scroll`}
      style={{
        width: formConfig.length && cols === 2 > 4 ? 700 : "fit-content",
      }}
    >
      <div className={`flex gap-2 min-w-[80%]`}>
        <div className={`flex flex-col gap-4 align-start`}>
          {/* profile start */}
          <div className={` mx-auto  relative w-full`}>
            <div className="mr-auto w-fit">
              {formConfig?.slice(0, 1)?.map((field) => (
                <div className="flex justify-center items-center gap-5">
                  <GenericFormField
                    key={field.name}
                    field={field}
                    formData={formData}
                    setFormData={setFormData}
                    handleInputChange={handleInputChange}
                    handleCheckboxInputChange={handleCheckboxInputChange}
                  />
                  <p className="hover:underline cursor-not-allowed">
                    Add brand logo
                  </p>
                </div>
              ))}
            </div>
            {formData.hasOwnProperty("amazonAffiliate") && (
              <div
                className={classNames(
                  "flex gap-4 justify-start h-fit whitespace-nowrap items-center w-fit pr-8 mt-4",
                  {
                    "border-2 rounded-lg border-[#2cccd3] p-2 bg-[#E4F7F8]":
                      formData.amazonAffiliate,
                  }
                )}
              >
                <input
                  id="amazonAffiliate"
                  type="checkbox"
                  name="amazonAffiliate"
                  checked={formData.amazonAffiliate}
                  onChange={handleCheckboxInputChange}
                  className="p-2 w-[24px] h-[24px]"
                />
                <label htmlFor="amazonAffiliate">
                  Marked user as amazon affiliated user
                </label>
              </div>
            )}
          </div>
          {/* profile end */}
          {/* ******************************* */}
          <div
            className={`grid grid-cols-[repeat(3,_minmax(0,_1fr))] gap-x-10 items-start grid-flow-col min-w-[1000px] w-[80vw]`}
          >
            <div className="grid grid-cols-1 grid-flow-row gap-y-2">
              {formConfig.slice(1, Math.ceil(8)).map((field) => (
                <GenericFormField
                  key={field.name}
                  field={field}
                  formData={formData}
                  setFormData={setFormData}
                  handleInputChange={handleInputChange}
                  handleCheckboxInputChange={handleCheckboxInputChange}
                />
              ))}
            </div>
            <div className="grid grid-cols-1 grid-flow-row   gap-y-2">
              <AutoGoogleComplete
                apiKey={apiKey}
                onPlaceSelected={(place) =>
                  handlePlaceSelected(place, "contractAddress")
                }
                placeholder={"Search Contract Address"}
                defaultValue={formData.contractAddress?.address1 || ""}
                dropdownEnable={true}
                openArrow={false}
                setShowGroup={setShowContractGroup}
              />
              {formConfig.slice(8, 13).map((field) => (
                <GenericFormField
                  key={field.name}
                  field={field}
                  formData={formData}
                  setFormData={setFormData}
                  hide={showContractGroup}
                  handleInputChange={handleInputChange}
                  handleCheckboxInputChange={handleCheckboxInputChange}
                />
              ))}
              {formConfig.slice(13, 14).map((field) => (
                <GenericFormField
                  key={field.name}
                  field={field}
                  formData={formData}
                  setFormData={setFormData}
                  handleInputChange={handleInputChange}
                  handleCheckboxInputChange={handleCheckboxInputChange}
                />
              ))}
            </div>
            <div className="gap-y-2 grid grid-cols-1 grid-flow-row">
              <div>
                <AutoGoogleComplete
                  apiKey={apiKey}
                  onPlaceSelected={(place) =>
                    handlePlaceSelected(place, "deliveryAddress")
                  }
                  placeholder={"Search Service Address"}
                  defaultValue={formData.deliveryAddress?.address1 || ""}
                  dropdownEnable={true}
                  openArrow={false}
                  setShowGroup={setShowDeliveryGroup}
                />
              </div>

              {formConfig.slice(14, 19).map((field) => (
                <GenericFormField
                  key={field.name}
                  field={field}
                  formData={formData}
                  hide={showDeliveryGroup}
                  setFormData={setFormData}
                  handleInputChange={handleInputChange}
                  handleCheckboxInputChange={handleCheckboxInputChange}
                />
              ))}
              <div className="flex gap-4 justify-start items-center pb-2">
                <input
                  id="sameAsContract"
                  type="checkbox"
                  name="sameAsContract"
                  checked={formData.sameAsContract}
                  onChange={handleCheckboxInputChange}
                  className="p-2 w-[24px] h-[24px]"
                />
                <label htmlFor="sameAsContract">
                  Same as Contract Address{" "}
                </label>
              </div>
            </div>
          </div>
          {/* ******************************* */}
          <div className={"mt-0.5 justify-between flex "}>
            {row?.status?.toLowerCase() === "pending" ? (
              <div className={`flex flex-row gap-2`}>
                <CustomButton
                  onClick={(_) => {
                    setShowApproveModel(true);
                    setSelectedDataValue(row);
                  }}
                >
                  Approve
                </CustomButton>
                <CustomButton
                  onClick={(_) => {
                    setShowRejectionModel(true);
                    setSelectedDataValue(row);
                  }}
                  color="red"
                  variant={"contained"}
                >
                  Reject
                </CustomButton>
              </div>
            ) : (
              ""
            )}
            <div
              onClick={handleSubmit}
              style={{
                backgroundColor: "#2CCCD3",
                color: "white",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
              }}
              className={`gap-[10px] w-fit self-end justify-self-end align-items-center cursor-pointer text-center align-center p-3 hover:drop-shadow-xl`}
            >
              Update
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditRowFormNewUI;
