import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Loader from "./../../../components/atoms/Loader";
import ErrorlogsDialog from "../dialogs/errorLogs";
import { Getkyclogs } from "../../../api/api";
import dayjs from "dayjs";

const KYCErrors = () => {
  const [data, setData] = useState();
  const [selectedData, setSelectedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const columns = [
    { field: "user_id", headerName: "Id", width: 200 },
    { field: "name", headerName: "Name", width: 200 },
    { field: "email", headerName: "Email", width: 150 },
    {
      field: "mobileNo",
      headerName: "Mobile",
      width: 150,
    },
    {
      field: "collegeName",
      headerName: "School",
      width: 150,
    },
    {
      field: "createdAt",
      headerName: "Created At (EST)",
      width: 200,
      renderCell: (params) => (
        <p className="" title="yyyy-mm-dd:hh:mm:ss time format EST">
          {dayjs(params?.row?.createdAt)
            ?.tz("America/New_York")
            .format("YYYY-MM-DD:HH:mm:ss")}
        </p>
      ),
    },
    {
      field: "errors",
      headerName: "Errors",
      width: 150,
      renderCell: (param) => {
        const data1 = param.row.errors;
        return (
          <div
            onClick={(_) => {
              setSelectedData(data1);
              setOpen(true);
            }}
            className={`font-roboto text-sm font-medium leading-tight tracking-tighter text-pink-500 w-100 h-100`}
          >
            Click here to View Errors
          </div>
        );
      },
    },
  ];

  const getAPI = async () => {
    setLoading(true);
    try {
      const res = await Getkyclogs();
      setData(res);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAPI();
  }, []);

  return (
    <div>
      <ErrorlogsDialog
        open={open}
        data={selectedData}
        handleClose={(_) => setOpen(false)}
      />
      {loading ? (
        <div className={`flex flex-row justify-center align-center`}>
          <Loader />
        </div>
      ) : (
        <>
          <div className={`flex flex-column gap-2 align-center h-auto`}>
            <Box sx={{ height: "83vh", width: "100%" }}>
              {data?.length > 0 ? (
                <DataGrid
                  rows={data}
                  columns={columns}
                  //getRowId={(row) => row.user_id}
                  getRowId={(row) => row.id}
                  pagination
                  pageSize={10}
                />
              ) : null}
            </Box>
          </div>
        </>
      )}
    </div>
  );
};

export default KYCErrors;
