import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { getCSVFileViewAPI } from "../../../api/api";
import DownloadCSV from "../../../shared/downloadCSV/DownloadCSV";

const Popup = ({ open, handleClose, fileKey }) => {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("All");
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);

  const popupRef = useRef(null);

  const getCSVAPI = async (fileKey) => {
    setLoading(true);
    try {
      const response = await getCSVFileViewAPI(fileKey);
      //   console.log(response?.data);

      setData(response.data || []);
      // console.log(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (fileKey) {
      //   console.log("bharat00000");
      open && getCSVAPI(fileKey);
    }
  }, [fileKey, open]);

  useEffect(() => {
    // Function to handle clicks outside of the popup
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleClose();
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, handleClose]);
  useEffect(() => {
    if (filter) {
      //   console.log("bharat", filter, filteredData);
      setFilteredData(
        data.filter((row) =>
          filter === "All"
            ? true
            : row?.status?.toUpperCase() === filter?.toUpperCase()
        )
      );
    } else {
      setFilteredData(data);
    }
  }, [filter, data]);

  const columns = [
    { field: "id", headerName: "ID", width: 60, download: true },
    { field: "email", headerName: "Email", width: 250, download: true },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 150,
      download: true,
    },
    { field: "status", headerName: "Status", width: 120, download: true },
  ];

  const pageSize = Math.min(10, filteredData.length);

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center w-full bg-gray-900 bg-opacity-50 transition-opacity ${
        open ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
      style={{ zIndex: 9999 }}
    >
      <div
        ref={popupRef}
        className="bg-white w-11/12 md:w-2/3 lg:w-2/3 xl:w-2/3 max-w-[880px] rounded-lg shadow-lg p-6 overflow-hidden max-h-screen relative"
      >
        <button
          onClick={handleClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-2xl hover:border-1 transition-all duration-100 rounded-full px-2 box-border"
          aria-label="Close"
        >
          &times;
        </button>
        <h2 className="text-xl font-bold mb-4">CSV Delivery Status</h2>
        <div className="flex mb-4 flex-row justify-between items-center  border-b ">
          {filter === "All" ? (
            <p className="font-bold text-sm">
              Total Count:-{" "}
              <strong className="font-semibold text-base">
                {data?.length}
              </strong>
            </p>
          ) : filter === "DELIVERED" ? (
            <p className="font-bold text-[#2cccd3] text-sm">
              Total Delivered:-{" "}
              <strong className="font-semibold text-base">
                {filteredData?.length}
              </strong>
            </p>
          ) : filter === "PENDING" ? (
            <p className="font-bold text-orange-400 text-sm">
              Total Pending:-{" "}
              <strong className="font-semibold text-base">
                {filteredData?.length}
              </strong>
            </p>
          ) : filter === "FAILED" ? (
            <p className="font-bold text-red-400 text-sm">
              Total Failed:-{" "}
              <strong className="font-semibold text-base">
                {filteredData?.length}
              </strong>
            </p>
          ) : filter === "STOPPED" ? (
            <p className="font-bold text-red-400 text-sm">
              Total Stopped:-{" "}
              <strong className="font-semibold text-base">
                {filteredData?.length}
              </strong>
            </p>
          ) : (
            ""
          )}

          <div className="flex justify-self-end ml-auto justify-end items-center gap-2 w-fit">
            <p>Filter By Status</p>
            <select
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              className="p-2 border border-gray-300 rounded-lg"
            >
              <option value="All">All</option>
              <option value="DELIVERED">Delivered</option>
              <option value="PENDING">Pending</option>
              <option value="FAILED">Failed</option>
              <option value="STOPPED">Stopped</option>
            </select>
            <div>
              <DownloadCSV
                fileName={"customer-report-with-status"}
                columns={columns}
                data={filteredData}
                fieldsToInclude={["id", "email,phoneNumber,status"]}
              />
            </div>
          </div>
        </div>
        {loading ? (
          <div className="flex items-center justify-center w-full h-[400px]">
            <CircularProgress style={{ color: "#2cccd3" }} />
          </div>
        ) : (
          <div
            className="data-grid-container"
            style={{ height: 400, width: "100%" }}
          >
            <DataGrid
              rows={filteredData}
              columns={columns}
              pageSize={pageSize}
              pageSizeOptions={[5, 10, 15, 20]}
              getRowId={(row, idx) => {
                return row?.id || row?.email;
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Popup;
