import { useState } from "react";
import { getLatLongByPlaceId, getSearchPlacesByQuery } from "../../api/api";
import InputComponent from "../atoms/InputComponent";

const LocationInput = ({ field, setFormData }) => {
  const [searchPlacesArr, setSearchPlaceArr] = useState([0, 2, 3, 3, 3]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [location, setLocation] = useState("");

  const fetchLatLong = async (place_id, desc) => {
    try {
      const res = await getLatLongByPlaceId(place_id);
      const lat = res.data.result.geometry.location.lat;
      const lng = res.data.result.geometry.location.lng;
      setFormData((prev) => {
        return {
          ...prev,
          [field.name]: JSON.stringify({
            description: desc,
            latitude: lat,
            longitude: lng,
          }),
        };
      });
      console.log({
        [field.name]: JSON.stringify({
          description: desc,
          latitude: lat,
          longitude: lng,
        }),
      });
    } catch (err) {
      console.log("eerr", err);
    }
  };

  const handleInputChange = async (e) => {
    console.log("handleInputChange", e.target);
    try {
      const { name, value } = e.target;
      if (name == "where") {
        const res = await getSearchPlacesByQuery(value);
        setSearchPlaceArr(res?.data?.predictions);
        if (value !== "") {
          setShowDropdown(true);
        } else {
          setShowDropdown(false);
        }
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div className="relative w-100">
      <div className={` w-100`}>
        <InputComponent
          type="search"
          name={field.name}
          placeholder={field.placeholder}
          value={location}
          onChange={(e) => {
            setLocation(e.target.value);
            handleInputChange(e);
          }}
          style={{ textOverflow: "ellipsis" }}
        />
      </div>
      {field.name === "where" && showDropdown ? (
        <div className="absolute bottom-0 left-0 flex flex-col rounded-md mt-2 translate-y-full bg-white z-10 w-300 search-input ">
          {searchPlacesArr?.map((item, ind) => {
            return (
              <div
                className={`pt-2 pl-2 cursor-pointer`}
                onClick={() => {
                  console.log("item", item.description);
                  setLocation(item?.description);
                  fetchLatLong(item?.place_id, item?.description);
                  setShowDropdown(false);
                }}
              >
                {item?.description}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default LocationInput;
