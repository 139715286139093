import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import CardComponent from "../../../components/DiscountCard/DisountCardComponent"; // Adjust the import path as needed

const SortableCard = ({
  cardData,
  cardIndexRank,
  type,
  setShowApproveModel,
  setSelectedDataValue,
  setShowRejectionModel,
  view,
  typeLabel,
  fetchData,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: cardData.id,
      data: { cardData: cardData, cardRank: cardIndexRank },
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <CardComponent
        cardData={cardData}
        // fetchData={fetchData}
        type={type}
        setShowApproveModel={setShowApproveModel}
        setSelectedDataValue={setSelectedDataValue}
        setShowRejectionModel={setShowRejectionModel}
        view={view}
        typeLabel={typeLabel}
      />
    </div>
  );
};

export default SortableCard;
