import React, { useState } from "react";
import GenericFormField from "../../../components/Form/FormFields";
import { CheckBox } from "@mui/icons-material";
import AutoGoogleComplete from "../../../shared/AutoComplete/AutoGoogleComplete";

const FormComponentTicket = ({
  formConfig,
  formData,
  setFormData,
  cols = 2,
  handleSubmit,
  width,
  submitText,
}) => {
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  const [showAddressGroup, setShowAddressGroup] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleCheckboxInputChange = (e) => {
    const { name, type, checked, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handlePlaceSelected = (place, addressType) => {
    const { address1, city, state, zipCode, country, lat, lng } = place;

    setFormData((prevState) => ({
      ...prevState,
      [addressType]: {
        address1,
        city,
        state,
        zipCode,
        country,
        lat,
        lng,
      },
    }));
  };

  return (
    <div
      className={`flex flex-col gap-2 align-start mt-[15px] overflow-hidden max-h-[80vh] overflow-y-scroll !p-4`}
      style={{
        width:
          formConfig.length && cols === 2 > 5 ? 700 : width || "fit-content",
      }}
    >
      <div className={`flex gap-5 ${width ? "w-full" : "w-[98%]"}`}>
        <div className={`flex flex-col gap-4 align-start w-full`}>
          <div
            className={`flex flex-row gap-10 max-h-[50vh] overflow-y-scroll`}
            style={{ alignItems: "start" }}
          >
            <div
              className={`flex flex-col align-start gap-2 p-[2px]`}
              style={{ width: formConfig.length > 6 ? "350px" : "100%" }}
            >
              {cols === 2
                ? formConfig
                    .slice(0, 7)
                    .map((field) => (
                      <GenericFormField
                        key={field.name}
                        field={field}
                        formData={formData}
                        setFormData={setFormData}
                        handleInputChange={handleInputChange}
                      />
                    ))
                : formConfig.map((field) => (
                    <GenericFormField
                      key={field.name}
                      field={field}
                      formData={formData}
                      setFormData={setFormData}
                      handleInputChange={handleInputChange}
                    />
                  ))}
            </div>
            {formConfig.length > 7 && cols === 2 && (
              <div
                className={`flex flex-col align-start gap-2 p-[2px]`}
                style={{ width: formConfig.length > 6 ? "350px" : "100%" }}
              >
                {formConfig?.slice(7, 10)?.map((field) => (
                  <GenericFormField
                    key={field.name}
                    field={field}
                    formData={formData}
                    setFormData={setFormData}
                    handleInputChange={handleInputChange}
                  />
                ))}
                <AutoGoogleComplete
                  apiKey={apiKey}
                  onPlaceSelected={(place) =>
                    handlePlaceSelected(place, "location")
                  }
                  placeholder={"Search Address"}
                  defaultValue={formData.when?.address1 || ""}
                  dropdownEnable={true}
                  openArrow={false}
                  setShowGroup={setShowAddressGroup}
                />
                {formConfig.slice(10, 16).map((field) => (
                  <GenericFormField
                    key={field.name}
                    field={field}
                    formData={formData}
                    setFormData={setFormData}
                    hide={showAddressGroup}
                    handleInputChange={handleInputChange}
                    handleCheckboxInputChange={handleCheckboxInputChange}
                  />
                ))}
              </div>
            )}
          </div>
          <div className={"mt-2"}>
            {formData.hasOwnProperty("amazonAffiliate") && (
              <div className="flex gap-4 justify-start items-center pb-6">
                <input
                  id="amazonAffiliate"
                  type="checkbox"
                  name="amazonAffiliate" // it may change in future and this key doesnt have direct accest through formdata or formconfig we have to seperately see it
                  checked={formData.amazonAffiliate}
                  onChange={handleCheckboxInputChange}
                  className="p-2 w-[24px] h-[24px]"
                />
                <label htmlFor="amazonAffiliate">
                  Mark this user as amazon affiliated user
                </label>
              </div>
            )}

            <div
              onClick={handleSubmit}
              style={{
                backgroundColor: "#2CCCD3",
                color: "white",
                borderRadius: "10px",
                display: "flex",
                width: "100%",
                alignItems: "center",
                textAlign: "center",
                justifyContent: "center",
              }}
              className={`gap-[10px] align-items-center cursor-pointer  text-center align-center p-3 hover:drop-shadow-xl`}
            >
              {submitText ? submitText : "Continue"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormComponentTicket;
