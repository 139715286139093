import React from "react";
import {
  rectSortingStrategy,
  SortableContext,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useDraggable } from "@dnd-kit/core";

const BrandCoupon = ({ id, headline, poster }) => {
  // const { attributes, listeners, setNodeRef, transform, transition } =
  //   useSortable({ id });
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: `coupon-${id}`,
    data: { type: "coupon", id: id },
  });
  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      className="relative w-full bg-white hover:bg-[#f2f2f3] rounded-md group p-5 flex items-center justify-start gap-2 touch-none overflow-hidden"
      style={{
        boxShadow: "rgba(0,0,0,0.16) 0px 1px 4px",
        transform: CSS.Transform.toString(transform),
      }}
    >
      <img
        src={`${process.env.REACT_APP_IMAGE_BASEURL}${poster}`}
        alt="brand"
        className="w-6 h-6 rounded-full"
      />
      <h4 className="text-[14px] font-bold truncate">{headline}</h4>
    </div>
  );
};

export default BrandCoupon;
