import React from "react";
import style from "./RejectCampagin.module.css";
import SoftDialog from "../../../../components/molecules/dialogs";
import CustomButton from "../../../../components/atoms/Button/Button";
import { UpdateAdStatus } from "../../../../api/api";

export default function RejectCampaginDialog({ open, handleClose, data }) {
  const rejectCampaginFunction = async () => {
    try {
      const currentDate = new Date();
      const sevenDaysLater = new Date(currentDate);
      sevenDaysLater.setDate(currentDate.getDate() + 7);

      const dataToPost = {
        startDate: currentDate,
        endDate: sevenDaysLater,
        id: data.id,
        status: "REJECTED",
      };

      console.log(dataToPost)

      await UpdateAdStatus(dataToPost, data.id);
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <SoftDialog
      title={"Reject Campagin"}
      open={open}
      subTitle={"Want to reject this campaign??"}
      handleClose={handleClose}
    >
      <div className={style.container}>
        <CustomButton
          onClick={handleClose}
          style={{ width: 330 }}
          variant={"outlined"}
        >
          Cancel Action
        </CustomButton>
        <CustomButton
          onClick={rejectCampaginFunction}
          style={{ width: 330 }}
          color="red"
          variant={"contained"}
        >
          Reject Campagin
        </CustomButton>
      </div>
    </SoftDialog>
  );
}
