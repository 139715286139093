import React, { useState } from "react";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const AutoGoogleComplete = ({
  apiKey,
  onPlaceSelected,
  defaultValue,
  placeholder,
  dropdownEnable = false,
  openArrow = true,
  showGroup = false,
  setShowGroup = () => {},
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(openArrow);

  const handleArrowAction = () => {
    const newValue = !dropdownOpen;
    setDropdownOpen(newValue);
    setShowGroup(newValue);
  };
  const handleSelect = async (place) => {
    console.log("Selected place:", place);

    try {
      // Get detailed address components
      const results = await geocodeByAddress(place.label);
      const addressComponents = results[0].address_components;

      // Get latitude and longitude
      const { lat, lng } = await getLatLng(results[0]);
      console.log(lat, lng);

      // Extract address components
      const getAddressComponent = (types) => {
        const component = addressComponents.find((component) =>
          component.types.some((type) => types.includes(type))
        );
        return component ? component.long_name : "";
      };

      const address1 = `${getAddressComponent([
        "street_number",
      ])} ${getAddressComponent(["route"])}`;
      const city = getAddressComponent(["locality"]);
      const state = getAddressComponent(["administrative_area_level_1"]);
      const zipCode = getAddressComponent(["postal_code"]);
      const country = getAddressComponent(["country"]);

      console.log("Address Details:", {
        address1,
        city,
        state,
        zipCode,
        country,
        lat,
        lng,
      });

      // Pass address details to parent component
      onPlaceSelected({ address1, city, state, zipCode, country, lat, lng });
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };

  return (
    <div className="flex flex-col text-left gap-2 google-autocomplete">
      <div className="flex flex-row justify-start gap-2 items-center">
        <p>{placeholder ? placeholder : "Search Address"}</p>
        {dropdownEnable && (
          <span>
            {dropdownOpen ? (
              <KeyboardArrowUpIcon
                onClick={() => handleArrowAction()}
                className="cursor-pointer"
              />
            ) : (
              <KeyboardArrowDownIcon
                onClick={() => handleArrowAction()}
                className="cursor-pointer"
              />
            )}
          </span>
        )}
      </div>
      <GooglePlacesAutocomplete
        apiKey={apiKey}
        selectProps={{
          onChange: handleSelect,
          defaultValue,
          placeholder: placeholder ? placeholder : "Search Address",
        }}
      />
    </div>
  );
};

export default AutoGoogleComplete;
