import React from "react";
import dummyImage from "../../../assets/imgs/hm.png";
const DiscountCard = ({
  handleViewCoupon,
  setSelectedCoupon,
  setView,
  poster,
  brand,
  caption,
  headline,
  user,
  cccData,
}) => {
  return (
    <div
      // onClick={() => {
      //   setView(true);
      //   setSelectedCoupon(cccData);
      // }}
      className="p-2 flex gap-3 flex-col bg-white relative items-start w-fit rounded-md cursor-pointer hover:shadow-2xl shadow-lg border-1 border-[#E8F0F2]"
    >
      <div className={`flex relative flex-col gap-2 items-center`}>
        {user?.logo && (
          <img
            src={`${process.env.REACT_APP_IMAGE_BASEURL}${user?.logo}`}
            className="w-[64px] h-[64px] absolute left-0 bottom-0 p-2 rounded-10"
            alt={"logo"}
          />
        )}

        <img
          src={
            poster
              ? `${process.env.REACT_APP_IMAGE_BASEURL}${poster}`
              : dummyImage
          }
          alt="bg"
          className="w-[260px] h-[123px] rounded-md"
        />
      </div>
      <div className="flex flex-col gap-1">
        <h4 className="text-sm font-extrabold">{headline}</h4>
        <p className="text-[10px] font-medium">{user?.brand}</p>
        <p className="text-[12px] font-normal">{caption}</p>
      </div>
      <button
        className="absolute top-0  left-0 right-0 bottom-0 !z-40"
        onClick={(e) => {
          // alert("bharat");
          e.stopPropagation(); // Stop event propagation
          setView(true);
          setSelectedCoupon(cccData);
        }}
      ></button>
    </div>
  );
};

export default DiscountCard;
