import React from "react";

import { useStateContext } from "../../contexts/ContextProvider";

const Button = ({
  icon,
  bgColor,
  color,
  bgHoverColor,
  size,
  onClick,
  text,
  type = "button",
  borderRadius,
  width,
}) => {
  const { setIsClicked, initialState } = useStateContext();

  return (
    <button
      type={type}
      onClick={() => onClick()}
      style={{
        backgroundColor: bgColor,
        color,
        borderRadius,
        display: "flex",
        width: width,
        cursor: "pointer",
        height: "45px",
        alignitems: "center",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
      }}
      className={`gap-[10px] align-items-center text-${size} text-center align-center p-3 w-${width} hover:drop-shadow-xl hover:bg-${bgHoverColor}`}
    >
      <div>{icon}</div> <div>{text}</div>
    </button>
  );
};

export default Button;
