import React, { useState, useEffect } from "react";
import style from "./adsmanager.module.css";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import Calendar from "../../assets/svgs/calendar.svg";
import ProfileDialogComponent from "./dialogs/profileDialog/profileDialog";
import CampaignPreviewComponent from "./dialogs/CampaignPreview";
import ApproveCampaginDialog from "./dialogs/ApproveCampagin/ApproveCampagin";
import RejectCampaginDialog from "./dialogs/RejectCampagin/RejectCampagin";
import { SelectIcon } from "./../../assets/svgs/selectIcon";
import { getDateRangeType } from "../../utils/dateCalculator";
import { DatePicker, Space } from "antd";
import { GetAdsManagerData } from "../../api/api";
import { RejectIcon } from "../../assets/svgs/svgs";
const { RangePicker } = DatePicker;

export default function AdsManager() {
  const [active, setActive] = useState(0);
  const [rows, setRows] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [total, setTotal] = useState({});
  const [showCampagin, setShowCampagin] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showApprove, setShowApprove] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [data, setData] = useState({});
  const [selectedData, setSelectedData] = useState([]);
  const [selectedProfileId, setSelectedProfileId] = useState(0);
  const [selectedCampaginId, setSelectedCampaginId] = useState(0);
  const [dateFilter, setDateFilter] = useState({
    startDate: new Date().setDate(new Date().getDate() - 7),
    endDate: new Date(),
    text: "Last 7 days",
  });
  const [openingArea, setOpeningArea] = useState("all");
  const [selectAll, setSelectAll] = useState(true);

  const columns = [
    {
      field: "title",
      headerName: "Campagin Name",
      width: 200,
      renderCell: (params) => {
        const data = params.row;
        const parsedResponse = JSON.parse(data.media);
        const imageUrl = parsedResponse[0];
        return (
          <div className={`flex gap-4 items-center justify-start w-full`}>
            <div className={style.campaginName_image}>
              <img src={`${process.env.REACT_APP_IMAGE_BASEURL}${imageUrl}`} />
            </div>
            <div className={style.campaginName_title}>
              {data.title?.slice(0, 20)}
            </div>
          </div>
        );
      },
    },
    {
      field: "posted_by",
      headerName: "Posted By",
      width: 250,
      renderCell: (params) => {
        const data = params.row;
        return (
          <div className={`flex gap-4 items-center justify-start w-full`}>
            <div className={style.campaginName_image}>
              <img
                src={`${process.env.REACT_APP_IMAGE_BASEURL}${data.user.profilePhoto}`}
              />
            </div>
            <div className={`flex flex-col gap-2 align-start`}>
              <div className={style.campaginName_title}>
                {data.user.instituteName}
              </div>
              <div className={style.campaginName_des}>{data.user.email}</div>
            </div>
          </div>
        );
      },
      sortComparator: (a, b) => {
        console.log(a, b);
        return a - b;
      },
    },
    {
      field: "company",
      headerName: "Company",
      width: 150,
      renderCell: (params) => (
        <p className={style.cellText}>{params.row.user.instituteName}</p>
      ),
      sortComparator: (a, b) => {
        console.log(a, b);
        return a - b;
      },
    },
    {
      field: "createdAt",
      headerName: "Posted",
      width: 150,
      renderCell: (params) => (
        <p className={style.cellText}>
          {new Date(params.row.createdAt).toISOString().slice(0, 10)}
        </p>
      ),
    },
    { field: "cost", headerName: "Payment Recived", width: 150 },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      disableColumnMenu: true,
      renderCell: (params) => {
        const data = params.row;
        return (
          <div>
            {data.status === "PENDING" ? (
              <div className={style.actionCell}>
                <button
                  onClick={(_) => {
                    setShowApprove(true);
                    setData(data);
                  }}
                  className={style.acceptButton}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_12511_3599)">
                      <path
                        d="M11.999 21.417C17.1999 21.417 21.416 17.2009 21.416 12C21.416 6.79914 17.1999 2.58301 11.999 2.58301C6.79816 2.58301 2.58203 6.79914 2.58203 12C2.58203 17.2009 6.79816 21.417 11.999 21.417Z"
                        stroke="#19B832"
                        stroke-width="1.64565"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9 12.4167L11.5 14.5L16.5 9"
                        stroke="#19B832"
                        stroke-width="1.65"
                        stroke-linecap="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_12511_3599">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
                <button
                  onClick={(_) => {
                    setShowReject(true);
                    setData(data);
                  }}
                  className={style.rejectButton}
                >
                  <RejectIcon />
                </button>
              </div>
            ) : (
              data.status
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await GetAdsManagerData();
        setRows(
          res.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        );
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [showApprove, showReject, showCampagin]);

  const filterDataByStatus = async (status) => {
    const x = rows
      .filter((da) => da.status.includes(status))
      .filter((dat) => {
        const newD = new Date(dat.createdAt);
        return newD >= dateFilter.startDate && newD <= dateFilter.endDate;
      });
    return x;
  };

  useEffect(() => {
    switch (active) {
      case 0:
        setFilteredData(
          rows
            .filter((da) => da.status.includes("PENDING"))
            .filter((dat) => {
              const newD = new Date(dat.createdAt);
              return newD >= dateFilter.startDate && newD <= dateFilter.endDate;
            })
        );
        break;
      case 1:
        setFilteredData(
          rows
            .filter((da) => da.status.includes("APPROVED"))
            .filter((dat) => {
              const newD = new Date(dat.createdAt);
              return newD >= dateFilter.startDate && newD <= dateFilter.endDate;
            })
        );
        break;
      case 2:
        setFilteredData(
          rows
            .filter((da) => da.status.includes("REJECTED"))
            .filter((dat) => {
              const newD = new Date(dat.createdAt);
              return newD >= dateFilter.startDate && newD <= dateFilter.endDate;
            })
        );
        break;
      default:
        setActive(0);
    }
  }, [active, rows, dateFilter]);

  const updatedColumns = columns.filter((column) => {
    return active !== 0;
  });

  useEffect(() => {
    const countStatus = (status) =>
      rows.filter((da) => da.status.includes(status)).length;

    const APICall = async () => {
      const pendingCount = countStatus("PENDING");
      const approvedCount = countStatus("APPROVED");
      const rejectedCount = countStatus("REJECTED");

      setTotal({
        pending: pendingCount,
        approved: approvedCount,
        rejected: rejectedCount,
      });
    };

    APICall();
  }, [rows]);

  const handleSelectionChange = (selection) => {
    setSelectedData(selectAll ? [] : selection.selectionModel);
  };

  const handleButtonClick = () => {
    setSelectAll((prevSelectAll) => {
      const newSelectAll = !prevSelectAll;
      setSelectedData(newSelectAll ? filteredData.map((row) => row.id) : []);
      return newSelectAll;
    });
  };

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      const DateType = getDateRangeType(new Date(dates[0]), new Date(dates[1]));
      setDateFilter({
        startDate: new Date(dates[0]),
        endDate: new Date(dates[1]),
        text: DateType,
      });
    }
  };

  const rangePresets = [
    {
      label: "Today",
      value: [dayjs().subtract(10, "h"), dayjs()],
    },
    {
      label: "Last 7 Days",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
  ];

  return (
    <div className={style.container}>
      <ProfileDialogComponent
        open={showProfile}
        profileId={selectedProfileId}
        handleClose={(_) => setShowProfile(false)}
      />
      <CampaignPreviewComponent
        open={showCampagin}
        openingArea={openingArea}
        approveCampagin={setShowApprove}
        rejectCampagin={setShowReject}
        dataCampagin={setData}
        campaginId={selectedCampaginId}
        handleClose={(_) => setShowCampagin(false)}
      />
      <ApproveCampaginDialog
        open={showApprove}
        data={data}
        handleClose={(_) => setShowApprove(false)}
      />
      <RejectCampaginDialog
        open={showReject}
        data={data}
        handleClose={(_) => setShowReject(false)}
      />
      <div
        className={`${style.topFilterSection} flex flex-row justify-between`}
      >
        <div className={`flex flex-row align-center gap-2`}>
          <button
            onClick={(_) => setActive(0)}
            className={`${style.buttonTab} ${active === 0 ? style.active : ""}`}
          >
            Pending{" "}
            <span
              className={`${style.badge} ${
                active === 0 ? style.activeBadge : ""
              }`}
            >
              {total.pending}
            </span>{" "}
          </button>
          <button
            onClick={(_) => setActive(1)}
            className={`${style.buttonTab} ${active === 1 ? style.active : ""}`}
          >
            Approved{" "}
            <span
              className={`${style.badge} ${
                active === 1 ? style.activeBadge : ""
              }`}
            >
              {total.approved}
            </span>{" "}
          </button>
          <button
            onClick={(_) => setActive(2)}
            className={`${style.buttonTab} ${active === 2 ? style.active : ""}`}
          >
            Rejected{" "}
            <span
              className={`${style.badge} ${
                active === 2 ? style.activeBadge : ""
              }`}
            >
              {total.rejected}
            </span>{" "}
          </button>
        </div>
        <div className="flex flex-row items-center gap-2">
          <div className="relative flex flex-row">
            <button
              onClick={() => {
                const dateclick = document.querySelector(".ant-picker");
                dateclick.click();
              }}
              className={`z-11 bg-white ${style.selectRows} ${style.selectRowsButton}`}
            >
              <img src={Calendar} />
              {dateFilter.text}
            </button>
            <div className="absolute">
              <Space>
                <RangePicker
                  defaultValue={[
                    dayjs(dateFilter.startDate),
                    dayjs(dateFilter.endDate),
                  ]}
                  presets={rangePresets}
                  onChange={onRangeChange}
                />
              </Space>
            </div>
          </div>
          <button
            onClick={handleButtonClick}
            className={`selectRows ${style.selectRows} ${
              !selectAll ? style.rowsActive : ""
            }`}
          >
            <SelectIcon />
            Select
          </button>
        </div>
      </div>
      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={filteredData}
          columns={columns}
          checkboxSelection={!selectAll}
          onSelectionModelChange={handleSelectionChange}
          selectionModel={selectedData}
          pagination
          pageSize={10}
          onCellClick={(params) => {
            if (params.field === "posted_by") {
              setSelectedProfileId(params.row.user_id);
              setShowProfile(true);
            } else if (params.field === "title") {
              setSelectedCampaginId(params.row.id);
              setShowCampagin(true);
              if (params.row.status === "APPROVED") {
                setOpeningArea("approved");
              } else if (params.row.status === "REJECTED") {
                setOpeningArea("rejected");
              } else {
                setOpeningArea("all");
              }
            }
          }}
        />
      </Box>
    </div>
  );
}
