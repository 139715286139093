import React from "react";
import SoftDialog from "../../components/molecules/dialogs";
import style from "./style.module.css";
import CustomButton from "../../components/atoms/Button/Button";

export default function AcceptRejectModel({
  showReject = false,
  open,
  title,
  onClick,
  handleClose,
  rejectMessage = "",
  setRejectMessage = () => {},
  reasonAdd,
}) {
  return (
    <SoftDialog title={title} open={open} handleClose={handleClose}>
      <div className={style.container11}>
        {showReject ? (
          <div className="flex flex-col gap-2 mt-4">
            {" "}
            {reasonAdd && (
              <div className="w-full max-h-[100px]">
                {" "}
                <textarea
                  name=""
                  id=""
                  className="w-full h-[100px] p-2 rounded-lg  ring-1 ring-[#2cccd3] focus:ring-2 focus:bg-[#e6f5f6] outline-none focus:shadow-sm duration-300 transition-all"
                  rows={3}
                  cols={5}
                  value={rejectMessage}
                  onChange={(e) => setRejectMessage(e.target.value)}
                  placeholder="Please mention rejection reason *"
                ></textarea>
              </div>
            )}
            <CustomButton
              onClick={handleClose}
              style={{ width: 330, maarginTop: 10 }}
              variant={"outlined"}
            >
              Cancel Action
            </CustomButton>
            <CustomButton
              onClick={onClick}
              style={{ width: 330, maarginTop: 10 }}
              color="red"
              variant={"contained"}
            >
              Reject
            </CustomButton>
          </div>
        ) : (
          <div className="flex flex-col gap-2">
            {" "}
            <CustomButton
              onClick={handleClose}
              style={{ width: 330, maarginTop: 10 }}
              variant={"outlined"}
            >
              Cancel Action
            </CustomButton>
            <CustomButton
              onClick={onClick}
              style={{ width: 330, maarginTop: 10 }}
              variant={"contained"}
            >
              Approve
            </CustomButton>
          </div>
        )}
      </div>
    </SoftDialog>
  );
}
