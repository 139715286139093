export const FormConfig = [
  { name: "instituteName", placeholder: "Company Name" },
  { name: "phoneNumber", placeholder: "Phone Number" },
  { name: "email", placeholder: "Email Address", type: "email" },
  { name: "password", placeholder: "Create Password", type: "password" },
  { name: "website", placeholder: "Website" },
  {
    name: "industry",
    placeholder: "Select Industry",
    type: "select",
    options: [
      { value: "1", name: "Footwear" },
      { value: "2", name: "Garments" },
      { value: "3", name: "Educational" },
      { value: "4", name: "Electronics" },
      { value: "5", name: "Food and Drinks" },
    ],
  },
];

export const FormConfigForAds = [
  { name: "title", placeholder: "Title", required: true },

  {
    name: "startDate",
    placeholder: "Start Date",
    type: "when",
    required: true,
  },
  { name: "endDate", placeholder: "End Date", type: "when", required: true },
  { name: "redirectUrl", placeholder: "Website Link", required: true },
  { name: "cta", placeholder: "Call to Action", required: true },
  {
    name: "description",
    placeholder: "Description",
    type: "textarea",
    required: true,
  },
];
