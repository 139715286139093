import React from "react";

const InputComponent = ({
  label,
  type,
  name,
  id,
  placeholder,
  required,
  onChange,
  min,
  max,
  ...props
}) => {
  const today = new Date()?.toISOString()?.split("T")[0];
  //default max date
  const defaultMaxDate = "9999-12-31";
  return (
    <div className="flex flex-col w-100">
      <label htmlFor="password" {...props} className="text-lg">
        {label}
      </label>
      <input
        type={type}
        name={name}
        onChange={onChange}
        {...props}
        id={id}
        required={required}
        className={`p-3 border disabled:cursor-not-allowed disabled:bg-slate-200 border-black focus:ring-2 focus:ring-[#2cccd3] focus:outline-none rounded-md outline-none w-100 ${
          type === "email" ? "lowercase" : ""
        }`}
        min={type === "date" && !min ? today : min} // min date to today if none provided
        max={type === "date" && !max ? defaultMaxDate : max}
        placeholder={placeholder}
      />
    </div>
  );
};

export default InputComponent;
