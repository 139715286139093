import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { SelectIcon } from "./../../assets/svgs/selectIcon";
import Box from "@mui/material/Box";
import Calendar from "../../assets/svgs/calendar.svg";
import {  GetUserAds } from "../../api/api";
import style from "./profileCampagin.module.css";
import CampaignPreviewComponent from "../adsmaster/dialogs/CampaignPreview";
import ApproveCampaginDialog from "../adsmaster/dialogs/ApproveCampagin/ApproveCampagin";
import { DatePicker, Space } from "antd";
const { RangePicker } = DatePicker;
import RejectCampaginDialog from "../adsmaster/dialogs/RejectCampagin/RejectCampagin";
import { getDateRangeType } from "../../utils/dateCalculator";
import { generateImageUrl } from "../../utils/func";

export default function ProfileCampagins() {
  const [user, setUser] = useState({});
  const [active, setActive] = useState(0);
  const [rows, setRows] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [total, setTotal] = useState({});
  const [showCampagin, setShowCampagin] = useState(false);
  const [showApprove, setShowApprove] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [data, setData] = useState({});
  const [selectedData, setSelectedData] = useState([]);
  const [selectedCampaginId, setSelectedCampaginId] = useState(0);
  const [dateFilter, setDateFilter] = useState({
    startDate: new Date().setDate(new Date().getDate() - 7),
    endDate: new Date(),
    text: "Last 7 days",
  });
  const [selectAll, setSelectAll] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const columns = [
    {
      field: "title",
      headerName: "Campagin Name",
      width: 300,
      renderCell: (params) => {
        const data = params.row;
        return (
          <div className={`flex gap-4 items-center justify-start w-full`}>
            <div className={style.campaginName_image}>
              <img src={generateImageUrl(data.media, "parse")} />
            </div>
            <div className={style.campaginName_title}>{data.title}</div>
          </div>
        );
      },
    },
    {
      field: "start_date",
      headerName: "Posted",
      width: 150,
      renderCell: (params) => (
        <p className={style.cellText}>
          {new Date(params.row.createdAt).toISOString().slice(0, 10)}
        </p>
      ),
    },
    { field: "cost", headerName: "Payment Recived", width: 150 },
    {
      field: "action",
      headerName: "Action",
      width: 250,
      renderCell: (params) => {
        const data = params.row;
        return (
          <div>
            {data.status === "PENDING" ? (
              <div className={style.actionCell}>
                <button
                  onClick={(_) => {
                    setShowApprove(true);
                    setData(data);
                  }}
                  className={style.acceptButton}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_12511_3599)">
                      <path
                        d="M11.999 21.417C17.1999 21.417 21.416 17.2009 21.416 12C21.416 6.79914 17.1999 2.58301 11.999 2.58301C6.79816 2.58301 2.58203 6.79914 2.58203 12C2.58203 17.2009 6.79816 21.417 11.999 21.417Z"
                        stroke="#19B832"
                        stroke-width="1.64565"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9 12.4167L11.5 14.5L16.5 9"
                        stroke="#19B832"
                        stroke-width="1.65"
                        stroke-linecap="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_12511_3599">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
                <button
                  onClick={(_) => {
                    setShowReject(true);
                    setData(data);
                  }}
                  className={style.rejectButton}
                >
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.6102 7.33154L7.38672 14.555M7.38672 7.33154L14.6102 14.555"
                      stroke="#F81E00"
                      stroke-width="1.50489"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.999 20.417C16.1999 20.417 20.416 16.2009 20.416 11C20.416 5.79914 16.1999 1.58301 10.999 1.58301C5.79816 1.58301 1.58203 5.79914 1.58203 11C1.58203 16.2009 5.79816 20.417 10.999 20.417Z"
                      stroke="#F81E00"
                      stroke-width="1.64565"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            ) : null}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const filterDataByStatus = (status) => {
      return rows
        .filter((da) => da.status.includes(status))
        .filter((dat) => {
          const newD = new Date(
            status === "PENDING" ? dat.createdAt : dat.start_date
          );
          return newD >= dateFilter.startDate && newD <= dateFilter.endDate;
        });
    };

    let data1;

    switch (active) {
      case 0:
        data1 = filterDataByStatus("PENDING");
        break;
      case 1:
        data1 = filterDataByStatus("APPROVED");
        break;
      case 2:
        data1 = filterDataByStatus("REJECTED");
        break;
      default:
        setActive(0);
        setFilteredData(rows);
        return;
    }

    setFilteredData(data1);
  }, [active, rows, dateFilter]);

  useEffect(() => {
    const APICall = () => {
      const data1 = rows.filter((da) => da.status.includes("PENDING"));
      const data2 = rows.filter((da) => da.status.includes("APPROVED"));
      const data3 = rows.filter((da) => da.status.includes("REJECTED"));

      setTotal({
        pending: data1.length,
        approved: data2.length,
        rejected: data3.length,
      });
    };

    APICall();
  }, [location, rows]);

  const handleSelectionChange = (selection) => {
    if (!selectAll) {
      setSelectedData(selection.selectionModel);
    } else {
      setSelectedData([]);
    }
  };

  const handleButtonClick = () => {
    if (selectAll) {
      setSelectAll(false);
      const newSelectionModel = filteredData.map((row) => row.id);
      setSelectedData(newSelectionModel);
    } else {
      setSelectedData([]);
      setSelectAll(true);
    }
  };

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      console.log(
        "From Date: ",
        new Date(dates[0]),
        ", to: ",
        new Date(dates[1])
      );
      const DateType = getDateRangeType(new Date(dates[0]), new Date(dates[1]));
      setDateFilter({
        startDate: new Date(dates[0]),
        endDate: new Date(dates[1]),
        text: DateType,
      });
    } else {
      console.log("Clear");
    }
  };

  const rangePresets = [
    {
      label: "Today",
      value: [dayjs().add(0, "d"), dayjs()],
    },
    {
      label: "Last 7 Days",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
  ];

  async function callAPIUSERS() {
    try {
      const userId = location.search.slice(4);
      const res = await GetUserAds(userId);
      setRows(res.data);
      setUser(res.user);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    callAPIUSERS();
  }, [location]);

  return (
    <div>
      <CampaignPreviewComponent
        open={showCampagin}
        campaginId={selectedCampaginId}
        handleClose={(_) => setShowCampagin(false)}
      />
      <ApproveCampaginDialog
        open={showApprove}
        data={data}
        handleClose={(_) => setShowApprove(false)}
      />
      <RejectCampaginDialog
        open={showReject}
        data={data}
        handleClose={(_) => setShowReject(false)}
      />
      <div className={`flex align-center px-3 title_threads_top font-light`}>
        <p onClick={(_) => navigate("/ads")}>Ads manager</p>/
        <p onClick={(_) => navigate("/adsprofile")}>Profiles</p>/
        <span className={"h5"}>{user?.instituteName}</span>
      </div>
      <div className={`px-5 py-2`}>
        <div className={`flex gap-4 items-start justify-start`}>
          <div className={style.campaginName_image}>
            <img src={user?.profilePhoto} />
          </div>
          <div className={`flex flex-col gap-2 align-start`}>
            <div className={style.campaginName_title}>
              {user?.instituteName}
            </div>
            <div className={style.campaginName_des}>{user?.email}</div>
          </div>
        </div>
      </div>
      <p className={style.allCampaginsText}>All Campagins</p>
      <div className={`mt-2 flex flex-col items-start`}>
        <div className={style.tableTitle}></div>
        <div className={style.tableContainer}>
          <div
            className={`${style.topFilterSection} flex flex-row justify-between`}
          >
            <div className={`flex flex-row align-center gap-2`}>
              <button
                onClick={(_) => setActive(0)}
                className={`${style.buttonTab} ${
                  active === 0 ? style.active : ""
                }`}
              >
                Pending{" "}
                <span
                  className={`${style.badge} ${
                    active === 0 ? style.activeBadge : ""
                  }`}
                >
                  {total.pending}
                </span>{" "}
              </button>
              <button
                onClick={(_) => setActive(1)}
                className={`${style.buttonTab} ${
                  active === 1 ? style.active : ""
                }`}
              >
                Approved{" "}
                <span
                  className={`${style.badge} ${
                    active === 1 ? style.activeBadge : ""
                  }`}
                >
                  {total.approved}
                </span>{" "}
              </button>
              <button
                onClick={(_) => setActive(2)}
                className={`${style.buttonTab} ${
                  active === 2 ? style.active : ""
                }`}
              >
                Rejected{" "}
                <span
                  className={`${style.badge} ${
                    active === 2 ? style.activeBadge : ""
                  }`}
                >
                  {total.rejected}
                </span>{" "}
              </button>
            </div>
            <div className={`flex flex-row align-center gap-2`}>
              <div className={`flex flex-row relative`}>
                <button
                  onClick={() => {
                    const dateclick = document.querySelector(".ant-picker");
                    dateclick.click();
                  }}
                  className={`z-10 bg-white ${style.selectRow}`}
                >
                  <img src={Calendar} />
                  {dateFilter.text}
                </button>
                <div className={`absolute`}>
                  <Space>
                    <RangePicker
                      defaultValue={[
                        dayjs(dateFilter.startDate),
                        dayjs(dateFilter.endDate),
                      ]}
                      presets={rangePresets}
                      onChange={onRangeChange}
                    />
                  </Space>
                </div>
              </div>
              <button
                onClick={handleButtonClick}
                className={`${style.selectRows} ${
                  !selectAll ? style.rowsActive : ""
                }`}
              >
                <SelectIcon />
                Select
              </button>
            </div>
          </div>
          <Box sx={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={filteredData}
              columns={columns}
              checkboxSelection={!selectAll}
              onSelectionModelChange={handleSelectionChange}
              selectionModel={selectedData}
              pagination
              pageSize={10}
              onCellClick={(params) => {
                if (params.field === "title") {
                  setSelectedCampaginId(params.row.id);
                  setShowCampagin(true);
                }
              }}
            />
          </Box>
        </div>
      </div>
    </div>
  );
}
