import React, { useEffect, useState } from "react";
import CsvUpload from "./CsvUpload/CsvUpload";
import { toast } from "react-toastify";
import { Loader } from "../../components";
import axios from "axios";
import ViewListIcon from "@mui/icons-material/ViewList";
import Popup from "./viewCsvFile/PopUp";
import { ExportIcon, EyeIcon } from "../../assets/svgs/svgs";
import { VisibilityOutlined } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { commsListAPI } from "../../api/api";

const CSV = () => {
  const [csvFiles, setCsvFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedFileKey, setSelectedFileKey] = useState(null);

  const fetchCsvFiles = async () => {
    setLoading(true); // Set loading to true when fetching starts
    try {
      // const response = await axios.get("https://comms.estulife.com/api/list");
      const response = await commsListAPI();
      if (response.status === 200) {
        setCsvFiles(response.data.data);
      } else {
        toast.info("Something wrong with getting list from server");
      }
    } catch (error) {
      console.error("Error fetching CSV files:", error);
      toast.error("Error while getting CSV files list");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchCsvFiles();
  }, []);

  const handleUploadSuccess = () => {
    fetchCsvFiles();
  };
  const handleViewClick = (fileKey) => {
    setSelectedFileKey(fileKey);
    setPopupOpen(true);
  };

  return (
    <div className="flex flex-col gap-5">
      <h4 className="text-xl">COMMS Management</h4>
      <CsvRules />

      <CsvUpload onUploadSuccess={handleUploadSuccess} />
      {/* listing */}
      <div className="bg-white shadow-md rounded-lg p-6">
        <h2 className="text-xl font-bold mb-4">{`Uploaded CSV Files ${
          csvFiles?.length ? "(" + csvFiles?.length + ")" : ""
        }`}</h2>
        {loading ? (
          <div className="flex items-center justify-between p-4 bg-gray-100 rounded-lg h-14">
            <Loader />
          </div>
        ) : csvFiles?.length === 0 ? (
          <p className="text-gray-600 text-center">
            No CSV files uploaded yet.
          </p>
        ) : (
          <div>
            <div className="grid grid-cols-10 gap-4 p-4 border-b border-b-[#D3E2E5] rounded-t-lg">
              <p className="col-span-3 font-bold text-[#2c3030] font-montserrat text-sm capitalize">
                CSV File
              </p>
              <p className="font-bold text-[#2c3030] font-montserrat text-sm capitalize text-center">
                Uploaded on
              </p>
              <p className="font-bold text-[#2c3030] font-montserrat text-sm capitalize text-center">
                Delivered
              </p>
              <p className="font-bold text-[#2c3030] font-montserrat text-sm capitalize text-center">
                Pending
              </p>

              <p className="font-bold text-[#2c3030] font-montserrat text-sm capitalize text-center">
                Failed
              </p>
              <p className="font-bold text-[#2c3030] font-montserrat text-sm capitalize text-center">
                Stopped
              </p>
              <p className="font-bold text-[#2c3030] font-montserrat text-sm capitalize text-center col-span-2">
                Action
              </p>
            </div>
            <ul className="space-y-4">
              {csvFiles?.map((file) => (
                <li
                  key={file.id}
                  className="grid grid-cols-10 items-center p-4 hover:bg-gray-100 border-b border-b-[#D3E2E5] rounded-lg"
                >
                  <div className="col-span-3 truncate">
                    <p
                      title={`${
                        file?.totalCount
                          ? `Total Count: ${file?.totalCount}`
                          : ""
                      }`}
                      className="text-[#2c3030] font-montserrat text-sm font-medium capitalize truncate px-0.5"
                    >
                      {file?.name}
                    </p>
                  </div>
                  <div>
                    <p
                      title={new Date(file?.createdAt).toLocaleString()}
                      className="relative text-[#2c3030] font-montserrat text-sm font-medium capitalize truncate px-0.5 text-center"
                    >
                      {new Date(file?.createdAt)?.toLocaleDateString()}
                    </p>
                  </div>
                  <div>
                    <p className="text-[#2c3030] font-montserrat text-sm font-medium capitalize truncate px-0.5 text-center">
                      {file?.deliveryCount}
                    </p>
                  </div>
                  <div>
                    <p className="text-[#2c3030] font-montserrat text-sm font-medium capitalize truncate px-0.5 text-center">
                      {file?.pendingCount}
                    </p>
                  </div>
                  <div>
                    <p className="text-[#2c3030] font-montserrat text-sm font-medium capitalize truncate px-0.5 text-center">
                      {file?.failedCount}
                    </p>
                  </div>{" "}
                  <div>
                    <p className="text-[#2c3030] font-montserrat text-sm font-medium capitalize truncate px-0.5 text-center">
                      {file?.stoppedCount}
                    </p>
                  </div>
                  <div className="flex gap-3 self-end justify-end col-span-2">
                    <button
                      onClick={() => handleViewClick(file?.key)}
                      className="flex w-fit h-full p-3 items-center gap-1 justify-center bg-[#E9742D] text-white rounded-lg font-semibold hover:bg-[#ea9058] active:bg-[#ad5e2d]"
                    >
                      <VisibilityOutlined
                        fontSize="small"
                        className="w-4 h-4 text-white"
                      />
                      <p className="text-white text-center font-montserrat text-[10px] font-bold">
                        View
                      </p>
                    </button>
                    <a
                      download
                      href={`https://d34gt69eepjr0b.cloudfront.net/csv/${file?.key}`}
                    >
                      <button className="flex w-fit h-full p-3 items-center gap-1 justify-center bg-[#2cccd3] text-white rounded-lg font-semibold hover:bg-[#5fecf3] active:bg-[#27878c]">
                        <ExportIcon />
                        <p className="text-white text-center font-montserrat text-[10px] font-bold">
                          Download
                        </p>
                      </button>
                    </a>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          // <ul className="space-y-4">
          //   {csvFiles?.map((file) => (
          //     <li
          //       key={file.id}
          //       className="flex items-center justify-between p-4 bg-gray-100 rounded-lg"
          //     >
          //       <div>
          //         <p className="text-sm font-semibold">{file?.name}</p>
          //         {/* <p className="text-14 font-semibold">{file?.key}</p> */}
          //         <p className="text-xs text-gray-500">
          //           Uploaded on: {new Date(file?.createdAt)?.toLocaleString()}
          //         </p>
          //         {/* <p className="text-xs text-gray-500">
          //           <span>
          //             Total Count ({file?.delivered ? file?.delivered : "---"}/
          //             {file?.totalCount ? file?.totalCount : "---"})
          //           </span>
          //           <span>Pending</span>
          //         </p> */}
          //       </div>
          //       <div className="flex justify-center items-center gap-3">
          //         {/* <button
          //           onClick={() => handleViewClick(file?.key)}
          //           className="py-1 px-3 gap-2 text-[#2cccd3] hover:underline flex-flex-row items-center justify-center rounded-lg font-semibold  active:scale-105 transition-all duration-300 whitespace-nowrap"
          //         >
          //           <ViewListIcon fontSize="small" />
          //           &nbsp;View
          //         </button> */}
          //         {/* <a
          //           href={`https://d34gt69eepjr0b.cloudfront.net/csv/${file?.key}`}
          //           // href={`${process.env.REACT_APP_CLOUD_FRONT_UR}/csv/${file?.key}`}
          //           className="py-1 px-3 bg-[#2cccd3] text-white rounded-lg font-semibold hover:bg-[#5fecf3] active:bg-[#27878c]"
          //           download
          //         >
          //           Download
          //         </a> */}

          //         <button
          //           onClick={() => handleViewClick(file?.key)}
          //           className="flex w-fit h-full p-3 items-center gap-1 justify-center bg-[#E9742D] text-white rounded-lg font-semibold hover:bg-[#ea9058] active:bg-[#ad5e2d]"
          //         >
          //           <VisibilityOutlined
          //             fontSize="small"
          //             className="w-4 h-4 text-white"
          //           />
          //           <p className=" text-white text-center font-montserrat text-[10px] font-bold">
          //             View
          //           </p>
          //         </button>
          //         <a
          //           download
          //           href={`https://d34gt69eepjr0b.cloudfront.net/csv/${file?.key}`}
          //         >
          //           <button className="flex w-fit h-full p-3 items-center gap-1 justify-center bg-[#2cccd3] text-white rounded-lg font-semibold hover:bg-[#5fecf3] active:bg-[#27878c]">
          //             <ExportIcon />
          //             <p className=" text-white text-center font-montserrat text-[10px] font-bold">
          //               Download
          //             </p>
          //           </button>
          //         </a>
          //       </div>
          //     </li>
          //   ))}
          // </ul>
        )}
      </div>
      <Popup
        open={popupOpen}
        handleClose={() => setPopupOpen(false)}
        fileKey={selectedFileKey}
      />
    </div>
  );
};

const CsvRules = () => {
  return (
    <div className="bg-white shadow-md rounded-lg p-6 mb-4">
      <h2 className="text-xl font-bold mb-4">COMMS File Format Rules</h2>
      <ul className="list-disc list-inside text-gray-700">
        <li className="mb-2">
          The CSV file should contain this exact order of columns:{" "}
          <strong>
            email, phoneNumber, subject, headline, emailBody, smsMessage, image
            {/* name, email, phoneNumber, subject, emailBody, smsMessage */}
          </strong>
        </li>
        <li className="mb-2">
          At least two columns must be filled out for each row.
        </li>
        <li className="mb-2">
          <strong>Email</strong> and <strong>Phone Number</strong> are the most
          important required fields.
        </li>
        <li className="mb-2">
          Make sure to follow the correct format for each column to ensure
          successful upload and processing.
        </li>
        <li className="mb-2">
          Each row should contain relevant data without empty cells.
        </li>
        <li className="mb-2">
          Phone numbers must be formatted as:{" "}
          <strong>{`<countryCode><phoneNumber>`}</strong>
        </li>{" "}
        <li className="mb-2">
          eg <strong>12525439876</strong>
        </li>
      </ul>
    </div>
  );
};

export default CSV;
