import axios from "axios";
import { toast } from "react-toastify";

export const ImageUpload = async (event, setFormData, keyName) => {
  console.log("Image Upload started");
  const file = event.target.files[0];

  if (!file) {
    console.error("No file selected");
    return;
  }

  const formData = new FormData();
  formData.append("file", file);

  const API_ENDPOINT = process.env.REACT_APP_IMAGE_UPLOAD_URL;
  const AUTH_TOKEN =
    "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjk1OTg2NTU0LCJleHAiOjE3MDM3NjI1NTR9.FIZhkfttqztMn7HbpoezY3rwsS6Zea3ldQli-lc2ykc";

  try {
    const response = await axios.post(API_ENDPOINT, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: AUTH_TOKEN,
      },
    });

    // if (admaster) {
    //   setFormData((prev) => ({
    //     ...prev,
    //     profilePhoto: response.data.data.key,
    //   }));
    // } else {
    //   setFormData((prev) => ({ ...prev, poster: response.data.data.key }));
    // }

    const uploadedImageKey = response?.data?.data?.key;
    setFormData((prev) => ({
      ...prev,
      [keyName]: uploadedImageKey, // Assign the uploaded image key to the passed keyName
    }));
  } catch (error) {
    console.error("Error uploading image:", error);
    toast.errot("Something went wrong while uploading image:");
  }
};
