import React from 'react';
import style from './Button.module.css';
import PropTypes from 'prop-types';

export default function CustomButton({ color, variant, children, onClick,  ...props }) {
	return color === 'blue' ? (
		<button
			{...props}
			onClick={onClick}
			className={`${style.blueButton} ${
				variant === 'outlined' ? `${style.blueButtonOutlined}` : ``
			}`}>
			{children}
		</button>
	) : color === 'red' ? (
		<button
				{...props}
				onClick={onClick}
			className={`${style.redButton} ${
				variant === 'outlined' ? `${style.redButtonOutlined}` : ``
			}`}>
			{children}
		</button>
	) : (
		<div></div>
	);
}

CustomButton.defaultProps = {
	variant: 'contained',
	color: 'blue',
};

CustomButton.propTypes = {
	variant: PropTypes.oneOf(['text', 'contained', 'outlined', 'gradient']),
	color: PropTypes.oneOf(['blue', 'red']),
	children: PropTypes.node.isRequired,
};
