import { TicketFormConfig } from "./formConfig";
import GenericFormComponent from "../../../components/Form/Form";
import { SaveTicket } from "../../../api/api";
import FormComponentTicket from "./FormComponentTicket";

const CreateTicketForm = ({ formData, setFormData, setStep }) => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await SaveTicket(formData);
      console.log("formdata", formData);
      setStep(1);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    // <GenericFormComponent
    //   formConfig={TicketFormConfig}
    //   formData={formData}
    //   setFormData={setFormData}
    //   handleSubmit={handleSubmit}
    // />
    <FormComponentTicket
      formConfig={TicketFormConfig}
      formData={formData}
      setFormData={setFormData}
      handleSubmit={handleSubmit}
    />
  );
};

export default CreateTicketForm;
