import {
	isToday,
	isWithinInterval,
	subDays,
	subMonths,
	startOfDay,
	endOfDay,
} from 'date-fns';

export const getDateRangeType = (startDate, endDate) => {
	const today = new Date();
	const last7DaysStart = subDays(today, 7);
	const last30DaysStart = subDays(today, 30);

	if (isToday(startDate) && isToday(endDate)) {
		return 'Today';
	} else if (
		isWithinInterval(startDate, {
			start: startOfDay(last7DaysStart),
			end: endOfDay(today),
		})
	) {
		return 'Last 7 days';
	} else if (
		isWithinInterval(startDate, {
			start: startOfDay(last30DaysStart),
			end: endOfDay(today),
		})
	) {
		return 'Last 30 days';
	} else {
		return 'Custom Date';
	}
};

export const calculateElapsedTime = (date) => {
	const now = new Date();
	const chatDate = new Date(date);
	const timeDifference = now - chatDate;
	const seconds = Math.floor(timeDifference / 1000);
	const minutes = Math.floor(seconds / 60);
	const hours = Math.floor(minutes / 60);
	const days = Math.floor(hours / 24);

	if (seconds < 60) {
		return `${seconds}s`;
	} else if (minutes < 60) {
		return `${minutes}m`;
	} else if (hours < 24) {
		return `${hours}h`;
	} else if (days < 7) {
		return `${days}d`;
	} else {
		// If more than 1 week, show the date and month
		const options = { month: 'short', day: 'numeric' };
		return chatDate.toLocaleDateString('en-US', options);
	}
};
