import EditRowForm from "../../../components/Form/EditRowForm";
import { EditAdmasterUserFormConfig } from "../../mbanq/formConfig";
import { updateAdmasterUser } from "../../../api/api";
import { toast } from "react-toastify";
import EditRowFormNew from "./EditFormNew";
import EditRowFormNewUI from "./EditRowFormNewUI";

const EditRowComponent = ({
  formData,
  setFormData,
  setUpdateRows,
  setShowEditRowModal,
  userId,
  setShowApproveModel,
  setShowRejectionModel,
  setSelectedDataValue,
  row,
}) => {
  const validateAddress = (address, addressType) => {
    const { address1, city, state, zipCode } = address;
    // console.log(address, "pqrst");

    if (!address1) {
      toast.error(`${addressType}: Address Line 1 is required`);
      return false;
    }

    if (!city) {
      toast.error(`${addressType}: City is required`);
      return false;
    }

    if (!state) {
      toast.error(`${addressType}: State is required`);
      return false;
    }

    if (!zipCode) {
      toast.error(`${addressType}: Zipcode is required`);
      return false;
    }
    if (isNaN(zipCode)) {
      toast.error("zipCode must be a number");
      return false;
    }
    if (zipCode.length < 5 || zipCode.length > 6) {
      toast.error(`${addressType}:ZipCode must be between 5 and 6 digits long`);
      return false;
    }

    return true;
  };
  const validationEditUser = (formData) => {
    // console.log(formData, "pqr");
    if (isNaN(formData.ein)) {
      toast.error("EIN must be a number");
      return false;
    }

    if (formData.ein.length !== 9) {
      toast.error("EIN must be exactly 9 digits long");
      return false;
    }

    if (!formData.serviceRadius) {
      toast.error("Please Enter Service Radius in miles");
      return false;
    }
    // Validate contractAddress
    if (
      formData.contractAddress &&
      !validateAddress(formData.contractAddress, "Contract Address")
    ) {
      return false;
    }

    // Validate deliveryAddress
    if (
      formData.deliveryAddress &&
      !validateAddress(formData.deliveryAddress, "Service Address")
    ) {
      return false;
    }

    return true;
  };
  const handleSubmit = async (e) => {
    console.log(formData);
    // return;
    if (!validationEditUser(formData)) {
      return;
    }

    // return;
    try {
      setShowEditRowModal(false);
      const response = await updateAdmasterUser(formData, userId);
      toast.success(response?.message ? response.message : "success");
      setUpdateRows((prev) => !prev);
    } catch (error) {
      console.error(error);
      // toast.error(error?.message ? error.message : "error");
      setShowEditRowModal(false);
    }
  };
  // console.log(formData);
  // return (
  //   <EditRowForm
  //     formConfig={EditAdmasterUserFormConfig}
  //     formData={formData}
  //     setFormData={setFormData}
  //     handleSubmit={handleSubmit}
  //   />
  // );
  // return (
  //   <EditRowFormNew
  //     formConfig={EditAdmasterUserFormConfig}
  //     formData={formData}
  //     setFormData={setFormData}
  //     handleSubmit={handleSubmit}
  //     setShowApproveModel={setShowApproveModel}
  //     setShowRejectionModel={setShowRejectionModel}
  //     setSelectedDataValue={setSelectedDataValue}
  //     row={row}
  //   />
  // );
  return (
    <EditRowFormNewUI
      formConfig={EditAdmasterUserFormConfig}
      formData={formData}
      setFormData={setFormData}
      handleSubmit={handleSubmit}
      setShowApproveModel={setShowApproveModel}
      setShowRejectionModel={setShowRejectionModel}
      setSelectedDataValue={setSelectedDataValue}
      row={row}
    />
  );
};

export default EditRowComponent;
