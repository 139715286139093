import Image1121 from "../../../assets/imgs/cpimg.png";
import Image1122 from "../../../assets/imgs/cpimg1.png";
import style from "./style.module.css";

export const SuccessFulllStep = () => {
  return (
    <div
      className={`flex flex-col p-5 gap-5 w-100`}
      style={{ alignItems: "center" }}
    >
      <div className={"w-[100px] h-[100px] mx-3"}>
        <img src={Image1121} className="w-100 h-100" />
      </div>
      <div className={style.successtext}>Coupon created successful !!</div>
      <div className={`h5 text-center w-[300px]`}>
        You can find your created coupon in profile {"->"} discount coupons
      </div>
    </div>
  );
};

export const SuccessFulllStep1 = () => {
  return (
    <div
      className={`flex flex-col p-5 gap-5 w-100`}
      style={{ alignItems: "center" }}
    >
      <div className={"w-[100px] h-[100px] mx-3"}>
        <img src={Image1122} className="w-100 h-100" />
      </div>
      <div className={style.successtext}>Event created successfully !!</div>
      <div className={`h5 text-center w-[300px]`}>
        You can find your created events in profile {"->"} Events
      </div>
    </div>
  );
};
