import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";
import UsersIcon from "../../assets/svgs/usersIcon.svg";
import CampaignIcon from "../../assets/svgs/CampaignIcon.svg";
import RevenueIcon from "../../assets/svgs/revenueIcon.svg";
import ThreadsIcon from "../../assets/svgs/ThreadsIcon.svg";

const Navbar = ({ data }) => {
  const {  setActiveMenu, setScreenSize, screenSize } =
    useStateContext();

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);

  return (
    <div className={`py-[16px]`}>
      <div
        className={`py-[16px] pl-[18px] text-[#344767] text-[20px] font-bold leading-[26px] capitalize tracking-[0.12px]`}
      >
        Ads Manager
      </div>
      <div className={`py-[12px] pl-[12px] pr-[44px] w-full flex items-center`}>
        <div className="grid w-full grid-cols-4 gap-[24px]">
          <div
            onClick={(_) => navigate("/adsprofile")}
            className="cursor-pointer shadow flex justify-between items-center bg-white rounded-[16px] h-[82px] w-full p-[16px]"
          >
            <div className={``}>
              <div
                className={`text-[#67748E] text-[14px] font-bold leading-[21px] tracking-[0.4px] capitalize`}
              >
                Connected Profiles
              </div>
              <div
                className={`text-[#344767] text-[20px] font-bold leading-[28px]`}
              >
                {data?.connected_profiles}
              </div>
            </div>
            <div
              className={` w-[48px] h-[48px] px-[10px] py-[12px] justify-center flex items-center rounded-[8px] bg-[#2CCCD3] shadow`}
            >
              <img width={30} height={24} src={UsersIcon} />
            </div>
          </div>

          <div className="shadow flex justify-between items-center bg-white rounded-[16px] h-[82px] w-full p-[16px]">
            <div className={``}>
              <div
                className={`text-[#67748E] text-[14px] font-bold leading-[21px] tracking-[0.4px] capitalize`}
              >
                Total campaigns
              </div>
              <div
                className={`text-[#344767] text-[20px] font-bold leading-[28px]`}
              >
                {data?.total_ads}
              </div>
            </div>
            <div
              className={` w-[48px] h-[48px] px-[10px] py-[12px] justify-center flex items-center rounded-[8px] bg-[#2CCCD3] shadow`}
            >
              <img width={30} height={24} src={CampaignIcon} />
            </div>
          </div>

          <div className="shadow flex justify-between items-center bg-white rounded-[16px] h-[82px] w-full p-[16px]">
            <div className={``}>
              <div
                className={`text-[#67748E] text-[14px] font-bold leading-[21px] tracking-[0.4px] capitalize`}
              >
                total revenue{" "}
              </div>
              <div
                className={`text-[#344767] text-[20px] font-bold leading-[28px]`}
              >
                ${parseInt(data?.ad_revenue)?.toLocaleString()}
              </div>
            </div>
            <div
              className={` w-[48px] h-[48px] px-[10px] py-[12px] justify-center flex items-center rounded-[8px] bg-[#2CCCD3] shadow`}
            >
              <img width={30} height={24} src={RevenueIcon} />
            </div>
          </div>

          <div
            onClick={(_) => navigate("/threads")}
            className="shadow flex justify-between items-center bg-white rounded-[16px] h-[82px] w-full p-[16px] cursor-pointer"
          >
            <div className={``}>
              <div
                className={`text-[#67748E] text-[14px] font-bold leading-[21px] tracking-[0.4px] capitalize`}
              >
                Threads
              </div>
              <div
                className={`text-[#344767] text-[20px] font-bold leading-[28px]`}
              >
                {parseInt(data?.count_threads)?.toLocaleString()}
              </div>
            </div>
            <div
              className={` w-[48px] h-[48px] px-[10px] py-[12px] justify-center flex items-center rounded-[8px] bg-[#2CCCD3] shadow`}
            >
              <img width={30} height={24} src={ThreadsIcon} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
