import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useStateContext } from "../../contexts/ContextProvider";
import Snackbar from "../atoms/Snackbar";
import { Navbar, Sidebar, ThemeSettings } from "..";
import Loader from "../atoms/Loader";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

const Layout = () => {
  const navigate = useNavigate();
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    themeSettings,
    isLoading,
  } = useStateContext();
  const [data, setData] = useState(null);
  const location = useLocation();
  const { userDepartment } = useAuth();
  const department = userDepartment();

  // useEffect(() => {
  //   navigate("/estu-ads");
  // }, []);

  useEffect(() => {
    const currentThemeColor = localStorage.getItem("colorMode");
    const currentThemeMode = localStorage.getItem("themeMode");
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  // useEffect(() => {
  //   API.get("/api/v1/admin/analytics/agg-count").then(
  //     (res) => setData(res.data),
  //     (err) => console.log(err)
  //   );
  // }, [location]);

  if (isLoading) return <Loader />;
  if (location.pathname === "/") {
    if (department === "MARKETING") {
      navigate("upload-csv");
    } else {
      navigate("/dashboard");
    }
  }

  return location.pathname !== "/threads" &&
    location.pathname !== "/waitlist" &&
    location.pathname !== "/adsprofile" &&
    location.pathname !== "/adsprofilecampagin" &&
    location.pathname !== "/instituteList" &&
    location.pathname !== "/kycerrors" &&
    location.pathname !== "/allowedUsers" &&
    location.pathname !== "/mbanq" &&
    location.pathname !== "/non-ssn-student" &&
    !location.pathname === "/estu-ads" ? (
    <>
      <div className={currentMode === "Dark" ? "dark" : ""}>
        <div className="flex relative dark:bg-main-dark-bg">
          {activeMenu ? (
            <div className="w-[264px] fixed sidebar dark:bg-secondary-dark-bg bg-[#F8F9FA] ">
              <Sidebar />
            </div>
          ) : (
            <div className="w-0 dark:bg-secondary-dark-bg">
              <Sidebar />
            </div>
          )}
          <div
            className={
              activeMenu
                ? "dark:bg-main-dark-bg   min-h-screen md:ml-[264px] w-full  "
                : "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 "
            }
          >
            <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
              <Navbar data={data} />
            </div>
            <div>
              {themeSettings && <ThemeSettings />}
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      <Snackbar />
    </>
  ) : (
    <>
      <div className={currentMode === "Dark" ? "dark" : ""}>
        <div className="flex relative dark:bg-main-dark-bg">
          {activeMenu ? (
            <div className="w-[264px] fixed sidebar dark:bg-secondary-dark-bg bg-[#F8F9FA] ">
              <Sidebar />
            </div>
          ) : (
            <div className="w-0 dark:bg-secondary-dark-bg">
              <Sidebar />
            </div>
          )}
          <div
            className={
              activeMenu
                ? "dark:bg-main-dark-bg   min-h-screen md:ml-[264px] w-full  "
                : "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 "
            }
          >
            <div
              className={
                "bg-[#F8F9FA] h-full p-4 flex align-start flex-col overflow-hidden"
              }
            >
              {location.pathname === "/threads" ? (
                <div
                  className={
                    "title_threads_top gap-1 flex flex-row items-center"
                  }
                >
                  <p onClick={(_) => navigate("/ads")}>Ads manager</p>/
                  <p onClick={(_) => navigate("/adsprofile")}>Profiles</p>
                  /Threads
                </div>
              ) : location.pathname === "/waitlist" ? (
                <div className={"title_threads_top"}>Waitlist</div>
              ) : location.pathname === "/adsprofile" ? (
                <div
                  style={{ fontWeight: 500 }}
                  className={`flex gap-2 align-center px-3 title_threads_top font-light`}
                >
                  <p onClick={(_) => navigate("/ads")}>Ads manager</p> /{" "}
                  <span
                    onClick={(_) => navigate("/ads")}
                    style={{ fontWeight: "bold" }}
                  >
                    Profiles
                  </span>
                </div>
              ) : null}
              {themeSettings && <ThemeSettings />}
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      <Snackbar />
    </>
  );
};

export default Layout;
