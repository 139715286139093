import React, { useCallback, useEffect, useState } from "react";
import {
  rectSortingStrategy,
  SortableContext,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import UserBrand from "./UserBrand";
import {
  closestCorners,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
  MeasuringStrategy,
  MouseSensor,
} from "@dnd-kit/core";
import { addCouponOrBrandAPI, searchByUserNameAPI } from "../../../api/api";
import { toast } from "react-toastify";
import BrandCoupon from "./BrandCoupon";
import SearchUIAddCouponAndBrand from "./SearchUIAddCouponAndBrand";
import { CircularProgress, TextField } from "@mui/material";
import { Loader } from "../../../components";
import Pagination from "../../../shared/pagination/Pagination";
import DroppableContainer from "./Dnd/DroppableContainer";
import DraggableBrandContainer from "./DraggableBrandContainer";
import DraggableCouponContainer from "./DraggableCouponContainer";

const AddCouponAndBrand = ({
  selectedGroup,
  selectedGroupId,
  brands,
  coupons,
  setCoupons,
  setSelectedGroup,
  setShowModalAddCouponAndBrand,
  selectedBrandId,
  setSelectedBrandId,
  users,
  setBrands,
}) => {
  const [query, setQuery] = useState("");
  const [loadingBrand, setLoadingBrand] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // console.log(selectedGroup, "bbbbbbbb");

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 10 } }),
    useSensor(TouchSensor)
  );

  // const getBrandPos = (id) => brands.findIndex((brand) => brand.id === id);
  const handleBrandDragEnd = async (event) => {
    const { active, over } = event;
    // if (!over || active.id === over.id) return;

    const APIData = {
      groupId: selectedGroup,
      couponData: {
        couponIds: [],
        brandIds: [active?.data.current.id],
      },
    };
    console.log(APIData);
    // return;
    const response = await addCouponOrBrandAPI(APIData);
    console.log(response);
    if (response?.status === "success") {
      toast.success(response.message);
      setShowModalAddCouponAndBrand(false);
    }
    // if (over.id === "drop-target") {
    //   console.log("UserBrand dropped in the target div");
    // }
    // if (active.id === over.id) return;
    console.log(active, over);
    console.log("bharat");
  };
  // const getCouponPos = (id) => coupons.findIndex((coupon) => coupon.id === id);
  const handleSelectedCouponDragEnd = async (event) => {
    const { active, over } = event;
    // if (!over || active.id === over.id) return;

    const APIData = {
      groupId: selectedGroup,
      couponData: {
        couponIds: [active?.data.current.id],
        brandIds: [],
      },
    };
    const response = await addCouponOrBrandAPI(APIData);
    console.log(response);
    if (response?.status === "success") {
      toast.success(response.message);
      setCoupons([]);
      setShowModalAddCouponAndBrand(false);
    }
    // if (over.id === "drop-target") {
    //   console.log("UserBrand dropped in the target div");
    // }
    // if (active.id === over.id) return;
    console.log(active, over);
    console.log("bharat");
  };

  const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };
  const debouncedSearch = useCallback(
    debounce((query) => {
      callSearchByUserName(query);
    }, 1000),
    []
  );
  const callSearchByUserName = async (query) => {
    try {
      setLoadingBrand(true);
      const response = await searchByUserNameAPI(query);
      console.log(response);
      if (response.status === "success") {
        setBrands(response.data);
      }
    } catch (error) {
      console.log("error:", error);
    } finally {
      setLoadingBrand(false);
    }
  };
  useEffect(() => {
    query && debouncedSearch(query);
  }, [query]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handleDragEnd = (event) => {
    if (!event.over) {
      toast.info("Please Drop only in droppable area!");
    } else if (event.over && event.over.id === "droppable") {
      // toast.info("perfect");
      if (event.active?.data?.current?.type === "brand") {
        // toast.info("perfect hh");
        handleBrandDragEnd(event);
      } else if (event.active?.data?.current?.type === "coupon") {
        // toast.info("Coupon remaining");
        handleSelectedCouponDragEnd(event);
      } else {
        toast.error("This is neither a brand nor coupon");
      }
    }
  };

  return (
    <div className="min-w-[90vw] flex flex-col h-[85vh] overflow-y-scroll rounded">
      <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
        <div className="flex flex-col gap-[19px] h-full">
          <DroppableContainer />
          <div className="grid grid-cols-2 gap-4 h-full">
            <DraggableBrandContainer
              brands={brands}
              setBrands={setBrands}
              selectedBrandId={selectedBrandId}
              setSelectedBrandId={setSelectedBrandId}
              page={page}
              setPage={setPage}
              // handlePageChange={handlePageChange}
              // totalPages={55}
            />
            <DraggableCouponContainer coupons={coupons} />
          </div>
        </div>
      </DndContext>
    </div>
  );
};

export default AddCouponAndBrand;
const FirstData = () => {
  <div className="w-1/2">
    {" "}
    <DndContext
      sensors={sensors}
      onDragEnd={handleCouponAndBrandDragEnd}
      collisionDetection={closestCorners}
      measuring={measuringConfig}
    >
      <SortableContext items={brands} strategy={rectSortingStrategy}>
        <div
          ref={setNodeRef}
          {...attributes}
          {...listeners}
          id="drop-target"
          className="bg-[#E8F0F2] border=[12px] flex flex-col items-center gap-2 p-4"
        >
          <div className="min-h-[200px] flex justify-center items-center border-[1px] w-full border-[#92A9AD] rounded bg-[#E8F0F2]">
            <p className="font-semibold text-xl text-[#526163] capitalize">
              Drop here brand & coupon
            </p>
          </div>
        </div>
        <div className="my-2 ">
          <h4 className="text-[16px] font-extrabold">Brands List</h4>
          <TextField
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Search Brands by Name"
            className="w-full my-1"
          />
        </div>
        {loadingBrand ? (
          <div className="flex justify-center items-center">
            <CircularProgress style={{ color: "#2cccd3" }} />
          </div>
        ) : (
          <div className="flex flex-col gap-2">
            <div className="flex flex-wrap mx-auto gap-2">
              {brands.map((brand, idx) => {
                return (
                  <UserBrand
                    selectedBrandId={selectedBrandId}
                    setSelectedBrandId={setSelectedBrandId}
                    key={idx}
                    {...brand}
                  />
                );
              })}
            </div>
            <Pagination
              currentPage={page}
              onPageChange={() => {}}
              totalPages={totalPages}
            />
          </div>
        )}
      </SortableContext>
    </DndContext>
  </div>;
};
const SecondData = () => {
  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleSelectedCouponDragEnd}
      collisionDetection={closestCorners}
      measuring={measuringConfig}
    >
      {coupons?.length > 0 ? (
        <SortableContext items={coupons} strategy={rectSortingStrategy}>
          <div className="my-2">
            <h4 className="text-[16px] font-extrabold">Coupons List</h4>
          </div>

          <div className="grid grid-cols-5 gap-2">
            {coupons.map((coupon, idx) => {
              return <BrandCoupon key={idx} {...coupon} />;
            })}
          </div>
          {/* <div className="flex items-center justify-center gap-2">
              {renderPageButtons()}
            </div> */}
        </SortableContext>
      ) : selectedBrandId ? (
        <p className="text-center">No Coupons for this brand</p>
      ) : (
        <p className="text-center">No Brand Selected</p>
      )}
    </DndContext>
  );
};
