import React from "react";
import { CrossIcon, PrevButtonIcon } from "../../assets/svgs/svgs";

const SoftCustomModel = ({
  isVisible,
  onClose,
  title,
  children,
  prevButton,
  widthFull,
  heightFull,
  width = "",
  height = "",
  onClickPrevButton = () => {},
}) => {
  if (!isVisible) return null;

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      onClick={onClose}
    >
      <div
        className={`bg-[#f2f8fa] p-6 rounded-lg shadow-lg ${
          widthFull ? "w-full" : "w-[527px]"
        } relative ${
          heightFull ? "h-full" : "max-h-[90vh]"
        } overflow-y-scroll ${width} ${height}`}
        onClick={(e) => {
          e.stopPropagation();
          //   onClose();
        }}
      >
        <div className="flex justify-between items-center mb-4">
          <div className="justify-start items-center flex flex-row gap-2 p-0">
            {prevButton && (
              <button
                onClick={onClickPrevButton}
                className="text-gray-500 hover:text-gray-800 hover:bg-slate-400 rounded-full duration-300 transition-all active:bg-slate-200 active:text-white"
              >
                <PrevButtonIcon />
              </button>
            )}

            <h2 className="text-[#38415F] font-montserrat font-bold text-lg flex-nowrap capitalize">
              {title}
            </h2>
          </div>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
            className="absolute top-2 right-2 rounded-full hover:scale-115 duration-300 transition-all"
          >
            <CrossIcon />
          </button>
        </div>
        <div className="modal-content">
          {children} {/* Render any children passed into the modal */}
        </div>
      </div>
    </div>
  );
};

export default SoftCustomModel;
