import React, { useState, useEffect } from "react";
import style from "./campagin.module.css";
import { useNavigate } from "react-router-dom";
import SoftDialog from "../../../components/molecules/dialogs";
import { GetAdCampaignById } from "../../../api/api";
import { EyeIcon, ThreadIcon } from "../../../assets/svgs/svgs";

export default function CampaignPreviewComponent({
  open,
  approveCampagin,
  rejectCampagin,
  dataCampagin,
  campaginId,
  handleClose,
  data,
  openingArea,
}) {
  // const [data, setData] = useState();

  const [meta_data, setMetaData] = useState({});
  const navigate = useNavigate();

  // useEffect(() => {
  // 	async function getData() {
  // 	  try {
  // 	    const result = await GetAdCampaignById(campaginId);
  // 	    setData(result.data);
  // 	    setMetaData(result.metaData);
  // 	  } catch (error) {}
  // 	}
  // 	getData();
  // }, [campaginId]);

  const imageURL =
    data?.media && `${process.env.REACT_APP_IMAGE_BASEURL}${data?.media}`;

  return (
    <SoftDialog open={open} handleClose={handleClose} title="Campaign Preview">
      <div className={style.container}>
        <div className={style.topPart}>
          <div className={style.left}>
            <img src={imageURL} className="max-h-[500px] w-auto" />
            <button
              onClick={(_) => window.open(imageURL)}
              className={style.viewFullImage}
            >
              <EyeIcon />
              View full image
            </button>
          </div>
          <div className={style.right}>
            <div className={style.right_title}>{data?.title}</div>
            <div className={style.right_des}>{data?.description}</div>
            <div className={style.content_card}>
              <div className={style.title}>About Ad</div>
              <div className={style.des}>
                <div className={style.cont1}>
                  <p className={style.title}>Website link</p>
                  <p className={style.des}>{data?.redirectUrl}</p>
                </div>
                <div className={style.cont1}>
                  <p className={style.title}>Call to Action</p>
                  <p className={style.des}>{data?.cta}</p>
                  {/* <a
										target='_blank'
										href={`http://${data?.user_metadata?.website}`}
										style={{ textDecoration: "none" }}
										className={style.des}
									>
										Visit Site
									</a> */}
                </div>
                {/* <div className={style.cont1}>
									<p className={style.title}>Placement</p>
									<p className={style.des}>Estu Feed</p>
								</div> */}
              </div>
            </div>
            {/* <div className={`${style.content_card}`}>
							<div className={style.title}>About Target audience</div>
							<div className={style.des}>
								<div className={style.cont1}>
									<p className={style.title}>Gender</p>
									<p className={style.des}>{meta_data.gender}</p>
								</div>
								<div className={style.cont1}>
									<p className={style.title}>Age Range</p>
									<p className={style.des}>
										{meta_data?.ageRange?.[0]}-{meta_data?.ageRange?.[1]}
									</p>
								</div>
								<div className={style.cont1}>
									<p className={style.title}>Intrest</p>
									<p className={style.des}>Ecommerce</p>
								</div>
								<div className={style.cont1}>
									<p className={style.title}>Target Institue</p>
									<p className={style.des}>Boston College</p>
								</div>
							</div>
						</div> */}
            {/* <div className={style.content_card}>
							<div className={style.title}>Cost and duration</div>
							<div className={style.des}>
								<div className={style.cont1}>
									<p className={style.title}>Cost</p>
									<p className={style.des}>$ {meta_data?.cost}</p>
								</div>
								<div className={style.cont1}>
									<p className={style.title}>Duriation</p>
									<p className={style.des}>{meta_data?.days}</p>
								</div>
								<div className={style.cont1}>
									<p className={style.title}>Payment Mode</p>
									<p className={style.des}>{meta_data?.paymentMethod}</p>
								</div>
							</div>
						</div> */}
          </div>
        </div>
        {/* <div className={style.bottomPart}>
					<button
						onClick={(_) => navigate(`/threads?chat=${data.user_id}`)}
						className={style.startThreadButton}
					>
						<ThreadIcon />
						Start Thread
					</button>
					{openingArea !== "rejected" && (
						<button
							onClick={(_) => {
								rejectCampagin(true);
								dataCampagin(data);
								handleClose();
							}}
							className={style.rejectButton}
						>
							Reject and refund
						</button>
					)}
					{openingArea !== "approved" && (
						<button
							onClick={(_) => {
								approveCampagin(true);
								dataCampagin(data);
								handleClose();
							}}
							className={style.acceptButton}
						>
							Approve Ad
						</button>
					)}
				</div> */}
      </div>
    </SoftDialog>
  );
}
