import React from "react";
import { Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import { useStateContext } from "../../contexts/ContextProvider";

const SnackBar = () => {
  const { snackbar, setSnackbar } = useStateContext();

  const handleClose = () => {
    setSnackbar({ active: false, status: "", message: "" });
  };

  return (
    <Snackbar
      open={snackbar.active}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert
        onClose={handleClose}
        severity={snackbar.status}
        sx={{ width: "100%" }}
      >
        {snackbar.message ? snackbar.message : "Something Went Wrong"}
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;
