import React, { useState, useEffect } from "react";
import Select from "react-select";
import { getSectors } from "../../api/api";

const MultiSelectIndustry = ({ formData, setFormData }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [availableOptions, setAvailableOptions] = useState([]);
  // console.log("data show");
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const sectors = await getSectors(); // Replace with your API call
        const updatedSectors = sectors.map((item) => {
          // console.log(item);
          return { value: item.id, name: item.name, label: item.name };
        });
        setAvailableOptions(updatedSectors);
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    // Check if formData has industry key and it's an array
    if (Array.isArray(formData.industry)) {
      // Filter availableOptions to get selected options based on IDs in formData.industry
      const selected = availableOptions.filter((option) =>
        formData.industry.includes(option.value)
      );
      setSelectedOptions(selected);
    }
  }, [formData.industry, availableOptions]);

  const handleMultiSelectChange = (selected) => {
    setSelectedOptions(selected);
    // Check if all options are selected
    if (selected.length === availableOptions.length) {
      // Extract IDs of all options
      const selectedIds = selected.map((option) => option.value);
      // Update formData.industry with all IDs
      setFormData({ ...formData, industry: selectedIds });
    } else {
      // Update formData.industry with selected option IDs
      const selectedIds = selected.map((option) => option.value);
      setFormData({ ...formData, industry: selectedIds });
    }
  };

  return (
    <Select
      options={availableOptions}
      isMulti
      value={selectedOptions}
      onChange={handleMultiSelectChange}
    />
  );
};

export default MultiSelectIndustry;
