export const DiscountFormConfig = [
  { name: "headline", placeholder: "Headline" },
  { name: "caption", placeholder: "Caption" },
  { name: "website", placeholder: "Website" },
  { name: "couponCode", placeholder: "Coupon Code" },
  { name: "howToRedeem", placeholder: "How to Redeem", type: "textarea" },
  { name: "poster", placeholder: "Poster Image", type: "image" },
  {
    name: "callToAction",
    placeholder: "Call to Action",
    type: "radio",
    options: ["Redeem offer", "Visit site"],
  },
  {
    name: "featureCoupon",
    placeholder: "Feature Coupon",
    type: "radio",
    options: ["Top", "Best", "Latest"],
  },
  {
    name: "valid",
    placeholder: "Validity",
    type: "calendar",
  },
  {
    name: "industry",
    placeholder: "Select industries",
    type: "multiselect",
  },
];
export const GroupCouponFormConfig = [
  { name: "headline", placeholder: "Headline" },
  { name: "caption", placeholder: "Caption" },
  { name: "website", placeholder: "Website" },
  { name: "poster", placeholder: "Poster Image", type: "image" },
  { name: "description", placeholder: "How to Redeem", type: "textarea" },
];
export const GroupFormConfig = [
  { name: "name", placeholder: "Name" },

  { name: "description", placeholder: "Description", type: "area" },
  { name: "start_date", placeholder: "Start Date", type: "when" },
  { name: "end_date", placeholder: "End Date", type: "when" },
];

export const TicketFormConfig = [
  { name: "headline", placeholder: "Headline" },

  { name: "description", placeholder: "Description", type: "area" },
  // { name: "where", placeholder: "Location" },

  { name: "poster", placeholder: "Poster Image", type: "image" },
  { name: "website", placeholder: "Website" },
  { name: "when", placeholder: "When", type: "when" },

  { name: "tillDate", placeholder: "End Date", type: "when" },

  { name: "time", placeholder: "Start Time", type: "time" },
  { name: "till", placeholder: "End Time", type: "time" },

  {
    name: "isPaidEvent",
    placeholder: "Is Paid Event",
    type: "radio",
    options: ["Yes", "No"],
  },
  {
    name: "callToAction",
    placeholder: "Call to Action",
    type: "radio",
    options: ["Get Ticket", "None"],
  },
  {
    name: "location.address1",
    placeholder: "Address1 *",
    type: "deliveryAddress",
  },
  {
    name: "location.address2",
    placeholder: "Address2(optional)",
    type: "deliveryAddress",
  },
  {
    name: "location.city",
    placeholder: "City *",
    type: "deliveryAddress",
  },
  {
    name: "location.state",
    placeholder: "State *",
    type: "deliveryAddress",
  },
  {
    name: "location.zipCode",
    placeholder: "Zip Code *",
    type: "deliveryAddress",
  },
];

//   {
//     "userId": 8,
//     "savingAccountId": "12",
//     "cardId": 12,
//     "clientId": 12,
//     "savingAccountNumber": "12"
// }
