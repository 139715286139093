import React, { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  DeleteGroupIcon,
  EditGroupIcon,
  GroupIcon,
  GroupIconBlack,
} from "../../../assets/svgs/svgs";
import {
  deleteGroupApi,
  groupToggleAPI,
  updateGroupRankNameApi,
} from "../../../api/api";
import { toast } from "react-toastify";
import SoftDialog from "../../../components/molecules/dialogs";
import { Button } from "../../../components";
import InputComponent from "../../../components/atoms/InputComponent";
import { ToggleButton } from "@mui/material";
import SwitchToggle from "../../../shared/Switch/SwitchToggle";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const Group = ({
  openModal,
  sethOpenModal,
  selectedGroup,
  setSelectedGroup,
  getAPI,
  id,
  name,
  description,
  start_date,
  end_date = "2099-12-31",
  allCopuons = [],
  rank,
  indexId,
  toggle,
}) => {
  const [isToggled, setIsToggled] = useState(toggle);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });
  const [editGroup, setEditGroup] = useState(false);
  const [formData, setFormData] = useState({
    name: name,
    description: description,
    start_date: start_date,
    end_date: end_date,
  });
  const navigate = useNavigate();

  const handleEditGroup = async (e) => {
    console.log(formData);
    if (!formData.end_date) {
      formData.end_date = "2099-12-31";
    }
    if (!formData.start_date) {
      formData.start_date = "";
    }
    if (
      formData.start_date &&
      !isEndDateValid(formData.start_date, formData.end_date)
    ) {
      return toast.info("End date can not be before start date");
    }
    if (!isDateValidFromToday(formData.end_date)) {
      return toast.info("End date can not be acceptable before today!");
    }
    // return console.log(formData);
    try {
      const response = await updateGroupRankNameApi({
        id,
        name: formData.name,
        description: formData.description,
        start_date: formData.start_date || "",
        end_date: formData.end_date || "2099-12-31", //yyyy-mm-dd
      });
      if (response.status === "success") {
        toast.success(response.message || "Group Updated successfully");
        getAPI();
      }
    } catch (error) {
      toast.error("Failed to delete group");
    }
  };
  const isEndDateValid = (startDate, endDate) => {
    if (!startDate || !endDate) {
      return false; // Handle cases where either date is missing
    }

    const formattedStartDate = moment(startDate, "YYYY-MM-DD");
    const formattedEndDate = moment(endDate, "YYYY-MM-DD");

    // Check if endDate is after or equal to startDate (inclusive)
    return formattedEndDate.isSameOrAfter(formattedStartDate);
  };
  const isDateValidFromToday = (endDate) => {
    const formattedEndDate = moment(endDate, "YYYY-MM-DD");
    const today = moment();

    // Check if endDate is not before today
    return !formattedEndDate.isBefore(today);
  };
  const handleGroupDelete = async () => {
    try {
      const response = await deleteGroupApi({ id });
      if (response.status === 200) {
        toast.success(response.data.message || "Group deleted successfully");
        console.log("delete group");
        getAPI();
      }
    } catch (error) {
      toast.error("Failed to delete group");
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleToggleChange = async () => {
    const response = await groupToggleAPI(id);
    toast.success(response.message);
  };
  const handleCouponBrandButton = () => {
    setSelectedGroup(id);
    sethOpenModal(!openModal);
  };
  const handleViewCoupons = () => {
    // if (allCopuons?.length > 0) {
    //view
    navigate(`group-coupons/${id}`, { state: { id } });
    // } else {
    // toast.info("No Coupons available! Please add");
    // }
  };
  return (
    <>
      <SoftDialog
        title={"Edit Group"}
        open={editGroup}
        handleClose={(_) => {
          // setStep(0);
          // setShowForm(false);
          setEditGroup(false);
        }}
      >
        <form action="" className="flex flex-col gap-4 max-w-md">
          <InputComponent
            type="text"
            name={"name"}
            placeholder={"Name"}
            value={formData["name"] || ""}
            onChange={handleInputChange}
          />
          <textarea
            onChange={handleInputChange}
            defaultValue={formData["description"] || ""}
            name="description"
            rows={4}
            cols="100"
            //   placeholder={"Message"}
            className={"p-3 border-2"}
          ></textarea>
          <InputComponent
            type="date"
            label={"Start Date(mm/dd/yyyy)"}
            name={"start_date"}
            title={"date format mm/dd/yyyy"}
            placeholder={"Start Date"}
            value={formData["start_date"] || ""}
            onChange={handleInputChange}
          />
          <InputComponent
            type="date"
            label="End Date(mm/dd/yyyy)"
            name={"end_date"}
            title={"date format mm/dd/yyyy"}
            placeholder={"End Date"}
            value={formData["end_date"] || ""}
            onChange={handleInputChange}
          />
          <Button
            onClick={(_) => handleEditGroup(true)}
            bgColor="#2CCCD3"
            color="#fff"
            borderRadius="10px"
            text={"Submit"}
          />
        </form>
        {/* {step === 0 ? (
					<FormComponent
						setStep={setStep}
						step={step}
						formData={formData}
						setFormData={setFormData}
					/>
				) : (
					<SuccessFulllStep />
				)} */}
      </SoftDialog>
      <div
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        className="relative bg-white hover:bg-[#f2f2f3] rounded-md w-full group p-5 flex items-center justify-start gap-5 touch-none"
        style={{
          boxShadow: "rgba(0,0,0,0.16) 0px 1px 4px",
          transition,
          transform: CSS.Transform.toString(transform),
        }}
      >
        <span className="absolute top-0 left-0 p-2 text-black">
          {indexId + 1}
        </span>
        <div className="w-fit">
          <button onClick={handleCouponBrandButton}>
            <GroupIcon className={"group-hover:block hidden"} />
            <GroupIconBlack className={"group-hover:hidden block"} />
          </button>
        </div>
        {/* <input type="checkbox" className="h-5 w-5" /> */}
        <div className="flex flex-1 flex-col gap-1 justify-start">
          {name && <h4 className="text-[14px] font-bold">{name}</h4>}
          {description && (
            <p className="text-[12px] font-normal text-[#344767] opacity-90">
              {description}
            </p>
          )}
        </div>
        <div className="w-fit flex gap-2 items-center justify-between">
          <button
            onClick={handleViewCoupons}
            className=" text-[#2cccd3] hover:underline text-[14px]"
          >
            {/* {allCopuons?.length > 0 ? "View Coupons" : "No Coupon"} */}
            View Coupons
          </button>
          <SwitchToggle
            rounded={true}
            isToggled={isToggled}
            setIsToggled={setIsToggled}
            title="this action is for deals show enable"
            onChange={handleToggleChange}
          />
          <button onClick={(e) => setEditGroup(true)}>
            <EditGroupIcon className={"opacity-60 hover:opacity-100"} />
          </button>
          <button onClick={(e) => handleGroupDelete(e)}>
            <DeleteGroupIcon className={"opacity-60 hover:opacity-100"} />
          </button>
        </div>
      </div>
    </>
  );
};

export default Group;
