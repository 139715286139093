import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import Chip from "@mui/material/Chip";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import { SelectIcon } from "../../assets/svgs/selectIcon";
import { DataGrid } from "@mui/x-data-grid";
import Loader from "../../components/atoms/Loader";
import { GetCountryList, ToogleCountryStatus } from "../../api/api";
import dayjs from "dayjs";

const CountryList = () => {
  const [data, setData] = useState();
  const [selectedData, setSelectedData] = useState([]);

  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(true);

  const updateCountryStatus = async (updatedRow) => {
    // console.log(updatedRow);
    try {
      const res = await ToogleCountryStatus({
        ...updatedRow,
        //approved or rejected
      });
      console.log("response", res);
      const updateRows = data.map((item) =>
        item.id == updatedRow.id
          ? { ...updatedRow, status: updatedRow.status }
          : { ...item }
      );
      setData(updateRows);
    } catch (err) {
      console.log("err", err);
      setData(data);
    }
  };

  const setStatus = async (row, id) => {
    try {
      const updatedData = data.map((item) =>
        item.id === id
          ? { ...row, status: row.status === "A" ? "R" : "A" }
          : item
      );

      setData(updatedData);
      await updateCountryStatus({
        ...row,
        status: row.status === "A" ? "R" : "A",
      });
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    { field: "name", headerName: "Country Name", width: 200, editable: true },
    { field: "code", headerName: "Country Code", width: 150, editable: true },
    {
      field: "createdAt",
      headerName: "Created At (EST)",
      width: 200,
      // renderCell: (params) => {
      //   const options = {
      //     hour12: false,
      //     year: "numeric",
      //     month: "2-digit",
      //     day: "2-digit",
      //     hour: "2-digit",
      //     minute: "2-digit",
      //     second: "2-digit",
      //   };
      //   const formattedDateTime = new Date(params.row.createdAt)
      //     .toLocaleString("en-US", options)
      //     .replace(
      //       /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/,
      //       "$1-$2-$3 $4:$5:$6"
      //     );

      //   return <p>{formattedDateTime}</p>;
      // },
      renderCell: (params) => (
        <p className="" title="yyyy-mm-dd:hh:mm:ss time format EST">
          {dayjs(params?.row?.createdAt)
            ?.tz("America/New_York")
            .format("YYYY-MM-DD:HH:mm:ss")}
        </p>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (param) => {
        const data = param.row;

        const row = {
          ...data,
        };

        return (
          <div className={`flex flex-row gap-2`}>
            <Switch
              onChange={(_) => setStatus(row, data.id)}
              checked={data.status === "A" ? true : false}
            />
          </div>
        );
      },
    },
  ];

  const getAPI = async () => {
    try {
      const res = await GetCountryList();
      setData(res);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAPI();
  }, []);

  const handleSelectionChange = (selection) => {
    if (!selectAll) {
      setSelectedData(selection.selectionModel);
    } else {
      setSelectedData([]);
    }
  };

  const handleButtonClick = () => {
    if (selectAll) {
      setSelectAll(false);
      const newSelectionModel = data.map((row) => row.id);
      setSelectedData(newSelectionModel);
    } else {
      setSelectedData([]);
      setSelectAll(true);
    }
  };

  return (
    <div className={style.container}>
      {loading ? (
        <div className={`flex flex-row justify-center align-center`}>
          <Loader />
        </div>
      ) : (
        <>
          <div className={`flex flex-row justify-between align-center`}>
            <div className={`${style.title}`}>
              International Permitted Country List
            </div>
            <div className={`flex flex-row gap-2 align-center`}>
              <div className={`flex flex-row align-center gap-2`}>
                <button
                  onClick={handleButtonClick}
                  className={`${style.selectRows} ${
                    !selectAll ? style.rowsActive : ""
                  }`}
                >
                  <SelectIcon />
                  Select
                </button>
              </div>
            </div>
          </div>
          <div className={`flex flex-column gap-2 align-center h-auto`}>
            <Box sx={{ height: "83vh", width: "100%" }}>
              {data?.length > 0 ? (
                <DataGrid
                  rows={data}
                  columns={columns}
                  checkboxSelection={!selectAll}
                  onSelectionModelChange={handleSelectionChange}
                  selectionModel={selectedData}
                  pagination
                  pageSize={10}
                  onCellFocusOut={() => console.log("akljsldkf")}
                  onCellEditCommit={(params) => {
                    console.log("params", params, typeof params.value);
                    let updatedRow = data.map((item) =>
                      item.id === params.id ? item : null
                    );

                    const updatedRows = data.map((item) => {
                      if (item.id === params.id) {
                        return {
                          ...updatedRow,
                        };
                      }
                      return item;
                    });

                    updateCountryStatus(updatedRow);
                    setData(updatedRows);
                  }}
                />
              ) : null}
            </Box>
          </div>
        </>
      )}
    </div>
  );
};

export default CountryList;
