import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import "./App.css";
import Login from "./pages/Login";
import Layout from "./components/molecules/Layout";
import { useStateContext } from "./contexts/ContextProvider";
import { addInterceptors, authenticate } from "./api/api";
import AdsManager from "./pages/adsmaster/adsmanager";
import Threads from "./pages/adsmaster/Therads/Threads";
import WaitlistComponent from "./pages/waitlist/waitlist";
import AdsProfileComponent from "./pages/adsmaster/profiles/Profile";
import ProfileCampagins from "./pages/profileCampagins/ProfileCampagins";
import InstituteList from "./pages/institueList/InstituteList";
import KYCErrors from "./pages/adsmaster/kycErrors/kycErrors";
import EstuAds from "./pages/adsmaster/adsmanager/adsManager";
import DiscountCouponsPage from "./pages/adsmaster/discountCoupons/discountCoupons";
import AllowedUsers from "./pages/allowedUsers/AllowedUsers";
import Mbanq from "./pages/mbanq/mbanq";
import NonSSNStudent from "./pages/nonSSNStudent/nonSSNStudent";
import UserWaitList from "./pages/waitlist/UserWaitlist";
import CountryList from "./pages/countryList/CountryList";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Deals from "./pages/deals/Deals";
import GroupCouponsPage from "./pages/deals/GroupCoupons/GroupCoupon";
import CSV from "./pages/csv/CSV";
import { useAuth } from "./contexts/AuthContext";
import CouponsPage from "./pages/coupons/Coupons";
import DashboardPage from "./pages/dashboard/DashboardPage";
import Comms from "./pages/comms/Comms";
import AdsManagerDemo from "./pages/adsmaster/adsManagerDemo";
import CustomerReportsPage from "./pages/customerReports/CustomerReportsPage";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import CampaignManagement from "./pages/campaigns/CampaignManagement";
import ReferralsPage from "./pages/Referrals/ReferralsPage";
import Media from "./pages/media/Media";

const App = () => {
  const { setIsLoading, setUser } = useStateContext();
  const navigate = useNavigate();
  const { userDepartment, setUserEmail } = useAuth();

  const department = userDepartment();
  useEffect(() => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    if (token) {
      addInterceptors();
      checkIsAuthenticated();
    } else {
      setIsLoading(false);
      navigate("/login");
    }
  }, []);

  const checkIsAuthenticated = async () => {
    try {
      const { data } = await authenticate();
      setUser(data.data.user);
      setUserEmail(data.data.user.email);
      setIsLoading(false);
    } catch (error) {
      navigate("/login");
      setIsLoading(false);
    }
  };

  const AllPrivateRoutes = [
    // { path: "/ads", element: <AdsManager />, department: ["ADMIN"] },
    { path: "/ads", element: <AdsManagerDemo />, department: ["ADMIN"] },
    { path: "/estu-ads", element: <EstuAds />, department: ["ADMIN"] },
    {
      path: "/instituteList",
      element: <InstituteList />,
      department: ["ADMIN"],
    },
    { path: "/countryList", element: <CountryList />, department: ["ADMIN"] },
    { path: "/kycerrors", element: <KYCErrors />, department: ["ADMIN"] },
    { path: "/deals", element: <Deals />, department: ["ADMIN"] },
    { path: "/media", element: <Media />, department: ["ADMIN"] },
    { path: "/referrals", element: <ReferralsPage />, department: ["ADMIN"] },
    {
      path: "/deals/group-coupons/:groupId",
      element: <GroupCouponsPage />,
      department: ["ADMIN"],
    },
    { path: "/allowedUsers", element: <AllowedUsers />, department: ["ADMIN"] },
    { path: "/mbanq", element: <Mbanq />, department: ["ADMIN"] },
    {
      path: "/international-student",
      element: <NonSSNStudent />,
      department: ["ADMIN"],
    },
    // { path: "/waitlist", element: <UserWaitList />, department: ["ADMIN"] },
    { path: "/threads", element: <Threads />, department: ["ADMIN"] },
    {
      path: "/adsprofile",
      element: <AdsProfileComponent />,
      department: ["ADMIN"],
    },
    {
      path: "/adsprofilecampagin",
      element: <ProfileCampagins />,
      department: ["ADMIN"],
    },
    {
      path: "/estu-ads/discount-coupons/:id",
      element: <DiscountCouponsPage />,
      department: ["ADMIN"],
    },
    {
      path: "/upload-csv",
      element: <CSV />,
      department: ["ADMIN", "MARKETING"],
    },
    {
      path: "/comms",
      element: <Comms />,
      department: ["ADMIN", "MARKETING"],
    },
    {
      path: "/coupons-management",
      element: <CouponsPage />,
      department: ["ADMIN"],
    },
    {
      path: "/dashboard",
      element: <DashboardPage />,
      department: ["ADMIN"],
    },
    {
      path: "/customer-reports",
      element: <CustomerReportsPage />,
      department: ["ADMIN"],
    },
    {
      path: "/campaigns-management",
      element: <CampaignManagement />,
      department: ["ADMIN"],
    },
  ];

  return (
    <div>
      <ToastContainer />
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Layout />}>
            {AllPrivateRoutes?.filter((curr) =>
              curr.department.includes(department)
            )?.map((itemRoute, idx) => {
              return (
                <Route
                  key={idx}
                  path={itemRoute?.path}
                  element={itemRoute?.element}
                />
              );
            })}
            {/* <Route path="/ads" element={<AdsManager />} />
          <Route path="/estu-ads" element={<EstuAds />} />
          <Route path="/instituteList" element={<InstituteList />} />
          <Route path="/countryList" element={<CountryList />} />
          <Route path="/kycerrors" element={<KYCErrors />} />
          <Route path="/deals" element={<Deals />} />
          <Route
            path="/deals/group-coupons/:groupId"
            element={<GroupCouponsPage />}
          />
          <Route path="/allowedUsers" element={<AllowedUsers />}></Route>
          <Route path="/mbanq" element={<Mbanq />}></Route>
          <Route path="/non-ssn-student" element={<NonSSNStudent />}></Route>
          <Route path="/waitlist" element={<WaitlistComponent />} /> this removed
          <Route path="/waitlist" element={<UserWaitList />} />
          <Route path="/threads" element={<Threads />} />
          <Route path="/adsprofile" element={<AdsProfileComponent />} />
          <Route path="/adsprofilecampagin" element={<ProfileCampagins />} />
          <Route
            path="/estu-ads/discount-coupons/:id"
            element={<DiscountCouponsPage />}
          />
          <Route path="/upload-csv" element={<CSV />} /> */}
          </Route>
          <Route
            path="*"
            element={
              <div className="flex w-screen h-screen flex-col justify-center items-center">
                No Route for this user
              </div>
            }
          />
        </Routes>
      </LocalizationProvider>
    </div>
  );
};

export default App;
