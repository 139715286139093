import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { getDashboardAnalyticsAPI } from "../../api/api";
import { toast } from "react-toastify";
import {
  ApprovedIcon,
  CancelIcon,
  CouponsDashIcon,
  CouponsIcon,
  ErrorIcon,
  EventsDashIcon,
  StudyIcon,
  WaitingIcon,
  WaitingWatchIcon,
  WatchExclamatoryIcon,
} from "../../assets/svgs/svgs";

import waitingImage from "../../assets/imgs/waiting.png";
import { NotInterested, StopCircleOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
const DashboardPage = () => {
  const [data, setData] = useState({
    partners: [
      { name: "approved", desc: "Approved", total: 0 },
      { name: "pending", desc: "pending", total: 0 },
      { name: "rejected", desc: "rejected", total: 0 },
    ],

    events: [
      { name: "totalEvents", desc: "Total", total: 0 },
      { name: "pendingEvents", desc: "pending", total: 0 },
      { name: "expiredEvents", desc: "rejected", total: 0 },
    ],
    coupons: [
      { name: "totalCoupons", desc: "Total Events", total: 0 },
      { name: "pendingCoupons", desc: "pending Events", total: 0 },
      { name: "expiredCoupons", desc: "rejected Events", total: 0 },
    ],
    estu_app: [
      // { name: "totalEstuAppUsers", desc: "TOTAL ESTU APP USERS", total: 0 },
      { name: "nonKycCustomers", desc: "Non-KYC Customers", total: 0 },
      { name: "kycCustomers", desc: "KYC(Banking) Customers", total: 0 },
      // { name: "waitlistStudents", desc: "WAITLIST STUDENTS", total: 0 },
      { name: "internationalStudents", desc: "International USERS", total: 0 },
      { name: "totalInstitutions", desc: "TOTAL INSTITUTIONS", total: 0 },
    ],
    i20_students: [
      { name: "approved", desc: "Approved", total: 0 },
      { name: "pending", desc: "pending", total: 0 },
      { name: "rejected", desc: "rejected", total: 0 },
    ],
  });

  const getAPI = async () => {
    //API call
    try {
      const response = await getDashboardAnalyticsAPI();
      // console.log(response);
      setData(response?.data);
    } catch (error) {
      console.log(error);
      //   toast.error("something went wrong with fetching data");
    }
  };
  useEffect(() => {
    getAPI();
  }, []);
  return (
    <div className="px-4 py-[12px] flex flex-col gap-6 justify-start">
      {/* //Layout have p-4 already */}
      <h2 className="text-[#344767] font-roboto font-bold text-2xl leading-[26px]">
        Dashboard
      </h2>
      <hr />
      <div className="flex flex-col">
        <DashboardPageComponent
          title={"Partners"}
          data={data?.partners}
          type="partner"
        />
        <DashboardPageComponent
          title={"Coupon & events"}
          data={data?.coupons}
          type="coupon"
        />
        <DashboardPageComponent data={data?.events} type="event" />
        <DashboardPageComponent
          title={"International Students"}
          data={data?.i20_students}
          type="i20_students"
        />
        <DashboardPageComponent
          title={"Estu Customers"}
          data={data?.estu_app}
          type="estu_app"
        />
      </div>
    </div>
  );
};

const DashboardPageComponent = ({ title, data, type }) => {
  return (
    <div
      className={classNames("flex flex-col mb-6", {
        "gap-0": !title,
        "gap-6": title,
      })}
    >
      {title && (
        <h5 className="text-[#344767] font-roboto font-medium text-2xl leading-[26px] select-none transition-transform hover:scale-105 cursor-pointer text-left w-fit">
          {title}
        </h5>
      )}
      <div className="flex-1 md:grid md:grid-cols-3 grid grid-cols-2 gap-4">
        {data?.map((item, idx) => (
          <ChipCard key={idx} data_obj={item} type={type} />
        ))}
      </div>
    </div>
  );
};

const ChipCard = ({ data_obj, type = "" }) => {
  const navigate = useNavigate();
  const handleChipClick = () => {
    if (type === "partner") {
      // console.log(type, data_obj);
      switch (data_obj?.name?.toUpperCase()) {
        case "APPROVED":
          navigate("/estu-ads?status=APPROVED", {
            state: { from: "dashboard" },
          });
          break;
        case "PENDING":
          navigate("/estu-ads?status=PENDING", {
            state: { from: "dashboard" },
          });
          break;
        case "REJECTED":
          navigate("/estu-ads?status=REJECTED", {
            state: { from: "dashboard" },
          });
          break;
        default:
          console.log(
            "SOMETHING WRONG WITH DATA PLEASE CHECK AND ASK BACKEND DEVELOPER WHY CHANGED DATA"
          );
          navigate("/estu-ads", {
            state: { from: "dashboard" },
          });
          break;
      }
      // navigate("/estu-ads");
    } else if (type == "coupon") {
      // console.log(type, data_obj);
      switch (data_obj?.name?.toUpperCase()) {
        case "TOTALCOUPONS":
          navigate("/coupons-management", {
            state: { from: "dashboard", activeTab: 2 },
          });
          break;
        case "PENDINGCOUPONS":
          navigate("/coupons-management?filter=PENDING", {
            state: { from: "dashboard", activeTab: 2 },
          }); // pending coupons
          break;
        case "EXPIREDCOUPONS":
          navigate("/coupons-management?status=EXPIRED", {
            state: { from: "dashboard", activeTab: 2 },
          }); // pending coupons
          break;
        default:
          navigate("/coupons-management", {
            state: { from: "dashboard", activeTab: 2 },
          });
          break;
      }
    } else if (type === "event") {
      switch (data_obj?.name?.toUpperCase()) {
        case "TOTALEVENTS":
          navigate("/coupons-management", {
            state: { from: "dashboard", activeTab: 1 },
          });
          break;
        case "PENDINGEVENTS":
          navigate("/coupons-management?filter=PENDING", {
            state: { from: "dashboard", activeTab: 1 },
          }); // pending coupons
          break;
        case "EXPIREDEVENTS":
          // console.log(type, data_obj);
          navigate("/coupons-management?status=EXPIRED", {
            state: { from: "dashboard", activeTab: 1 },
          }); // pending coupons
          break;
        default:
          navigate("/coupons-management", {
            state: { from: "dashboard", activeTab: 1 },
          });
          break;
      }
    } else if (type === "i20_students") {
      switch (data_obj?.name?.toUpperCase()) {
        case "APPROVED":
          navigate("/international-student", {
            state: { from: "dashboard", activeTab: 0, currStatus: "ACCEPT" }, //kyc
          });
          break;
        case "PENDING":
          navigate("/international-student", {
            state: { from: "dashboard", activeTab: 1, currStatus: "PENDING" }, //non-kyc
          });
          break;
        case "REJECTED":
          navigate("/international-student", {
            state: { from: "dashboard", activeTab: 2, currStatus: "REJECTI20" }, //non-kyc
          });
          break;
        default:
          console.log(
            "SOMETHING WRONG WITH DATA PLEASE CHECK AND ASK BACKEND DEVELOPER WHY CHANGED DATA"
          );
          navigate("/international-student", {
            state: { from: "dashboard" },
          });
          break;
      }
    } else if (data_obj?.name === "totalEstuAppUsers") {
      //   toast.info("No route Defined yet!");
      navigate("/customer-reports", { state: { from: "dashboard" } });
    } else if (data_obj?.name === "nonKycCustomers") {
      //   toast.info("No route Defined yet!");
      navigate("/customer-reports", {
        state: { from: "dashboard", activeTab: 1 },
      });
    } else if (data_obj?.name === "kycCustomers") {
      //   toast.info("No route Defined yet!");
      navigate("/customer-reports", {
        state: { from: "dashboard", activeTab: 0 },
      });
    } else if (data_obj?.name === "waitlistStudents") {
      navigate("/waitlist", { state: { from: "dashboard" } });
    } else if (data_obj?.name === "internationalStudents") {
      navigate("/international-student", { state: { from: "dashboard" } });
    } else if (data_obj?.name === "totalInstitutions") {
      navigate("/instituteList", { state: { from: "dashboard" } });
    } else {
      toast.info("No route Defined yet!");
    }
  };
  console.log(data_obj?.name);
  return (
    <div
      onClick={handleChipClick}
      className="w-full px-6 py-[26.5px] bg-white shadow-sm hover: cursor-pointer hover:shadow rounded-lg hover:scale-105 transition-transform flex flex-row gap-4 justify-start items-center"
    >
      <span
        className={classNames(
          "w-12 h-12 rounded-lg hover:scale-105 transition-all duration-300 flex flex-col justify-center items-center overflow-hidden shadow-sm",
          { "bg-[#12A58C]": data_obj?.name?.toLowerCase() === "approved" },
          { "bg-[#E0AF00]": data_obj?.name?.toLowerCase() === "pending" },
          { "bg-[#F6285F]": data_obj?.name?.toLowerCase() === "rejected" },
          { "bg-[#F6285F]": data_obj?.name?.toLowerCase() === "expired" },
          { "bg-[#2CCCD3]": type !== "partner" },
          {
            "bg-[#E0AF00]": data_obj?.name?.toLowerCase() === "pendingcoupons",
          },
          { "bg-[#E0AF00]": data_obj?.name?.toLowerCase() === "pendingevents" },

          {
            "bg-[#F6285F]": data_obj?.name?.toLowerCase() === "expiredcoupons",
          },
          { "bg-[#F6285F]": data_obj?.name?.toLowerCase() === "expiredevents" },
          {
            "bg-[#F6285F]": data_obj?.name?.toLowerCase() === "rejectedcoupons",
          },
          {
            "bg-[#F6285F]": data_obj?.name?.toLowerCase() === "rejectedevents",
          },
          {
            "bg-[#E0AF00]":
              data_obj?.name?.toLowerCase() === "waitliststudents",
          },
          {
            "bg-[#C0F]":
              data_obj?.name?.toLowerCase() === "internationalstudents",
          }
        )}
      >
        {type === "partner" ? (
          data_obj?.name?.toLowerCase() === "approved" ? (
            <ApprovedIcon />
          ) : data_obj?.name?.toLowerCase() === "pending" ? (
            <WaitingIcon />
          ) : (
            <CancelIcon />
          )
        ) : (
          ""
        )}

        {type === "coupon" ? (
          data_obj?.name === "totalCoupons" ? (
            <CouponsDashIcon />
          ) : data_obj?.name === "pendingCoupons" ? (
            <WaitingWatchIcon />
          ) : (
            <ErrorIcon />
          )
        ) : (
          ""
        )}

        {type === "event" ? (
          data_obj?.name === "totalEvents" ? (
            <EventsDashIcon />
          ) : data_obj?.name === "pendingEvents" ? (
            <WaitingIcon />
          ) : (
            <WatchExclamatoryIcon />
          )
        ) : (
          ""
        )}
        {type === "i20_students" ? (
          data_obj?.name?.toLowerCase() === "approved" ? (
            <ApprovedIcon />
          ) : data_obj?.name?.toLowerCase() === "pending" ? (
            <WaitingIcon />
          ) : (
            <CancelIcon />
          )
        ) : (
          ""
        )}

        {type === "estu_app" ? (
          data_obj?.name === "totalEstuAppUsers" ? (
            <ApprovedIcon />
          ) : data_obj?.name === "waitlistStudents" ? (
            <WaitingIcon />
          ) : data_obj?.name === "totalInstitutions" ? (
            <StudyIcon />
          ) : data_obj?.name === "internationalStudents" ? (
            <>
              <NotInterested className="text-white" />
              <p className="text-white font-bold">SSN</p>
            </>
          ) : (
            <WatchExclamatoryIcon />
          )
        ) : (
          ""
        )}
      </span>
      <div className="flex flex-col gap-0">
        <p className="text-[#344767] font-montserrat text-2xl font-bold leading-[27.5px] p-0 m-0 [font-style:normal]">
          {data_obj?.total || 0}
        </p>
        <p className="text-[#2C3030] text-center font-montserrat text-[15px] font-semibold leading-5 uppercase p-0 m-0 [font-style:normal]">
          {data_obj?.desc}
          {type === "i20_students" ? " Customer" : ""}
        </p>
      </div>
    </div>
  );
};

export default DashboardPage;
