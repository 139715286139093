/* eslint-disable react/prop-types */

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePrevPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    if (currentPage > 2) {
      pageNumbers.push(1);
      if (currentPage > 3) {
        pageNumbers.push("...");
      }
    }

    if (currentPage > 1) {
      pageNumbers.push(currentPage - 1);
    }

    pageNumbers.push(currentPage);

    if (currentPage < totalPages) {
      pageNumbers.push(currentPage + 1);
    }

    if (currentPage < totalPages - 1) {
      if (currentPage < totalPages - 2) {
        pageNumbers.push("...");
      }
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  return (
    <div className="flex items-center">
      <button
        className={`px-4 py-2 bg-white border border-[#E9F0F2] text-[#2CCCD3] rounded ${
          currentPage === 1 ? "cursor-not-allowed" : ""
        }`}
        onClick={handlePrevPage}
        disabled={currentPage === 1}
      >
        &lt;
      </button>
      {renderPageNumbers().map((page, index) =>
        page === "..." ? (
          <span key={index} className="px-4 py-2">
            ...
          </span>
        ) : (
          <button
            key={index}
            className={`px-4 py-2 border border-[#E9F0F2] rounded ${
              page === currentPage ? "bg-[#2CCCD3] text-white" : "bg-white"
            }`}
            onClick={() => onPageChange(page)}
          >
            {page}
          </button>
        )
      )}
      <button
        className={`px-4 py-2 bg-white border border-[#E9F0F2] text-[#2CCCD3] rounded ${
          currentPage === totalPages ? "cursor-not-allowed" : ""
        }`}
        onClick={handleNextPage}
        disabled={currentPage === totalPages}
      >
        &gt;
      </button>
    </div>
  );
};

export default Pagination;
