import React, { useState } from "react";
import DeleteConfirmation from "../../../components/DeleteConfirmation/DeleteConfirmation";
import amazonLogo from "../../../assets/imgs/amazon-logo.png";

import { Line } from "../../../assets/svgs/svgs";
import QrImage from "../../../assets/imgs/qr.png";
import { MdEdit, MdDelete, MdDeleteOutline } from "react-icons/md";
import style from "./style.module.css";
import moment from "moment";
const ViewAmazonAffiliateCoupon = ({ data, user, setView, update }) => {
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const handleDeleteConfirm = async () => {
		// await UpdateDiscount(data, "delete");
		alert("You can not delete it");
		setView((prev) => ({ ...prev, view: false }));
		update((prev) => !prev);
		setShowDeleteConfirmation(false);
	};

	const handleDeleteCancel = () => {
		setShowDeleteConfirmation(false);
	};
	return (
		<div className={`flex flex-col p-3 align-center justify-center`}>
			{showDeleteConfirmation && (
				<DeleteConfirmation
					onDelete={handleDeleteConfirm}
					onCancel={handleDeleteCancel}
				/>
			)}
			<div
				className={
					"flex items-center flex-col justify-center gap-4 p-6 rounded-lg bg-white border border-teal-400 w-[350px]"
				}
			>
				<div className={`flex relative flex-col gap-2 items-center`}>
					{data?.poster && (
						<img
							src={amazonLogo}
							className='w-[64px] h-[20px] absolute top-0 right-0 rounded-10'
							alt={user?.profilePhoto}
						/>
					)}
					<div>
						<img
							src={`${data?.poster}`}
							className='w-80 h-[114px] rounded-10'
							alt={user?.profilePhoto}
						/>
					</div>
				</div>
				<div className={`flex flex-col gap-4 text-center w-[100%] mt-7`}>
					<p className={style.form_title}>{data.headline}</p>
					<p className={style.form__des}>{data.caption}</p>
					{/* <div>{moment(data.updatedAt).format("DD MMM, YYYY - h:mm a")}</div> */}

					<div className={`${style.cardStatus} items-center justify-center`}>
						<div
							className={`${style.cardCircle} ${
								data.status === "active" ? style.activeCircle : ""
							}`}
						></div>
						<div className={style.cardStatusText}>
							{data.status}&nbsp;{data.isStatus}
						</div>
					</div>
					<div className=' overflow-hidden flex justify-center'>
						<Line />
					</div>
					<div className={`flex flex-col gap-6 items-center`}>
						<div className='text-center border-b-[#2CCCD3] border-b-2 py-0 leading-3'>
							<a
								href={data.website}
								className='text-14 font-semibold text-[#2CCCD3]'
								target='_blank'
							>
								Deal Link
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className='flex items-start gap-2 flex-row w-100 mt-8'>
				{/* <button
                onClick={(_) => {
                  setEdit(true);
                  setFormDataDiscount(data);
                }}
                className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex flex-row gap-2 items-center`}
              >
                <MdEdit /> Edit
              </button> */}
				{/* <button
					onClick={(_) => {
						setShowDeleteConfirmation(true);
					}}
					className={`text-[#F6285F] font-semibold mx-auto px-4 text-14 rounded flex flex-row gap-2 items-center`}
				>
					Delete deal <MdDeleteOutline color='#F6285F' />
				</button> */}
			</div>
		</div>
	);
};

export default ViewAmazonAffiliateCoupon;
