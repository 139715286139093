import { useEffect, useState } from "react";
import { getReferralsListAPI } from "../../api/api";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Loader } from "../../components";
import dayjs from "dayjs";

const ReferralsPage = () => {
  const [loading, setLoading] = useState(false);
  const [cData, setCData] = useState([]);

  const getAPI = async () => {
    setLoading(true);

    try {
      const res = await getReferralsListAPI();

      // console.log(codes, "bharattt");
      // console.log(res);
      if (res.status === "success") {
        setCData(res?.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAPI();
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "referrer_name", headerName: "Referrer Name", width: 150 },

    {
      field: "ref_code",
      headerName: "referral code",
      width: 100,
    },
    { field: "customer_name", headerName: "Customer Name", width: 150 },
    {
      field: "date_of_join",
      headerName: "Joining Date (EST)",
      // valueGetter: (params) => {
      //   // Format the date using Day.js
      //   return dayjs(params.row.date_of_join).format("YYYY-MM-DD");
      // },
      renderCell: (params) => (
        <p className="" title="yyyy-mm-dd time format EST">
          {dayjs(params?.row?.data_of_join)
            ?.tz("America/New_York")
            .format("YYYY-MM-DD")}
        </p>
      ),
      width: 150,
    },
  ];
  const handleOpenModal = () => {};
  // console.log(cData);

  return (
    <div className="mx-auto w-full pt-4 max-h-screen overflow-auto h-[99vh]">
      <div className="flex justify-between items-center w-full">
        {" "}
        <h1 className="text-2xl font-bold mb-4">Referrals Management</h1>
        {/* <button
          className="bg-[#2cccd3] text-white px-4 py-2 rounded-lg mb-4 cursor-pointer hover:shadow-md"
          onClick={handleOpenModal}
        >
          Create Referral
        </button> */}
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div>
            Loading... <Loader />
          </div>
        </div>
      ) : (
        <Box
          sx={{
            height: "90%",
            width: "100%",
            backgroundColor: "white",
            flex: 1,
            borderRadius: "12px",
          }}
        >
          <DataGrid
            rows={cData.map((item, index) => {
              // Check if the item has an 'id' key
              if (!item.hasOwnProperty("id")) {
                // If not, add a unique 'id' (using index or a library like uuid)
                return { ...item, id: index }; // Replace index with a unique ID if needed
              }
              return item; // Return the item unchanged if it already has an id
            })}
            columns={columns}
            pagination
            pageSize={10}
          />
        </Box>
      )}
    </div>
  );
};
export default ReferralsPage;
