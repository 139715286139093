export const EditRowFormConfig = [
  { name: "savingAccountId", placeholder: "Saving Account Id" },
  { name: "cardId", placeholder: "Card Id" },
  { name: "clientId", placeholder: "Client Id" },
  { name: "savingAccountNumber", placeholder: "Saving Account Number" },
];

export const EditAdmasterUserFormConfig = [
  { name: "image", placeholder: "Profile image", type: "profilePhoto" },
  { name: "instituteName", placeholder: "Institute Name" },
  { name: "phoneNumber", placeholder: "Phone Number" },
  { name: "email", placeholder: "Email Id", type: "email", disabled: true },
  { name: "industry", placeholder: "Industry", type: "select" },
  { name: "website", placeholder: "Website" },
  { name: "ein", placeholder: "EIN Number *" },
  { name: "serviceRadius", placeholder: "Service Radius", type: "number" },
  {
    name: "contractAddress.address1",
    placeholder: "Address1 *",
    type: "contractAddress",
  },
  {
    name: "contractAddress.address2",
    placeholder: "Address2(optional)",
    type: "contractAddress",
  },
  {
    name: "contractAddress.city",
    placeholder: "City *",
    type: "contractAddress",
  },
  {
    name: "contractAddress.state",
    placeholder: "State *",
    type: "contractAddress",
  },
  {
    name: "contractAddress.zipCode",
    placeholder: "Zip Code *",
    type: "contractAddress",
  },
  {
    name: "companyType",
    placeholder: "Company Type",
    type: "radioType",
    options: [
      {
        // value: "Physical",
        value: "Local",
        // title: "companies that operate across the entire U.S.",
        title: "Operate Across Local Area",
        label: "Local",
      },
      {
        // value: "Virtual",
        value: "National",
        title: "national",
        label: "National",
      },
      // {
      //   value: "Both",
      //   //  title: "Both virtual and physical",
      //   title: "Operate Across Both Local and National Areas",

      //   label: "Both",
      // },
    ],
  },
  {
    name: "deliveryAddress.address1",
    placeholder: "Address1 *",
    type: "deliveryAddress",
  },
  {
    name: "deliveryAddress.address2",
    placeholder: "Address2(optional)",
    type: "deliveryAddress",
  },
  {
    name: "deliveryAddress.city",
    placeholder: "City *",
    type: "deliveryAddress",
  },
  {
    name: "deliveryAddress.state",
    placeholder: "State *",
    type: "deliveryAddress",
  },
  {
    name: "deliveryAddress.zipCode",
    placeholder: "Zip Code *",
    type: "deliveryAddress",
  },
  {
    name: "amazonAffiliate",
    placeholder: "Amazon Affiliate",
    type: "checkbox",
  },

  // { name: "profilePhoto", placeholder: "Profile Photo" },
];
//  instituteName: "",
//     phoneNumber: "",
//     website: "",
//     industry: "",
//     profilePhoto: "",
