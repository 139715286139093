import React from "react";
import {
	rectSortingStrategy,
	SortableContext,
	useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useDraggable } from "@dnd-kit/core";

const UserBrand = ({
	id,
	profilePhoto,
	instituteName,
	selectedBrandId,
	setSelectedBrandId,
}) => {
	const { attributes, listeners, setNodeRef, transform } = useDraggable({
		id: `brand-${id}`,
		data: { type: "brand", id: id },
	});

	return (
		<div
			ref={setNodeRef}
			{...attributes}
			{...listeners}
			className={`relative w-full bg-white hover:bg-[#f2f2f3] min-h-[52px] rounded-md group p-2 flex items-center justify-start gap-2 touch-none overflow-hidden ${
				selectedBrandId === id ? "border-2 border-[#2cccd3]" : ""
			}`}
			style={{
				boxShadow: "rgba(0,0,0,0.16) 0px 1px 4px",

				transform: CSS.Transform.toString(transform),
			}}
		>
			<img
				src={`${process.env.REACT_APP_IMAGE_BASEURL}${profilePhoto}`}
				alt='b'
				className='w-9 h-9 rounded-full'
			/>
			<h4 className='text-[14px] text-[#344767] font-bold truncate'>
				{instituteName}
			</h4>
			<button
				className='absolute top-0 left-0 right-0 bottom-0 z-40'
				onClick={() => setSelectedBrandId(id)}
			></button>
		</div>
	);
};

export default UserBrand;
