import dayjs from "dayjs";
export const generateImageUrl = (id, type = "string") => {
  if (type === "string") {
    return `${process.env.REACT_APP_IMAGE_BASEURL}${id}`;
  } else {
    return `${process.env.REACT_APP_IMAGE_BASEURL}${JSON.parse(id)}`;
  }
};

// export const isValidDate = (value) => {
//   console.log(value, "bgh");
//   if (value instanceof Date) {
//     // Check if it's a Date object and valid
//     return !isNaN(value.getTime());
//   }

//   if (typeof value === "string") {
//     const date = new Date(value);
//     return !isNaN(date.getTime());
//   }

//   return false;
// };

export const isValidDate = (date) => {
  // Check if date is a dayjs object and is valid
  if (!date || !dayjs(date).isValid()) {
    return false;
  }

  // Check if the date object has day, month, and year components
  const day = date.$D;
  const month = date.$M;
  const year = date.$y;

  // Check if day, month, and year are valid
  return (
    day != null &&
    month != null &&
    year != null &&
    day > 0 &&
    month >= 0 &&
    year > 0
  );
};

export const formatDateToYYYYMMDD = (dateString) => {
  const date = new Date(dateString);

  // Ensure date components are two digits by padding with zeros if necessary
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so add 1
  const day = String(date.getUTCDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};
