import classNames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  ApprovedIcon,
  CancelIcon,
  ErrorIcon,
  WaitingIcon,
  WaitingWatchIcon,
  EmailIcon,
} from "../../../../assets/svgs/svgs";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import { DoneOutline } from "@mui/icons-material";

const StatusBoard = ({ data = {}, setSelectedStatus, selectedStatus }) => {
  const handleButtonClick = (status) => {
    // handle button click and update the state
    setSelectedStatus(status);
  };
  //   console.log(data);
  return (
    <div className="flex flex-row justify-between items-center gap-4">
      <ChipCard
        type="total"
        data_obj={{ total: data?.totalCount, desc: "All" }}
        onClick={() => handleButtonClick("")}
        selectedStatus={selectedStatus}
      />
      <ChipCard
        type="delivered"
        data_obj={{ total: data?.deliveryCount, desc: "delivered" }}
        onClick={() => handleButtonClick("DELIVERED")}
        selectedStatus={selectedStatus}
      />
      <ChipCard
        type="pending"
        data_obj={{ total: data?.pendingCount, desc: "pending" }}
        onClick={() => handleButtonClick("PENDING")}
        selectedStatus={selectedStatus}
      />
      <ChipCard
        type="failed"
        data_obj={{ total: data?.failedCount, desc: "failed" }}
        onClick={() => handleButtonClick("FAILED")}
        selectedStatus={selectedStatus}
      />
      <ChipCard
        type="stopped"
        data_obj={{ total: data?.stoppedCount, desc: "stopped" }}
        onClick={() => handleButtonClick("STOPPED")}
        selectedStatus={selectedStatus}
      />
    </div>
  );
};

const ChipCard = ({ data_obj, type = "", onClick, selectedStatus }) => {
  const navigate = useNavigate();
  const handleChipClick = () => {
    if (type === "partner") {
      navigate("/estu-ads");
    } else if (type == "coupon") {
      navigate("/coupons-management", { state: { from: "dashboard" } });
    } else if (type === "event") {
      navigate("/coupons-management", { state: { from: "dashboard" } });
    }
  };
  return (
    <div
      onClick={onClick}
      className={classNames(
        "w-full px-6 py-[26.5px] cursor-pointer bg-white shadow-sm hover:shadow rounded-lg hover:scale-105 transition-transform flex flex-row gap-4 justify-start items-center",
        {
          "border-2 border-[#12A58C]":
            selectedStatus?.toLowerCase() === "delivered" &&
            type === "delivered",
        },
        {
          "border-2 border-[#E0AF00]":
            selectedStatus?.toLowerCase() === "pending" && type === "pending",
        },
        {
          "border-2 border-[#F6285F]":
            selectedStatus?.toLowerCase() === "failed" && type === "failed",
        },
        {
          "border-2 border-[#2cccd3]":
            selectedStatus?.toLowerCase() === "" && type === "total",
        },
        {
          "border-2 border-[#bc9236]":
            selectedStatus?.toLowerCase() === "stopped" && type === "stopped",
        }
      )}
    >
      <span
        className={classNames(
          "w-12 h-12 rounded-lg hover:scale-105 transition-all duration-300 flex flex-col justify-center items-center overflow-hidden shadow-sm",
          { "bg-[#12A58C]": type?.toLowerCase() === "delivered" },
          { "bg-[#E0AF00]": type?.toLowerCase() === "pending" },
          { "bg-[#F6285F]": type?.toLowerCase() === "failed" },
          { "bg-[#2cccd3]": type?.toLowerCase() === "total" },
          { "bg-[#bc9236]": type?.toLowerCase() === "stopped" }
        )}
      >
        {type === "delivered" ? (
          <MarkEmailReadIcon className="text-white" />
        ) : type?.toLowerCase() === "pending" ? (
          <WaitingIcon />
        ) : type?.toLowerCase() === "failed" ? (
          <ErrorIcon />
        ) : type?.toLowerCase() === "total" ? (
          <DoneOutline className="text-white" />
        ) : type?.toLocaleLowerCase() === "stopped" ? (
          <ErrorIcon />
        ) : (
          ""
        )}
      </span>
      <div className="flex flex-col gap-1" onClick={handleChipClick}>
        <p className="text-[#344767] font-montserrat text-2xl font-bold leading-[27.5px] p-0 m-0 [font-style:normal]">
          {data_obj?.total || 0}
        </p>
        <p className="text-[#2C3030] text-center font-montserrat text-[14px] font-semibold leading-5 uppercase p-0 m-0 [font-style:normal]">
          {data_obj?.desc}
        </p>
      </div>
    </div>
  );
};

export default StatusBoard;
