const formConfig = [
  {
    name: "name",
    label: "Name",
    type: "text",
    placeholder: "Enter institute name",
    required: true,
  },
  {
    name: "domains",
    label: "Domains",
    type: "textarea",
    placeholder:
      "Add multiple domains separated by commas (e.g., yopmail.com, gmail.com)",
    required: true,
  },
];
export default formConfig;
