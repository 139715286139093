import React, { useEffect, useState, useRef } from "react";
import style from "../allowedUsers/style.module.css";
import Box from "@mui/material/Box";
import Loader from "./../../components/atoms/Loader";
import { DataGrid } from "@mui/x-data-grid";
import { getNonSSNDetails } from "../../api/api";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import classNames from "classnames";

const columns = [
  { field: "id", headerName: "id", width: 50, hide: true },
  { field: "name", headerName: "Name", width: 200 },
  { field: "email", headerName: "Email", width: 200 },
  { field: "mobileNo", headerName: "Mobile No", width: 150 },
  // {
  //   field: "userId",
  //   headerName: "User Id",
  //   width: 200,
  // },
  {
    field: "createdAt",
    headerName: "Created At (EST)",
    width: 200,
    // renderCell: (params) => {
    //   const options = {
    //     hour12: false,
    //     year: "numeric",
    //     month: "2-digit",
    //     day: "2-digit",
    //     hour: "2-digit",
    //     minute: "2-digit",
    //     second: "2-digit",
    //   };
    //   const formattedDateTime = new Date(params.row.createdAt)
    //     .toLocaleString("en-US", options)
    //     .replace(
    //       /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/,
    //       "$1-$2-$3 $4:$5:$6"
    //     );

    //   return <p>{formattedDateTime}</p>;
    // },
    renderCell: (params) => (
      <p className="" title="yyyy-mm-dd:hh:mm:ss time format EST">
        {dayjs(params?.row?.createdAt)
          ?.tz("America/New_York")
          .format("YYYY-MM-DD:HH:mm:ss")}
      </p>
    ),
  },
];

export default function NonSSNStudent() {
  const [rowData, setRowData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();
  const [statusFilter, setStatusFilter] = useState(
    location?.state?.currStatus || ""
  );
  // console.log(location?.state, statusFilter, "bh");

  useEffect(() => {
    // console.log(activeTab, "bbbb");
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getNonSSNDetails({ kyc: statusFilter });
        // console.log("data", data?.data?.internationalStudents);
        setLoading(false);
        // setRowData(data?.data?.acknowledgeSsnsUser);
        setRowData(data?.data?.internationalStudents);
      } catch (err) {
        console.log("err", err);
        toast.error(err.response?.data?.error);
        setLoading(false);
      }
    };

    fetchData();
  }, [activeTab, statusFilter]);

  useEffect(() => {
    const { state } = location;
    // console.log(activeTab, "bbbb", state);
    if (state && state.activeTab) {
      setActiveTab(state.activeTab);

      setStatusFilter(state?.currStatus);
    } else {
      setActiveTab(0);
    }
  }, [location]);

  return (
    <div className={style.container}>
      {/* <div className={style.header}>
        <div className={style.title}>International Student Details</div>
      </div> */}
      <div className={"flex w-full items-center justify-between mb-4"}>
        <div className={style.title}>International Student Details</div>
        {/* <div className={`flex flex-row gap-3`} style={{ alignItems: "center" }}>
          <div
            onClick={(_) => {
              setStatusFilter("ACCEPT");
              setActiveTab(0);
            }}
            className={classNames(
              "cursor-pointer w-[148px] h-[43px] py-3 px-[19px] flex flex-row justify-center items-center border-b gap-1 text-[#67748e] font-montserrat text-base font-semibold leading-4 [letter-spacing:_0.3428399860858917px] text-center border-b-white transition-[0.2s_all_ease-in-out]",
              {
                "font-montserrat border-b border-b-[#67748e] text-base transition-[0.2_all_ease-in-out] font-bold leading-4 [letter-spacing:_0.3428399860858917px;] text-center":
                  activeTab === 0,
              }
            )}
          >
            Accept
          </div>
          <div
            onClick={(_) => {
              setStatusFilter("PENDING");
              setActiveTab(1);
            }}
            className={classNames(
              "cursor-pointer w-[148px] h-[43px] py-3 px-[19px] flex flex-row justify-center items-center border-b gap-1 text-[#67748e] font-montserrat text-base font-semibold leading-4 [letter-spacing:_0.3428399860858917px] text-center border-b-white transition-[0.2s_all_ease-in-out]",
              {
                "font-montserrat border-b border-b-[#67748e] text-base transition-[0.2_all_ease-in-out] font-bold leading-4 [letter-spacing:_0.3428399860858917px;] text-center":
                  activeTab === 1,
              }
            )}
          >
            Pending
          </div>
          <div
            onClick={(_) => {
              setStatusFilter("REJECTI20");
              setActiveTab(2);
            }}
            className={classNames(
              "cursor-pointer w-[148px] h-[43px] py-3 px-[19px] flex flex-row justify-center items-center border-b gap-1 text-[#67748e] font-montserrat text-base font-semibold leading-4 [letter-spacing:_0.3428399860858917px] text-center border-b-white transition-[0.2s_all_ease-in-out]",
              {
                "font-montserrat border-b border-b-[#67748e] text-base transition-[0.2_all_ease-in-out] font-bold leading-4 [letter-spacing:_0.3428399860858917px;] text-center":
                  activeTab === 2,
              }
            )}
          >
            RejectI20
          </div>
        </div> */}

        <div className="flex flex-row items-center gap-2 border-b pb-2">
          <label
            htmlFor="statusFilter"
            className="text-sm font-medium text-gray-700"
          >
            Filter by Customer:
          </label>
          <select
            id="statusFilter"
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            className="border border-gray-300 rounded-md px-2 py-1 text-sm cursor-pointer"
          >
            <option value="">All</option>
            <option value="ACCEPT">Approved</option>
            <option value="PENDING">Pending</option>
            <option value="REJECTI20">Rejected</option>
          </select>
        </div>
      </div>

      {loading ? (
        <div className={`flex flex-row justify-center align-center`}>
          <Loader />
        </div>
      ) : (
        <>
          <div className={`flex flex-column gap-2 align-center h-auto`}>
            <Box sx={{ height: "83vh", width: "100%" }}>
              {rowData?.length > 0 ? (
                <DataGrid
                  rows={rowData}
                  columns={columns}
                  pagination
                  pageSize={10}
                />
              ) : null}
            </Box>
          </div>
        </>
      )}
    </div>
  );
}
