import React, { useEffect, useState } from "react";
import { startStopCommsMessagesAPI } from "../../../api/api";
import { DataGrid } from "@mui/x-data-grid";
import { CircularProgress } from "@mui/material";
import DownloadCSV from "../../../shared/downloadCSV/DownloadCSV";
import dayjs from "dayjs";

const StartStop = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const getAPI = async () => {
    setLoading(true);
    try {
      const response = await startStopCommsMessagesAPI();
      console.log(response);
      setData(response?.data);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAPI();
  }, []);

  const columns = [
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 200,
      download: true,
    },
    {
      field: "preference",
      headerName: "Preference",
      width: 150,
      download: true,
    },
    {
      field: "createdAt",
      headerName: "Created At (EST)",
      width: 200,
      download: true,
      completeDate: true,
      // valueGetter: (params) => {
      //   // Format the date for display
      //   const date = new Date(params.value);
      //   return date.toLocaleDateString() + " " + date.toLocaleTimeString();
      // },

      renderCell: (params) => (
        <p className="" title="yyyy-mm-dd:hh:mm:ss time format EST">
          {dayjs(params?.row?.createdAt)
            ?.tz("America/New_York")
            .format("YYYY-MM-DD:HH:mm:ss")}
        </p>
      ),
    },
  ];

  return (
    <div className="flex flex-col justify-center items-center h-full text-gray-600 font-montserrat">
      {loading ? (
        <CircularProgress style={{ color: "#2cccd3" }} />
      ) : data?.length === 0 ? (
        <div>NO Data Available!</div>
      ) : (
        <div className="flex-grow flex  flex-col w-full p-0.5">
          <div className="justify-self-end flex justify-end items-center pb-2">
            <DownloadCSV
              fileName={"opt-out-list"}
              columns={columns}
              data={data}
              fieldsToInclude={["phoneNumber", "preference", "createdAt"]}
            />
          </div>
          <DataGrid
            rows={data}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            getRowId={(row) => row.phoneNumber}
            sx={{
              boxShadow: 2,
              border: 2,
              padding: 2,
              backgroundColor: "white",
              borderColor: "primary.light",
              "& .MuiDataGrid-cell:hover": {
                //   color: "primary.main",
              },
            }}
            componentsProps={{
              pagination: {
                labelDisplayedRows: ({ from, to, count }) =>
                  `${from}-${to} of ${
                    count !== -1 ? count : `more than ${to}`
                  }`,
              },
            }}
          />
        </div>
      )}
    </div>
  );
};

export default StartStop;
