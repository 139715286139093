import React, { useCallback, useEffect, useState } from "react";
import { Loader } from "../../../components";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import {
  getGroupCouponsAPI,
  updateGroupCouponsOrderAPI,
} from "../../../api/api";
import DiscountCard from "./DiscountCard";
import SoftDialog from "../../../components/molecules/dialogs";
import ViewDiscountCard from "./ViewDiscountCard";
import EditCoupon from "./EditCoupon";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";
import SortableCouponItem from "./SortableCouponItem";
import { toast } from "react-toastify";

const GroupCouponsPage = () => {
  const navigate = useNavigate();
  const { groupId } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [view, setView] = useState(false);
  const [editable, setEditable] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState([]);
  const [editCouponFormData, setEditCouponFormData] = useState({});
  const handleViewCoupon = () => {
    setView(true);
  };
  const getAPI = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getGroupCouponsAPI({ groupId });
      console.log(response);
      setData(response.data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  });
  const handleEditReset = () => {
    setEditable(false);
    setView(false);
    setEditCouponFormData({});
    // setSelectedCoupon([]);
    getAPI();
  };

  const handleDragEnd = async (event) => {
    const { active, over } = event;
    console.log(active, over);
    if (active.id !== over.id) {
      setData((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        const newOrder = arrayMove(items, oldIndex, newIndex);
        updateCouponOrder(newOrder, items, active?.id, oldIndex, newIndex); // Pass current items as fallback
        return newOrder;
      });
    }
  };

  const updateCouponOrder = async (
    newOrder,
    fallbackOrder,
    couponId,
    oldIndex,
    newIndex
  ) => {
    // console.log(newOrder, fallbackOrder, item, newIndex, oldIndex);
    console.log(groupId, newIndex, couponId);
    try {
      const response = await updateGroupCouponsOrderAPI({
        groupId,
        rank: newIndex,
        couponId,
      });
      console.log(response);
      toast.success(response?.message);
    } catch (error) {
      console.error("Error updating coupon order:", error);
      setData(fallbackOrder); // Revert to fallback order on failure
    }
  };
  useEffect(() => {
    getAPI();
  }, []);
  useEffect(() => {
    setEditCouponFormData(selectedCoupon);
    console.log("selectedCoupon", selectedCoupon);
  }, [selectedCoupon]);
  return (
    <div
      className={
        "flex flex-col bg-white h-full rounded-[10px] px-5 py-2.5 m-2.5"
      }
      //   style={{ boxShadow: "10px 10px lightgrey" }}
    >
      {loading ? (
        <div className={`flex flex-row justify-center align-center`}>
          <Loader />
        </div>
      ) : (
        <>
          <div className={`flex flex-row justify-start gap-1 align-center`}>
            <button
              onClick={() => navigate("/deals", { state: { active: 1 } })}
              className="bg-[#F2F8FA] p-2 rounded-full hover:opacity-90"
            >
              <ArrowBack className="" />
            </button>
            <div
              className={
                "font-bold text-[26px] text-[#344767] capitalize w-fit"
              }
            >
              Group Coupons
            </div>
          </div>
          {data?.length === 0 ? (
            <div className="flex flex-col flex-1 justify-center items-center">
              No Coupons Available!
            </div>
          ) : (
            <DndContext
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={data.map((item) => item.id)}
                strategy={verticalListSortingStrategy}
              >
                <div className="grid grid-cols-4 gap-2 my-4 flex-wrap">
                  {/* <DndContext></DndContext> */}
                  {data?.map((item, idx) => {
                    return (
                      <SortableCouponItem key={item.id} id={item.id}>
                        <DiscountCard
                          handleViewCoupon={handleViewCoupon}
                          setSelectedCoupon={setSelectedCoupon}
                          setView={setView}
                          key={idx}
                          cccData={item}
                          {...item}
                        />
                      </SortableCouponItem>
                    );
                  })}
                </div>
              </SortableContext>
            </DndContext>
          )}
          <SoftDialog
            handleClose={(_) => setView(false)}
            title={"coupon details"}
            open={view}
          >
            <ViewDiscountCard
              data={selectedCoupon}
              //   user={user}
              setView={setView}
              //   update={setUpdate}
              getAPI={getAPI}
              groupId={groupId}
              setEditable={setEditable}
              setSelectedCoupon={setSelectedCoupon}
            />
          </SoftDialog>
          <SoftDialog
            handleClose={(_) => setEditable(false)}
            title={"Edit Coupon"}
            open={editable}
          >
            <EditCoupon
              formData={editCouponFormData}
              setFormData={setEditCouponFormData}
              reset={() => handleEditReset()}
              data={selectedCoupon}
              //   user={user}
              setView={setView}
              //   update={setUpdate}
              getAPI={getAPI}
              groupId={groupId}
              show={setEditable}
            />
          </SoftDialog>
        </>
      )}
    </div>
  );
};
export default GroupCouponsPage;
