import { AccessibleForwardSharp, Add, Delete, Edit } from "@mui/icons-material";
import { getPickersFadeTransitionGroupUtilityClass } from "@mui/x-date-pickers/DateCalendar/pickersFadeTransitionGroupClasses";
import React, { useEffect, useState } from "react";
import dummyNewsLogo from "../../assets/imgs/dummy_news_logo.png";
import SoftCustomModel from "../../shared/SoftCustomModel/SoftCustomModel";
import { Loader } from "../../components";
import {
  addNewMediaAPI,
  deleteSingleMediaAPI,
  editNewMediaAPI,
  getMediaListAPI,
} from "../../api/api";
import { toast } from "react-toastify";
import { ImageUpload } from "../adsmaster/discountCoupons/imageUpload";

import AddEditMedia from "./AddEditMedia";
const Media = () => {
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [loading, setLoading] = useState([]);
  const [addLoading, setAddLoading] = useState(false);
  const [editData, setEditData] = useState(false);
  const [formValid, setFormValid] = useState(false);

  const [data, setData] = useState([
    // {
    //   headline: "The best tech resource for college student survival",
    //   logo: "logo",
    //   newsLink: "https://www.google.com",
    // },
  ]);
  const [formData, setFormData] = useState({
    headline: "",
    newsLink: "",
    banner: "",
  });
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const handleDeleteClick = (id) => {
    setItemToDelete(id);
    setDeleteModalOpen(true);
  };

  const resetFormData = () => {
    setFormData({
      headline: "",
      newsLink: "",
      banner: "",
    });
    setFormValid(false);
  };
  // console.log(formData, "ppppppp");

  const handleConfirmDelete = async () => {
    try {
      const response = await deleteSingleMediaAPI({ id: itemToDelete });
      // console.log(response);
      // if (response.status) {
      toast.success(
        response?.data?.message ||
          `Data with id ${itemToDelete} deleted successfully`
      );

      setData((prevData) =>
        prevData.filter((_, index) => _?.id !== itemToDelete)
      );
      setDeleteModalOpen(false);
      // }
    } catch (error) {
      console.log(error);
      toast.error(error?.error || error?.message || "Error deleting data");
    }
  };
  const handleModalClose = () => {
    setAddModalOpen(false);
    resetFormData();
    setEditData(false);
  };
  const handleEditClick = (rowData) => {
    // console.log(rowData);
    setFormValid(true);
    setFormData(rowData);
    setEditData(true);
    setAddModalOpen(true);
  };
  const handleAddClick = () => {
    setAddModalOpen(true);
  };

  const getAPI = async () => {
    setLoading(true);
    try {
      const response = await getMediaListAPI();
      // setData(response?.data);
      if (response?.status) {
        setData(response.data);
      }
      // console.log(response);
    } catch (error) {
      console.log(error);
      toast.error(error?.error || error?.message || "Error fetching data");
    } finally {
      setLoading(false);
    }
  };
  const handleBannerUpload = async (event) => {
    await ImageUpload(event, setFormData, "banner");
  };
  const handleChange = (e) => {
    const { name, value } = e?.target;

    if (name === "headline") {
      if (value.length <= 40) {
        setFormData((prev) => ({ ...prev, [name]: value }));
      } else {
        toast.error("Headline should not exceed 40 characters");
      }
    } else if (name === "newsLink") {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async () => {
    if (editData) {
      //edit api
      setAddLoading(true);
      try {
        const response = await editNewMediaAPI(formData);
        if (response?.status) {
          toast.success(response?.data?.message || "Data Updated successfully");
          setTimeout(() => {
            handleModalClose();
          }, [500]);
          getAPI();
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.error || error?.message || "Error adding data");
      } finally {
        setAddLoading(false);
      }
    } else {
      //add api
      setAddLoading(true);
      try {
        const response = await addNewMediaAPI(formData);
        if (response?.status) {
          toast.success(response?.data?.message || "Data added successfully");
          setTimeout(() => {
            handleModalClose();
          }, [500]);
          getAPI();
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.error || error?.message || "Error adding data");
      } finally {
        setAddLoading(false);
      }
    }
  };
  useEffect(() => {
    getAPI();
  }, []);
  useEffect(() => {
    if (formData?.headline && formData?.newsLink && formData?.banner) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [formData]);

  return (
    <div className="px-4 py-[12px] flex flex-col gap-6 h-full justify-start bg-[#F8F9FA]">
      <div className="flex items-center justify-between w-full">
        <h2 className="text-[#344767] font-roboto font-bold text-2xl leading-[26px]">
          Our Media
        </h2>
        <button
          onClick={handleAddClick}
          className="px-7 py-3 hover:shadow-sm active:scale-95 duration-300 rounded-lg bg-[#2cccd3] text-white text-sm font-bold font-montserrat flex justify-center items-center text-center"
        >
          <Add />
          &nbsp;Add a news
        </button>
      </div>
      <hr />
      <div className="flex flex-col flex-1 justify-center items-center">
        {loading ? (
          <Loader />
        ) : data?.length === 0 ? (
          <p>No Media</p>
        ) : (
          <MediaTable
            data={data}
            columns={[
              { name: "Headline" },
              { name: "Logo" },
              { name: "News link" },
              { name: "Action" },
            ]}
            onDelete={handleDeleteClick}
            onEdit={handleEditClick}
          />
        )}
      </div>
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={handleConfirmDelete}
      />
      <SoftCustomModel
        isVisible={isAddModalOpen}
        onClose={handleModalClose}
        title={editData ? "Edit News" : "Add News"}
      >
        <AddEditMedia
          formData={formData}
          handleChange={handleChange}
          addLoading={addLoading}
          handleSubmit={handleSubmit}
          handleBannerUpload={handleBannerUpload}
          buttonText={editData ? "Update Changes" : "Upload news"}
          validForm={formValid}
        />
      </SoftCustomModel>
    </div>
  );
};

const MediaTable = ({ data, columns, onDelete, onEdit }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleModalClose = () => {
    setSelectedImage(null);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  return (
    <table className="w-full h-full rounded-lg">
      <thead>
        <tr className="grid grid-cols-4">
          {columns?.map((col, idx) => (
            <th
              key={idx}
              className="text-sm font-montserrat font-bold text-[#2C3030] text-left px-4 py-2.5"
            >
              {col?.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="w-full h-full relative rounded-lg py-4 flex justify-start flex-col gap-4 overflow-y-scroll">
        {data?.map((row, index) => {
          return (
            <tr className="grid grid-cols-4 items-center justify-center rounded-lg">
              <td
                key={row?.headline}
                title={row?.id}
                className="text-[#2C3030] px-4 py-2 text-sm font-montserrat font-bold text-ellipsis whitespace-nowrap overflow-x-hidden overscroll-x-none"
              >
                {row?.headline}
              </td>
              <td key={index} className="px-4 py-2">
                {row?.banner ? (
                  <img
                    src={`${process.env.REACT_APP_IMAGE_BASEURL}${row?.banner}`}
                    className="w-[166px] h-[26px] bg-white border-white border-2"
                    alt="logo"
                    onClick={() => handleImageClick(row?.banner)}
                  />
                ) : (
                  <img
                    src={dummyNewsLogo}
                    className="w-[166px] h-[26px]"
                    alt="logo"
                  />
                )}
              </td>
              <td
                onClick={() => {
                  const url =
                    row?.newsLink?.startsWith("http://") ||
                    row?.newsLink?.startsWith("https://")
                      ? row?.newsLink
                      : `https://${row?.newsLink}`;
                  window.open(url, "_blank");
                }}
                key={row?.newsLink}
                className="px-4 py-2 cursor-pointer hover:scale-105 duration-300 transition-all font-montserrat text-sm font-bold text-[#2CCCD3] underline text-ellipsis whitespace-nowrap overflow-x-hidden overscroll-x-none"
              >
                {row?.newsLink}
              </td>
              <td key={row?.id}>
                <div className="flex flex-row gap-2.5 items-center justify-start">
                  <EditButton onClick={() => onEdit(row)} />
                  <DeleteButton onClick={() => onDelete(row?.id)} />
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
      {/* Modal for Selected Image */}
      {selectedImage && (
        <div
          onClick={(e) => {
            handleModalClose();
          }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="relative bg-white rounded-lg"
          >
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleModalClose();
              }}
              className="absolute top-2 right-2 text-gray-500 bg-white bg-opacity-80 rounded-full py-1.5 px-3 hover:text-gray-800"
            >
              ✕
            </button>
            <img
              src={`${process.env.REACT_APP_IMAGE_BASEURL}${selectedImage}`}
              alt="Full Banner Preview"
              className="max-w-full max-h-[40vh] min-h-[10vh] rounded-md"
            />
          </div>
        </div>
      )}
    </table>
  );
};

const EditButton = ({ onClick = () => {} }) => {
  return (
    <button
      className="text-white flex justify-center items-center text-sm gap-1 bg-[#2cccd3] px-5 py-3 rounded-lg"
      onClick={onClick}
    >
      <Edit fontSize="small" /> <p>Edit</p>
    </button>
  );
};
const DeleteButton = ({ onClick = () => {} }) => {
  return (
    <button
      className="text-white flex justify-center text-sm items-center gap-1 bg-[#F6285F] px-5 py-3 rounded-lg"
      onClick={onClick}
    >
      <Delete fontSize="small" /> <p>Delete</p>
    </button>
  );
};

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg p-6 w-[400px] text-center">
        <h2 className="text-xl font-bold mb-4">Confirm Delete</h2>
        <p className="text-gray-700 mb-6">
          Are you sure you want to delete this item?
        </p>
        <div className="flex justify-center gap-4">
          <button
            className="px-4 py-2 bg-gray-300 rounded-lg"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-red-500 text-white rounded-lg"
            onClick={onConfirm}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default Media;
