import { CircularProgress } from "@mui/material";
import { UploadIcon } from "../../assets/svgs/svgs";

const AddEditMedia = ({
  formData,
  handleChange,
  addLoading,
  handleSubmit,
  handleBannerUpload,
  buttonText,
  validForm = false,
}) => {
  return (
    <div className="flex flex-col gap-6">
      {/* Headline Input */}
      <div className="flex justify-start items-start gap-4 flex-col">
        <label
          htmlFor="headline"
          className="block text-sm font-bold font-montserrat text-gray-600"
        >
          Headline
        </label>
        <input
          type="text"
          id="headline"
          name="headline"
          placeholder="Enter news headline"
          className="w-full py-2 px-3.5 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#2cccd3]"
          value={formData?.headline}
          onChange={handleChange}
        />
      </div>

      {/* Website URL Input */}
      <div className="flex justify-start items-start gap-4 flex-col">
        <label
          htmlFor="newsLink"
          className="block text-sm font-bold font-montserrat text-gray-600 mb-2"
        >
          Website
        </label>
        <input
          type="url"
          id="newsLink"
          name="newsLink"
          value={formData?.newsLink}
          onChange={handleChange}
          placeholder="eg:- https://www.estulife.com or www.estulife.com"
          className="w-full py-2 px-3.5 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#2cccd3]"
          // pattern="https?://.*"
          title="Please enter a valid URL starting with http:// or https://"
        />
      </div>

      {/* Logo Upload */}
      <div className="flex justify-start items-start gap-4 flex-col w-full">
        <label
          htmlFor="logo"
          className="block text-sm font-bold font-montserrat text-gray-600 mb-2"
        >
          Logo
        </label>
        <div className="relative w-full">
          <input
            type="file"
            id="banner"
            className="absolute inset-0 opacity-0 cursor-pointer"
            accept="image/*"
            onChange={handleBannerUpload}
          />

          {formData?.banner ? (
            <img
              src={`${process.env.REACT_APP_IMAGE_BASEURL}${formData.banner}`}
              alt="Banner Preview"
              className="w-full min-h-[80px] border-white border-2 min-w-[80px] h-auto rounded-md"
              style={{ maxHeight: "200px" }} // Restricting max height for the image
            />
          ) : (
            <div className="flex items-center w-full justify-start py-2 px-3.5 border border-dashed border-gray-300 rounded-lg bg-white cursor-pointer hover:border-[#2cccd3]">
              <span className="text-[#2cccd3] mr-2">
                <UploadIcon />
              </span>
              <span className="text-sm font-bold font-roboto text-[#2cccd3] w-full">
                Upload Image
              </span>
            </div>
          )}
        </div>
      </div>
      {/* Submit Button */}
      <button
        onClick={handleSubmit}
        disabled={addLoading || !validForm}
        className="py-3.5 text-center w-full disabled:bg-[#bec9ca] disabled:cursor-not-allowed bg-[#2cccd3] text-white rounded-lg font-roboto font-bold font-montserrat text-sm"
      >
        {addLoading ? (
          <span className="flex items-center justify-center gap-2 flex-row">
            <CircularProgress size={"20px"} style={{ color: "#ffffff" }} />{" "}
            Loading...
          </span>
        ) : buttonText ? (
          buttonText
        ) : (
          "Upload News"
        )}
      </button>
    </div>
  );
};

export default AddEditMedia;
