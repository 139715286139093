import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import { Button, Loader } from "../../components";
import { CouponsIcon, OfferIcon } from "../../assets/svgs/svgs";
import { getGroups, getTopBrands, groupRankChangeApi } from "../../api/api";
import {
  closestCorners,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
  MeasuringStrategy,
  MouseSensor,
} from "@dnd-kit/core";
import GroupsContainer from "./Groups/GroupsContainer";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import SoftDialog from "../../components/molecules/dialogs";
import FormComponent from "../adsmaster/discountCoupons/CreateDiscount";
import { SuccessFullStepGroup } from "./SuccessIndicators";
import FormComponentDeals from "./FormComponentDeals";
import TopBrands from "./TopBrands/TopBrands";
import { useLocation } from "react-router-dom";

const Deals = () => {
  const [showListTopBrands, setShowListTopBrands] = useState(false);
  const [showGroupForm, setShowGroupForm] = useState(false);
  const [active, setActive] = useState(0);
  const [loading, setLoading] = useState(true);
  const [Data, setData] = useState([]);
  const [brands, setBrands] = useState([]);
  const [groupStep, setGroupStep] = useState(0);
  const [formDataGroup, setFormDataGroup] = useState({
    name: "",
    description: "",
  });
  const location = useLocation();
  // console.log("bharat", location);
  const { state } = location;
  // // if (state?.active && active === 0) {
  // //   console.log("bharat", state.active);
  // //   setActive(state.active);
  // }

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 10 } }),
    useSensor(TouchSensor),
    useSensor(
      KeyboardSensor,

      { cordinateGetter: sortableKeyboardCoordinates }
    )
  );

  const measuringConfig = {
    droppable: {
      strategy: MeasuringStrategy.Always,
    },
  };

  const getGroupPos = (id) => Data.findIndex((group) => group.id === id);
  const handleGroupDragEnd = async (event) => {
    const { active, over } = event;
    if (active.id === over.id) return;
    const originalPos = getGroupPos(active.id);
    const newPos = getGroupPos(over.id);
    const response = await groupRankChangeApi({
      rank: newPos + 1,
      id: active.id,
    });
    if (response.status === "success") {
      setData((data) => {
        return arrayMove(Data, originalPos, newPos);
      });
    }
    // toast
  };
  const getAPI = async () => {
    setLoading(true);
    if (active == 0) {
      try {
        const res = await getTopBrands();
        if (res.status === "success") {
          setBrands(res.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const res = await getGroups();
        setData(res);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    setGroupStep(0);
    setFormDataGroup({
      name: "",
      description: "",
    });
  }, [showGroupForm]);

  useEffect(() => {
    getAPI();
  }, [active]);
  useEffect(() => {
    if (state?.active && active === 0) {
      console.log("bharat", state.active);
      setActive(state.active);
      state.active = null;
    }
  }, [location]);

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={`flex flex-row gap-3`} style={{ alignItems: "center" }}>
          <div
            onClick={(_) => setActive(0)}
            className={`${style.buttonTab} ${
              active === 0 ? style.active : ""
            }  cursor-pointer`}
          >
            Top Brands
          </div>
          <div
            onClick={(_) => setActive(1)}
            className={`${style.buttonTab} ${
              active === 1 ? style.active : ""
            } cursor-pointer`}
          >
            Groups
          </div>
        </div>
        <SoftDialog
          title={groupStep === 0 ? "Add A New Group" : ""}
          open={showGroupForm}
          handleClose={(_) => {
            setShowGroupForm(false);
            if (stepGroup === 1) {
              setGroupStep(0);
              setFormDataGroup({
                name: "",
                description: "",
              });
            }
          }}
        >
          {groupStep === 0 ? (
            <FormComponentDeals
              setStep={setGroupStep}
              formData={formDataGroup}
              setFormData={setFormDataGroup}
              getAPI={getAPI}
            />
          ) : (
            <SuccessFullStepGroup />
          )}
        </SoftDialog>
        <div>
          {active === 0 ? (
            // <Button
            //   onClick={(_) => setShowListTopBrands(true)}
            //   bgColor="#2CCCD3"
            //   color="#fff"
            //   borderRadius="10px"
            //   icon={<CouponsIcon />}
            //   text={"Add Brands"}
            // />
            ""
          ) : (
            <Button
              onClick={(_) => setShowGroupForm(true)}
              bgColor="#2CCCD3"
              color="#fff"
              borderRadius="10px"
              icon={<AddIcon />}
              text={"Add New Group"}
            />
          )}
        </div>
      </div>
      {loading ? (
        <div className={`flex flex-row justify-center align-center`}>
          <Loader />
        </div>
      ) : (
        <div className="h-full">
          {active == 0 ? (
            <TopBrands
              brands={brands ? brands : []}
              getTopBrands={getAPI}
              setTopBrands={setBrands}
            />
          ) : Data?.length > 0 ? (
            <DndContext
              sensors={sensors}
              onDragEnd={handleGroupDragEnd}
              collisionDetection={closestCorners}
              measuring={measuringConfig}
            >
              <GroupsContainer groups={Data ? Data : []} getAPI={getAPI} />
            </DndContext>
          ) : (
            <EmptyCardComponent active={active} />
          )}
        </div>
      )}
    </div>
  );
};
const EmptyCardComponent = ({ active }) => {
  return (
    <div className={"w-full flex justify-center items-center h-full"}>
      {active == 0 ? "No Brands" : "No Groups"}
    </div>
  );
};

export default Deals;
