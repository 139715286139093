import React, { useState } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { SearchIcon, CancelIcon } from "../../assets/svgs/svgs";

const SearchBox = ({
  onSearch,
  placeholder = "Search...",
  data,
  active = false,
  setActive = () => {},
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchActive, setIsSearchActive] = useState(active);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    if (onSearch) {
      onSearch(value);
    }
  };

  const handleClearSearch = () => {
    setSearchQuery("");
    if (onSearch) {
      onSearch("");
    }
    setIsSearchActive(false);
    setActive(false);
  };

  const handleBlur = () => {
    if (!searchQuery) {
      setIsSearchActive(false);
      setActive(false);
    }
  };

  return (
    <TextField
      variant="outlined"
      placeholder={placeholder}
      value={searchQuery}
      onChange={handleSearchChange}
      margin="normal"
      onFocus={() => {
        setIsSearchActive(true);
        setActive(true);
      }}
      onBlur={handleBlur}
      className={`transition-all duration-500 ease-in-out ${
        isSearchActive ? "w-[500px]" : "w-[50px]"
      }`}
      InputProps={{
        startAdornment: (
          <InputAdornment
            onClick={() => {
              setIsSearchActive(true);
              setActive(true);
            }}
            position="start"
            className="cursor-pointer"
          >
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: searchQuery && (
          <InputAdornment position="end">
            <IconButton
              sx={{
                backgroundColor: "#2cccd3",
                "&:hover": { backgroundColor: "#29b6bd" },
              }}
              onClick={handleClearSearch}
            >
              <CancelIcon />
            </IconButton>
          </InputAdornment>
        ),
        style: {
          backgroundColor: "#ffffff",
          borderRadius: "25px",
          borderColor: isSearchActive ? "#2cccd3" : "transparent",
        },
      }}
      sx={{
        "& .MuiOutlinedInput-root": {
          paddingLeft: isSearchActive ? "40px" : "12px",
          "& fieldset": {
            borderColor: isSearchActive ? "#2cccd3" : "transparent",
          },
          "&:hover fieldset": {
            borderColor: isSearchActive ? "#2cccd3" : "transparent",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#2cccd3",
          },
        },
      }}
    />
  );
};

export default SearchBox;
