import dayjs from "dayjs";
import { ExportIcon } from "../../assets/svgs/svgs";

const DownloadCSV = ({
  data,
  fileName = "data",
  columns,
  fieldsToInclude = [
    "email",
    "phoneNumber",
    "headline",
    "smsMessage",
    "subject",
    "emailBody",
    "image",
  ],
}) => {
  //   const convertToCSV = (objArray) => {
  //     const array =
  //       typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
  //     let str = "";

  //     for (let i = 0; i < array.length; i++) {
  //       let line = "";
  //       for (let index in array[i]) {
  //         if (line !== "") line += ",";

  //         line += array[i][index];
  //       }
  //       str += line + "\r\n";
  //     }
  //     return str;
  //   };

  // Fields to include in CSV
  // const fieldsToInclude = [
  //   "email",
  //   "phoneNumber",
  //   "headline",
  //   "smsMessage",
  //   "subject",
  //   "emailBody",
  //   "image",
  // ];

  //   const convertToCSV = (objArray) => {
  //     // Extract headers from columns that are marked for download
  //     const headers = columns
  //       .filter((col) => col.download)
  //       .map((col) => col.headerName);
  //     const headerString = headers.join(",");

  //     // Extract data from rows
  //     const rows = objArray.map((obj) =>
  //       columns
  //         .filter((col) => col.download)
  //         .map((col) => obj[col.field])
  //         .join(",")
  //     );
  //     return [headerString, ...rows].join("\r\n");
  //   };
  // Function to format date as YYYY-MM-DD
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Returns date in YYYY-MM-DD format
  };

  const convertToCSV = (objArray) => {
    // Extract headers based on the fields to include
    // const headers = columns
    //   .filter((col) => col.download) // Include only columns marked for download
    //   .map((col) => col.headerName || col.field); // Use headerName if available, otherwise use field name
    const headers = fieldsToInclude.map((field) => {
      const column = columns.find((col) => col.field === field);
      // return column ? column?.field || field : field; // Use headerName if available, otherwise use field name
      return column && column.csvHeaderName
        ? column.csvHeaderName
        : column?.headerName || field;
    });
    const headerString = headers.join(",");

    // Extract data from rows
    // Extract data from rows
    const rows = objArray.map((obj) =>
      columns
        .filter((col) => col.download) // Include only columns marked for download
        .map((col) => {
          if (col.field === "createdAt") {
            if (col.completeDate) {
              return dayjs(obj[col.field])
                .tz("America/New_York")
                .format("YYYY-MM-DD:HH:mm:ss"); // Full date-time format
            } else {
              return formatDate(obj[col.field]); // Format to YYYY-MM-DD
            }
          }
          return obj[col.field] || ""; // Default to empty string if the field is missing
        })
        .join(",")
    );
    return [headerString, ...rows].join("\r\n");
  };

  const downloadCSV = () => {
    const csvData = new Blob([convertToCSV(data)], { type: "text/csv" });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement("a");
    link.href = csvURL;
    link.download = `${fileName}`; //removed csv extension like fileName.csv
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <button
      onClick={downloadCSV}
      className="flex w-[163px] h-10 py-3 shadow-[0px_4px_7px_-1px_rgba(0_0_0_0.11)] hover:scale-105 duration-500 transition-all justify-center items-center gap-1 flex-shrink-0 rounded-lg bg-[#2CCCD3]"
    >
      <ExportIcon />
      <p className="text-white text-center font-montserrat text-sm font-semibold">
        Export
      </p>
    </button>
  );
};

export default DownloadCSV;
