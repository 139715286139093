import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import BrandCoupon from "./BrandCoupon";

const DraggableCouponContainer = ({ coupons }) => {
  const [loading, setLoading] = useState(false);
  return (
    <div className="bg-white h-full flex flex-col gap-4 py-[15px] px-[22px] rounded-lg">
      <div className="flex flex-col gap-4">
        <h4 className="text-[16px] font-semibold text-ellipsis">Coupon List</h4>
      </div>
      {loading ? (
        <div className="w-full  flex justify-center items-center h-full">
          <CircularProgress sx={{ color: "#2cccd3" }} />
        </div>
      ) : coupons?.length !== 0 ? (
        <div className="flex flex-col justify-between gap-2 max-h-[50vh]">
          <div className="grid grid-cols-4 gap-2 ">
            {coupons.map((coupon, idx) => {
              return <BrandCoupon key={idx} {...coupon} />;
            })}
          </div>

          {/* <Pagination
            currentPage={page}
            onPageChange={handlePageChange}
            totalPages={Math.ceil(totalSize / 16) || 1}
          /> */}
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center w-full h-full max-h-[50vh]">
          No Coupons
        </div>
      )}
    </div>
  );
};

export default DraggableCouponContainer;
