import { useState } from "react";
import { Line } from "../../../assets/svgs/svgs";
import QrImage from "../../../assets/imgs/qr.png";
import style from "./style.module.css";
import { UpdateEvent } from "../../../api/api";
import { MdDelete, MdEdit } from "react-icons/md";
import DeleteConfirmation from "../../../components/DeleteConfirmation/DeleteConfirmation";

const ViewEventDetails = ({
  data,
  setEdit,
  setFormDataDiscount,
  setView,
  update,
}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleDeleteConfirm = async () => {
    await UpdateEvent(data, "delete");
    setView((prev) => ({ ...prev, view: false }));
    update((prev) => !prev);
    setShowDeleteConfirmation(false);
  };

  const handleDeleteCancel = () => {
    setShowDeleteConfirmation(false);
  };

  return (
    <div
      className={`flex flex-row p-10 gap-6 align-start justify-between w-[720px]`}
    >
      {showDeleteConfirmation && (
        <DeleteConfirmation
          onDelete={handleDeleteConfirm}
          onCancel={handleDeleteCancel}
        />
      )}
      <div className={`flex flex-col gap-6 text-left w-[300px]`}>
        <div className={`flex flex-col gap-2 text-left`}>
          <p className={style.form__des}>Event</p>
          <p className={style.form_title}>{data?.headline}</p>
        </div>
        <div className={`flex flex-col gap-2 text-left`}>
          <p className={style.form__des}>Description</p>
          <p className={style.form_title}>{data?.description}</p>
        </div>
        <div className={`flex flex-col gap-2 text-left`}>
          <p className={style.form__des}>Start Date </p>
          <p className={style.form_title}>{data?.when}</p>
        </div>
        <div className={`flex flex-col gap-2 text-left`}>
          <p className={style.form__des}>End Date </p>
          <p className={style.form_title}>{data?.tillDate}</p>
        </div>
        <div className={`flex flex-col gap-2 text-left`}>
          <p className={style.form__des}>Time </p>
          <p className={style.form_title}>
            {data?.time} - {data?.till}
          </p>
        </div>
        <div className={`flex flex-col gap-2 text-left flex-wrap`}>
          <p className={style.form__des}>Location </p>
          {console.log(data)}
          {/* <p className={`${style.form_title} ${style.form_overflow_title}`}>
            {data?.where ? JSON.parse(data?.where)?.description : null}
          </p> */}
          <p className={`${style.form_title} ${style.form_overflow_title}`}>
            {data?.location
              ? `${data?.location?.address1 ? data?.location?.address1 : ""} ${
                  data?.location?.address2 ? data?.location?.address2 : ""
                } ${data?.location?.city ? data?.location?.city : ""} ${
                  data?.location?.state ? data?.location?.state : ""
                } ${data?.location?.country ? data?.location?.country : ""} ${
                  data?.location?.zipCode ? data?.location?.zipCode : ""
                }`
              : ""}
          </p>
        </div>
        <div className="flex items-start gap-2 flex-row w-100">
          <button
            onClick={(_) => {
              setEdit(true);
              setFormDataDiscount(data);
            }}
            className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex flex-row gap-2 items-center`}
          >
            <MdEdit /> Edit
          </button>
          <button
            onClick={(_) => {
              setShowDeleteConfirmation(true);
            }}
            className={`bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded flex flex-row gap-2 items-center`}
          >
            <MdDelete /> Delete
          </button>
        </div>
      </div>
      <div className={`flex flex-col gap-6 w-[300px]`}>
        <div className={`flex flex-col gap-2`}>
          <p className={style.form_title}>Ticket preview</p>
          <div className={`flex flex-col bg-white p-3 gap-3`}>
            <img
              style={{ width: 253, height: 147 }}
              src={`${process.env.REACT_APP_IMAGE_BASEURL}${data.poster}`}
              alt="ddd"
            />
            <div className="overflow-hidden text-center overflow-ellipsis font-montserrat text-neutral-90 font-semibold text-16 leading-normal tracking-tight">
              {data.headline}
            </div>
            <div className="text-center text-montserrat text-2c3030 text-14 font-normal tracking-tight leading-6 flex-wrap">
              Venue:{" "}
              <span className="line-clamp-3">
                {/* {data?.where ? JSON.parse(data?.where)?.description : null} */}
                {data?.location
                  ? `${
                      data?.location?.address1 ? data?.location?.address1 : ""
                    } ${
                      data?.location?.address2 ? data?.location?.address2 : ""
                    } ${data?.location?.city ? data?.location?.city : ""} ${
                      data?.location?.state ? data?.location?.state : ""
                    } ${
                      data?.location?.country ? data?.location?.country : ""
                    } ${data?.location?.zipCode ? data?.location?.zipCode : ""}`
                  : ""}
              </span>
            </div>
            <div className="text-center text-montserrat text-2c3030 text-14 font-normal tracking-tight leading-6">
              Start Date: {data?.when}
            </div>{" "}
            <div className="text-center text-montserrat text-2c3030 text-14 font-normal tracking-tight leading-6">
              Ent Date: {data?.tillDate}
            </div>
            <div className="text-center text-montserrat text-2c3030 text-14 font-normal tracking-tight leading-6">
              Time: {data?.time} - {data?.till}
            </div>
            <div
              className={`flex flex-col align-center text-center w-[100%]`}
              style={{ alignItems: "center" }}
            >
              <div className={style.cardStatus}>
                <div
                  className={`${style.cardCircle} ${
                    data.status === "active" ? style.activeCircle : ""
                  }`}
                ></div>
                <div className={style.cardStatusText}>{data.status}</div>
              </div>
            </div>
            <div className={`mt-2`}>
              <Line />
            </div>
            <div
              className={`flex flex-col align-center text-center w-[100%] mt-2`}
              style={{ alignItems: "center" }}
            >
              <img src={QrImage} alt="Qr" style={{ width: 60, height: 60 }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewEventDetails;
