import React from "react";
import { useState, useEffect } from "react";
import {
  closestCorners,
  DndContext,
  KeyboardSensor,
  TouchSensor,
  useSensor,
  useSensors,
  MeasuringStrategy,
  DragOverlay,
  MouseSensor,
} from "@dnd-kit/core";
import {
  addTopBrandApi,
  getAdmasterUsersAsBrands,
  updateTopBrandRankApi,
  deleteTopBrandApi,
} from "../../../api/api";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { Button } from "../../../components";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import DraggableItem from "./Brand";
import {
  SortableContext,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";

const TopBrands = ({ brands, getTopBrands, setTopBrands }) => {
  const [users, setUsers] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [activeId, setActiveId] = useState(null);

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 25 } }), //changed distance to 25 when requirement changed to 25 from 10
    useSensor(TouchSensor),
    useSensor(
      KeyboardSensor,

      { cordinateGetter: sortableKeyboardCoordinates }
    )
  );

  const measuringConfig = {
    droppable: {
      strategy: MeasuringStrategy.Always,
    },
  };

  const fetchAdsManagerUser = async () => {
    try {
      const res = await getAdmasterUsersAsBrands();
      if (res.status === "success") {
        setUsers(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getGroupPos = (id) => {
    let brandsIndex = brands.findIndex((brand) => {
      return brand.user_id === parseInt(id);
    });
    return {
      index: brandsIndex,
      brand: brands[brandsIndex],
    };
  };

  function handleDragStart(event) {
    const { active } = event;
    const activeData = brands.filter((brand) => {
      return brand.user_id === parseInt(active.id);
    });
    setActiveId(activeData.length > 0 ? activeData[0] : {});
  }

  const handleGroupDragEnd = async (event) => {
    const { active, over } = event;
    if (active.id === over.id) {
      setActiveId(null);
      return;
    }
    const originalPos = getGroupPos(active.id);
    const newPos = getGroupPos(over.id);
    const response = await updateTopBrandRankApi({
      rank: newPos.brand.rank,
      id: originalPos.brand.id,
    });
    if (response.status === 200) {
      setTopBrands((data) => {
        return arrayMove(brands, originalPos.index, newPos.index);
      });
      toast.success(
        response.data?.message || "Top brand rank updated successfully"
      );
    }
  };

  useEffect(() => {
    // Fetch initial items (adjust the endpoint)
    fetchAdsManagerUser();
  }, []);

  const addSelectedBrands = async () => {
    try {
      console.log(selectedBrands);
      const res = await addTopBrandApi({
        userId: selectedBrands[0].id,
        rank: selectedBrands[0].rank,
      });
      if (res?.status === "success") {
        toast.success("Marked as Top Brand successfully");
        getTopBrands();
      }
      console.log(res, "");
    } catch (err) {
      console.log("", err);
      //   toast.error("bharat");
    }
  };

  const handleDeleteTopBrand = async (rankId) => {
    try {
      const res = await deleteTopBrandApi(rankId);
      if (res.status === 200) {
        toast.success("Top Brand deleted successfully");
        getTopBrands();
        setActiveId(null);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="mt-7 flex flex-col gap-4 h-[90[vh]">
      <div className="left-aligned-grid hide-grid-header">
        <div className="flex flex-col gap-3 mb-4">
          <p className="text-md text-[#344767] font-semibold">Brands</p>
          <div className="flex gap-4 items-center mb-5">
            <Stack spacing={3} sx={{ width: "100%" }}>
              <Autocomplete
                // multiple
                onChange={(event, newValue) => {
                  // setSelectedBrands(
                  // 	newValue.map((el, i) => {
                  // 		return {
                  // 			...el,
                  // 			rank: i + brands.length,
                  // 		};
                  // 	})
                  // );
                  setSelectedBrands([
                    {
                      ...newValue,
                      rank: brands.length + 1,
                    },
                  ]);
                }}
                id="tags-standard"
                options={users || []}
                getOptionLabel={(option) => option?.instituteName}
                // getOptionDisabled={(options) =>
                // 	selectedBrands.length + brands.length < 10 ? false : true
                // }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Brands"
                    placeholder="Brands"
                  />
                )}
              />
            </Stack>
            {selectedBrands.length > 0 && (
              <div className="shrink-0">
                <Button
                  onClick={(_) => addSelectedBrands()}
                  bgColor="#2CCCD3"
                  color="#fff"
                  borderRadius="10px"
                  text={"+ Add selected Brands"}
                />
              </div>
            )}
          </div>
          <p className="text-[14px] text-[#344767] font-semibold">
            Top Brands - Drag and drop to reorder the list
          </p>

          <DndContext
            sensors={sensors}
            onDragStart={handleDragStart}
            onDragEnd={handleGroupDragEnd}
            collisionDetection={closestCorners}
            measuring={measuringConfig}
          >
            <DragOverlay>
              {activeId ? (
                <div className="w-[80px] border-1 border-[#92A9AD] rounded-lg h-28 flex flex-col items-center justify-center bg-white opacity-[.8]">
                  <div className="flex gap-2 justify-between items-center bg-[#2CCCD3] text-white py-1 px-2 text-center w-full">
                    <span>{activeId.rank}</span>
                  </div>
                  <div className="h-full w-full flex items-center justify-center">
                    <p className="p-2 text-ellipsis whitespace-nowrap overflow-hidden text-sm font-medium">
                      {activeId.brand_name}
                    </p>
                  </div>
                </div>
              ) : null}
            </DragOverlay>
            <div className="flex gap-3 flex-wrap">
              <SortableContext
                items={brands}
                strategy={horizontalListSortingStrategy}
                handle
              >
                {brands.map((item, idx) => (
                  <DraggableItem
                    key={idx}
                    item={item}
                    handleDeleteTopBrand={handleDeleteTopBrand}
                    index={idx}
                  />
                ))}
              </SortableContext>
            </div>
          </DndContext>
        </div>
      </div>
    </div>
  );
};

export default TopBrands;
