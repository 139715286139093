import React, { useEffect, useState } from "react";
import { addGroupApi } from "../../api/api";
import { GroupFormConfig } from "../adsmaster/discountCoupons/formConfig";
import GenericFormComponent from "../../components/Form/Form";
import GenericFormField from "../../components/Form/FormFields";
import { toast } from "react-toastify";
import moment from "moment";

const FormComponentDeals = ({ getAPI, formData, setFormData, setStep }) => {
  const [error, setError] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    // Check form validity on form data change
    const checkFormValidity = () => {
      const emptyFields = GroupFormConfig.filter((field) => {
        console.log(field.name);
        // Exclude 'end_date' from validation check
        if (field.name === "end_date") {
          return false;
        }
        return !formData[field.name];
      });
      setIsFormValid(emptyFields.length === 0);
    };

    checkFormValidity();
  }, [formData]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (!isFormValid) {
      setError("Please fill all required fields.");
      toast.error(error);
      return;
    }
    if (!formData.end_date) {
      formData.end_date = "2099-12-31";
    }

    if (!isEndDateValid(formData.start_date, formData.end_date)) {
      return toast.info("End date can not be before start date");
    }
    if (!isDateValidFromToday(formData.end_date)) {
      return toast.info("End date can not be acceptable before today!");
    }

    try {
      const response = await addGroupApi({ ...formData });

      setStep(1);
      setError("");
      getAPI();
    } catch (error) {
      console.error(error);
      setError("An error occurred while submitting the form.");
    }
  };
  const isEndDateValid = (startDate, endDate) => {
    if (!startDate || !endDate) {
      return false; // Handle cases where either date is missing
    }

    const formattedStartDate = moment(startDate, "YYYY-MM-DD");
    const formattedEndDate = moment(endDate, "YYYY-MM-DD");

    // Check if endDate is after or equal to startDate (inclusive)
    return formattedEndDate.isSameOrAfter(formattedStartDate);
  };
  const isDateValidFromToday = (endDate) => {
    const formattedEndDate = moment(endDate, "YYYY-MM-DD");
    const today = moment();

    // Check if endDate is not before today
    return !formattedEndDate.isBefore(today);
  };
  return (
    <FormUI
      formConfig={GroupFormConfig}
      formData={formData}
      setFormData={setFormData}
      handleSubmit={handleSubmit}
      error={error}
      isFormValid={isFormValid}
    />
  );
};
const FormUI = ({
  formConfig,
  formData,
  setFormData,
  cols = 2,
  handleSubmit,
  error,
  isFormValid,
}) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  return (
    <div
      className={`flex flex-col gap-2 align-start mt-[15px] min-w-[50vw] overflow-hidden p-4`}
      style={{
        width: formConfig.length && cols === 2 > 4 ? 700 : "fit-content",
      }}
    >
      <div className={`flex flex-col align-start gap-2`}>
        {formConfig.map((field) => (
          <GenericFormField
            key={field.name}
            field={field}
            formData={formData}
            setFormData={setFormData}
            handleInputChange={handleInputChange}
          />
        ))}
      </div>
      {error && (
        <div
          style={{
            color: "red",
            marginBottom: "10px",
          }}
        >
          {error}
        </div>
      )}
      <div
        onClick={handleSubmit}
        style={{
          backgroundColor: isFormValid ? "#2CCCD3" : "#CCCCCC",
          color: "white",
          borderRadius: "10px",
          display: "flex",
          width: "100%",
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center",
          cursor: isFormValid ? "pointer" : "not-allowed",
        }}
        className={`gap-[10px] align-items-center text-center align-center p-3 hover:drop-shadow-xl`}
        disabled={!isFormValid}
      >
        Continue
      </div>
    </div>
  );
};

export default FormComponentDeals;
