import { ResetTvSharp } from "@mui/icons-material";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { uploadCSVAPI } from "../../../api/api";

const CsvUpload = ({ onUploadSuccess }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [csvData, setCsvData] = useState(null); // State to store CSV data
  const [open, setOpen] = useState(false); // State to control modal visibility
  const [loading, setLoading] = useState(false); // State to manage loading state
  const [inputKey, setInputKey] = useState(Date.now());

  const handleFileChange = (event) => {
    // console.log("bharat");
    setSelectedFile(event.target.files[0]);
  };

  const columns = [
    { field: "email", headerName: "Email", flex: 1 },
    { field: "phoneNumber", headerName: "Phone Number", flex: 1 },
    { field: "subject", headerName: "Subject", flex: 1 },
    { field: "headline", headerName: "Headline", flex: 1 },
    { field: "emailBody", headerName: "EmailBody", flex: 1 },
    { field: "smsMessage", headerName: "SmsMessage", flex: 1 },
    { field: "image", headerName: "Image", flex: 1 },

    // { field: "name", headerName: "Name", flex: 1 },
    // { field: "email", headerName: "Email", flex: 1 },
    // { field: "phoneNumber", headerName: "Phone Number", flex: 1 },
    // { field: "subject", headerName: "Subject", flex: 1 },
    // { field: "emailBody", headerName: "Email Body", flex: 1 },
    // { field: "smsMessage", headerName: "SMS Message", flex: 1 },
  ];

  const handleUpload = async () => {
    if (!selectedFile) {
      toast.error("Please select a file first");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    setLoading(true);
    try {
      //calling this type reason cors issue
      const response = await uploadCSVAPI(formData);
      if (response.status === 200) {
        toast.success(
          response?.data?.message ? response?.data?.message : "upload success"
        );
        onUploadSuccess();
      } else {
        toast.error("Something went wrong uploading");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("File upload failed");
    } finally {
      setSelectedFile(null);
      setOpen(false);
      setCsvData(null);
      setInputKey(Date.now());
      setLoading(false);
    }
  };

  //csv view before upload
  const handlePreviewClick = () => {
    handleOpenModal();
  };
  const handleOpenModal = async () => {
    if (!selectedFile) {
      toast.error("Please select a file first");
      return;
    }
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      const rows = text
        .split("\n")
        .map((row) =>
          row.split(",").map((cell) => cell.trim().replace("\r", ""))
        );

      const columns = [
        { field: "email", headerName: "Email", flex: 1 },
        { field: "phoneNumber", headerName: "Phone Number", flex: 1 },
        { field: "subject", headerName: "Subject", flex: 1 },
        { field: "headline", headerName: "Headline", flex: 1 },
        { field: "emailBody", headerName: "EmailBody", flex: 1 },
        { field: "smsMessage", headerName: "SmsMessage", flex: 1 },
        { field: "image", headerName: "Image", flex: 1 },
        // email, phoneNumber, subject, headline, emailBody, smsMessage, image
        // { field: "name", headerName: "Name", flex: 1 },
        // { field: "email", headerName: "Email", flex: 1 },
        // { field: "phoneNumber", headerName: "Phone Number", flex: 1 },
        // { field: "subject", headerName: "Subject", flex: 1 },
        // { field: "emailBody", headerName: "Email Body", flex: 1 },
        // { field: "smsMessage", headerName: "SMS Message", flex: 1 },
      ];

      const data = rows
        .slice(1) // Skip the header row
        .filter(
          (row) => row.length > 1 && row.some((cell) => cell.trim() !== "")
        ) // Filter out empty rows and rows with only one cell
        .map((row, index) => {
          const rowData = {};
          console.log(columns);
          columns.forEach((column, idx) => {
            rowData[column.field] = row[idx];
          });
          return { id: index, ...rowData };
        });

      setCsvData(data); // Set CSV data to state
      setOpen(true); // Open the modal
    };

    reader.readAsText(selectedFile);
  };
  const handleCloseModal = () => {
    setOpen(false); // Close the modal
    setCsvData(null); // Clear CSV data from state
    setInputKey(Date.now());
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-xl font-bold mb-4">Upload CSV File</h2>
      <div className="flex items-center space-x-4">
        <label
          htmlFor="file-upload"
          className="w-1/2 py-2 px-4 bg-gray-200 text-gray-700 rounded-lg cursor-pointer hover:bg-gray-300"
        >
          <span className="text-sm">
            {selectedFile ? selectedFile.name : "Choose a CSV File"}
          </span>
          <input
            id="file-upload"
            type="file"
            accept=".csv"
            className="hidden"
            onChange={handleFileChange}
            key={inputKey} // Use key to reset the file input
          />
        </label>
        <button
          className={`py-2 px-4 bg-[#2cccd3] hover:bg-[#32b6bc] active:bg-[#27878c] text-white rounded-lg font-semibold ${
            selectedFile ? "" : "opacity-50 cursor-not-allowed"
          }`}
          onClick={handlePreviewClick}
          disabled={!selectedFile}
        >
          {!selectedFile ? "Upload" : "Preview"}
        </button>
      </div>
      {/* Preview Modal */}
      <Dialog open={open} onClose={handleCloseModal} maxWidth="lg" fullWidth>
        <DialogTitle>Preview of COMMS File</DialogTitle>
        <DialogContent dividers>
          {csvData ? (
            <div style={{ height: 400, width: "100%" }}>
              <DataGrid rows={csvData} columns={columns} pageSize={10} />
            </div>
          ) : (
            <p>No CSV data available.</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModal}
            color="primary"
            sx={{ color: "#2cccd3", padding: "8px 16px", borderRadius: "8px" }}
          >
            Close
          </Button>
          <button
            className={`py-2 px-4 bg-[#2cccd3] hover:bg-[#32b6bc] active:bg-[#27878c] text-white rounded-lg font-semibold ${
              selectedFile ? "" : "opacity-50 cursor-not-allowed"
            }`}
            onClick={handleUpload}
            disabled={!selectedFile || loading}
          >
            {loading ? "processing..." : "Upload"}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CsvUpload;
