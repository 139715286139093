import React, { useEffect, useState, useRef } from "react";
import style from "./style.module.css";
import { FormConfig } from "./formConfig";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Loader from "./../../../components/atoms/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../../components";
import SoftDialog from "../../../components/molecules/dialogs";
import {
  CheckIcon,
  EditGroupIcon,
  PhotoIcon,
  UserIcon,
} from "../../../assets/svgs/svgs";
import PreviewIcon from "@mui/icons-material/Preview";
import GenericFormComponent from "../../../components/Form/Form";
import { ImageUpload } from "../discountCoupons/imageUpload";
import {
  CreateSector,
  DeleteAdmasterUser,
  DeleteSector,
  GetAdsManagerUsers,
  SaveAdsManagerUsers,
  UpdateSector,
  UpdateUserStatusAdmaster,
  getSectors,
} from "../../../api/api";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditRowComponent from "./editUser";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import AcceptRejectModel from "../../admasterUsers/Accept_RejectModel";
import CustomButton from "../../../components/atoms/Button/Button";
import UsersIcon from "../../../assets/svgs/user_admaster.svg";
import classNames from "classnames";
import { toast } from "react-toastify";
import dayjs from "dayjs";

const EstuAds = () => {
  const [Data, setData] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [sectorSelectionModel, setSectorSelectionModel] = useState([]);
  const [showEditRowModal, setShowEditRowModal] = useState(false);
  const [showEditSector, setShowEditSector] = useState(false);
  const [updateRows, setUpdateRows] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedSectorId, setSelectedSectorId] = useState(null);
  const [showApproveModel, setShowApproveModel] = useState(false);
  const [showRejectionModel, setShowRejectionModel] = useState(false);
  const [selectedDataValue, setSelectedDataValue] = useState({});
  const [updateState, setUpdateState] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");
  const [formData, setFormData] = useState({
    instituteName: "",
    phoneNumber: "",
    email: "",
    password: "",
    website: "",
    industry: "",
    profilePhoto: "",
    amazonAffiliate: false,
  });
  const [updateFormData, setUpdateFormData] = useState({
    instituteName: "",
    phoneNumber: "",
    website: "",
    industry: "",
    profilePhoto: "",
    amazonAffiliate: "",
    email: "",
  });
  const [selectedRow, setSelectedRow] = useState({});
  const [industrySectors, setIndustrySectors] = useState(null);
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(0);
  const navigate = useNavigate();

  const location = useLocation();

  // Helper function to get query parameters from the URL
  const useQuery = () => new URLSearchParams(location.search);
  const query = useQuery();

  // Extract the status parameter from the URL
  const statusFilter = query.get("status");
  // console.log("statusFilter: " + statusFilter);
  // Define the filter model based on the presence of the status parameter
  const filterModel = statusFilter
    ? {
        items: [
          {
            columnField: "status",
            operatorValue: "equals",
            value: statusFilter,
          },
        ],
      }
    : { items: [] };
  const approveData = async () => {
    try {
      const res = await UpdateUserStatusAdmaster(
        true,
        selectedDataValue.email,
        selectedDataValue.id
      );
      setShowApproveModel(false);
      setUpdateState((prev) => !prev);
      setShowEditRowModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const rejectData = async () => {
    if (!rejectMessage) {
      return toast.info("Please mention reject reason");
    }
    try {
      const res = await UpdateUserStatusAdmaster(
        false,
        selectedDataValue.email,
        selectedDataValue.id,
        rejectMessage
      );
      toast?.success(
        res?.data?.body ? res?.data?.body : "Updated successfully!"
      );
      setShowRejectionModel(false);
      setUpdateState((prev) => !prev);
      setShowEditRowModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      field: "instituteName",
      headerName: "Company Name",
      width: 200,
      renderCell: (params) => {
        return (
          <div
            // onClick={(_) =>
            //   navigate(`/estu-ads/discount-coupons/${params.row.id}`)
            // }
            className={
              "flex flex-row gap-2 align-center justify-center p-2 items-center"
            }
          >
            {params.row.profilePhoto ? (
              <div className="flex items-center justify-center shrink-0">
                <img
                  src={`${process.env.REACT_APP_IMAGE_BASEURL}${params.row.profilePhoto}`}
                  className="w-10 h-10 rounded-full"
                />
              </div>
            ) : (
              <div className="flex items-center justify-center">
                <img src={UsersIcon} className="w-10 h-10 rounded-full" />
              </div>
            )}
            <div>
              <p className="w-28 text-ellipsis whitespace-nowrap overflow-hidden">
                {params.row.instituteName}
              </p>
            </div>
          </div>
        );
      },
    },
    { field: "email", headerName: "Email", width: 150 },
    { field: "phoneNumber", headerName: "Mobile", width: 150 },
    { field: "website", headerName: "Website", width: 150 },
    {
      field: "couponCount",
      headerName: "Coupons",
      width: 100,
      renderCell: (params) => {
        const data = params.row;

        const row = {
          ...data,
        };
        return (
          <div
            className="flex flex-col justify-center w-full"
            onClick={(_) =>
              navigate(`/estu-ads/discount-coupons/${params.row.id}`, {
                state: {
                  lastPage: "/estu-ads",
                },
              })
            }
          >
            <p className="cursor-pointer underline w-full text-[#2cccd3] hover:text-[#1aa7ad] font-medium text-xl font-montserrat text-center">
              {data?.couponCount}
            </p>
          </div>
        );
      },
    },
    {
      field: "eventCount",
      headerName: "Events",
      width: 100,
      renderCell: (params) => {
        const data = params.row;

        const row = {
          ...data,
        };
        return (
          <div
            className="flex flex-col justify-center w-full"
            onClick={(_) =>
              navigate(`/estu-ads/discount-coupons/${params.row.id}`, {
                state: { from: "event", lastPage: "/estu-ads" },
              })
            }
          >
            <p className="cursor-pointer underline w-full text-[#2cccd3] hover:text-[#1aa7ad] font-medium text-xl font-montserrat text-center">
              {data?.eventCount}
            </p>
          </div>
        );
      },
    },
    {
      field: "ein",
      headerName: "EIN",
      width: 100,
      renderCell: (params) => {
        const data = params.row;
        // console.log(params);
        return (
          <p className={`${!data?.ein ? "text-center w-full" : ""}`}>
            {data?.ein || "---"}
          </p>
        );
      },
    },
    {
      field: "contractAddress",
      headerName: "Contract Address",
      width: 200,
      renderCell: (params) => {
        const data = params.row;

        const row = {
          ...data,
        };
        return (
          <div className="flex flex-col justify-center w-full">
            <p
              className="cursor-pointer  w-full font-montserrat text-center"
              title={`${
                data?.contractAddress?.address1
                  ? data?.contractAddress?.address1 + " "
                  : ""
              }${
                data?.contractAddress?.address2
                  ? data?.contractAddress?.address2 + " "
                  : ""
              }${
                data?.contractAddress?.city
                  ? data?.contractAddress?.city + " "
                  : ""
              }${
                data?.contractAddress?.state
                  ? data?.contractAddress?.state + " "
                  : ""
              }${
                data?.contractAddress?.zipCode
                  ? data?.contractAddress?.zipCode + " "
                  : ""
              }`}
            >
              {`${
                data?.contractAddress?.address1
                  ? data?.contractAddress?.address1 + " "
                  : ""
              }${
                data?.contractAddress?.address2
                  ? data?.contractAddress?.address2 + " "
                  : ""
              }${
                data?.contractAddress?.city
                  ? data?.contractAddress?.city + " "
                  : ""
              }${
                data?.contractAddress?.state
                  ? data?.contractAddress?.state + " "
                  : ""
              }${
                data?.contractAddress?.zipCode
                  ? data?.contractAddress?.zipCode + " "
                  : ""
              }`}
            </p>
          </div>
        );
      },
    },
    {
      field: "deliveryAddress",
      headerName: "Service Address",
      width: 200,
      renderCell: (params) => {
        const data = params.row;

        const row = {
          ...data,
        };
        return (
          <div className="flex flex-col justify-center w-full">
            <p
              className="cursor-pointer  w-full font-montserrat text-center"
              title={`${
                data?.deliveryAddress?.address1
                  ? data?.deliveryAddress?.address1 + " "
                  : ""
              }${
                data?.deliveryAddress?.address2
                  ? data?.deliveryAddress?.address2 + " "
                  : ""
              }${
                data?.deliveryAddress?.city
                  ? data?.deliveryAddress?.city + " "
                  : ""
              }${
                data?.deliveryAddress?.state
                  ? data?.deliveryAddress?.state + " "
                  : ""
              }${
                data?.deliveryAddress?.zipCode
                  ? data?.deliveryAddress?.zipCode + " "
                  : ""
              }`}
            >
              {`${
                data?.deliveryAddress?.address1
                  ? data?.deliveryAddress?.address1 + " "
                  : ""
              }${
                data?.deliveryAddress?.address2
                  ? data?.deliveryAddress?.address2 + " "
                  : ""
              }${
                data?.deliveryAddress?.city
                  ? data?.deliveryAddress?.city + " "
                  : ""
              }${
                data?.deliveryAddress?.state
                  ? data?.deliveryAddress?.state + " "
                  : ""
              }${
                data?.deliveryAddress?.zipCode
                  ? data?.deliveryAddress?.zipCode + " "
                  : ""
              }`}
            </p>
          </div>
        );
      },
    },
    {
      field: "serviceRadius",
      headerName: "Service Radius",
      width: 150,
      renderCell: (params) => {
        const data = params.row;
        return (
          <div className="w-full">
            <p className="text-center w-full">
              {params?.row?.serviceRadius ? params?.row?.serviceRadius : "---"}
            </p>
          </div>
        );
      },
    },
    {
      field: "companyType",
      headerName: "Company Type",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="w-full">
            <p className="text-center w-full">
              {params?.row?.serviceRadius ? params?.row?.companyType : "---"}
            </p>
          </div>
        );
      },
    },

    {
      field: "createdAt",
      headerName: "Created At (EST)",
      width: 150,
      // renderCell: (params) => {
      //   const options = {
      //     hour12: false,
      //     year: "numeric",
      //     month: "2-digit",
      //     day: "2-digit",
      //     hour: "2-digit",
      //     minute: "2-digit",
      //     second: "2-digit",
      //   };

      //   const formattedDateTime = new Date(params.row.createdAt).toLocaleString(
      //     "en-US",
      //     options
      //   );

      //   const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      //   let timeZoneStandard;

      //   // Determine the time zone standard based on the user's time zone
      //   if (userTimeZone.includes("America")) {
      //     timeZoneStandard = "US Time Standard";
      //   } else if (userTimeZone.includes("Asia/Kolkata")) {
      //     timeZoneStandard = "Indian Time Standard";
      //   } else {
      //     timeZoneStandard = userTimeZone; // Default to the actual time zone if not specifically handled
      //   }

      //   return (
      //     <p
      //       title={`PST time zone(Pacific Standard Time) ISO 8601 Format, ${timeZoneStandard}`}
      //     >
      //       {formattedDateTime}
      //     </p>
      //   );
      // },

      renderCell: (params) => (
        <p className="" title="yyyy-mm-dd:hh:mm:ss time format EST">
          {dayjs(params?.row?.createdAt)
            ?.tz("America/New_York")
            .format("YYYY-MM-DD :HH:mm:ss")}
        </p>
      ),
    },

    {
      field: "status",
      headerName: "status",
      width: 200,
      renderCell: (param) => {
        const data = param.row;

        const row = {
          ...data,
        };

        return (
          <div className={``}>
            {row?.status ? (
              // row?.status?.toLowerCase() === "pending" ? (
              // <div className={`flex flex-row gap-2`}>
              //   {" "}
              //   <CustomButton
              //     onClick={(_) => {
              //       setShowApproveModel(true);
              //       setSelectedDataValue(row);
              //     }}
              //   >
              //     Approve
              //   </CustomButton>
              //   <CustomButton
              //     onClick={(_) => {
              //       setShowRejectionModel(true);
              //       setSelectedDataValue(row);
              //     }}
              //     color="red"
              //     variant={"contained"}
              //   >
              //     Reject
              //   </CustomButton>
              // </div>

              // ) : (
              <p title={row?.reason || ""}>{row.status}</p>
            ) : // )
            null}
          </div>
        );
      },
    },
    {
      field: "edit",
      headerName: "Review",
      width: 150,
      renderCell: (params) => {
        const data = params?.row || {};

        const row = {
          ...data,
        };
        return (
          <div className="flex flex-col justify-center">
            <CustomButton
              onClick={() => {
                // setSelectedDataValue((prev) => row);
                // console.log(row);
                // editUser(row);
                setSelectedUserId(data.id);
                setSelectedDataValue(data);
                setShowEditRowModal(true);
                setSelectedRow(row);
              }}
              variant={"contained"}
            >
              {/* <EditIcon /> */}
              <PreviewIcon />
              <p className="inline-block w-fit ml-1">Review</p>
            </CustomButton>
          </div>
        );
      },
    },
  ];

  const fetchSectors = async () => {
    try {
      const allSectors = await getSectors();
      // console.log("allSectors", allSectors);
      const filteredSectors = allSectors.map((item) => {
        return {
          id: item.id,
          name: item.name,
        };
      });
      setIndustrySectors(filteredSectors);
    } catch (err) {
      console.log("err", err);
    }
  };
  const handleSelectionChange = (newSelectionModel) => {
    // console.log(newSelectionModel);
    let filteredRow = [];
    if (newSelectionModel.length > 1) {
      // Keep only the latest selected row
      filteredRow = Data.filter(
        (item) => item.id == newSelectionModel[newSelectionModel.length - 1]
      );
      setSelectionModel([newSelectionModel[newSelectionModel.length - 1]]);
    } else if (newSelectionModel.length > 0) {
      setSelectionModel(newSelectionModel);
      filteredRow = Data.filter((item) => item.id == newSelectionModel[0]);
    } else {
      setSelectionModel(newSelectionModel);
    }

    if (filteredRow.length) {
      // console.log("inside");
      setSelectedUserId(filteredRow[0].id);
      console.log(filteredRow);
      // setUpdateFormData({
      //   instituteName: filteredRow[0].instituteName,
      //   phoneNumber: filteredRow[0].phoneNumber,
      //   website: filteredRow[0].website,
      //   industry: filteredRow[0].industry,
      //   profilePhoto: filteredRow[0].profilePhoto,
      //   amazonAffiliate: filteredRow[0].amazonAffiliate,
      // });
      setUpdateFormData({
        instituteName: filteredRow[0].instituteName,
        phoneNumber: filteredRow[0].phoneNumber,
        website: filteredRow[0].website,
        industry: filteredRow[0].industry,
        profilePhoto: filteredRow[0].profilePhoto,
        amazonAffiliate: filteredRow[0].amazonAffiliate,
        deliveryAddress: filteredRow[0].deliveryAddress,
        contractAddress: filteredRow[0].contractAddress,
        ein: filteredRow[0]?.ein,
        email: filteredRow[0].email,
        companyType: filteredRow[0].companyType,
        serviceRadius: filteredRow[0].serviceRadius || 0,
      });
    }
    // console.log("filter", filteredRow);
  };

  const handleSelectionChangeonSectors = (newSelectionModel) => {
    // console.log(newSelectionModel);
    let filteredRow = [];
    if (newSelectionModel.length > 1) {
      // Keep only the latest selected row
      filteredRow = industrySectors.filter(
        (item) => item.id == newSelectionModel[newSelectionModel.length - 1]
      );
      setSectorSelectionModel([
        newSelectionModel[newSelectionModel.length - 1],
      ]);
    } else if (newSelectionModel.length > 0) {
      setSectorSelectionModel(newSelectionModel);
      filteredRow = industrySectors.filter(
        (item) => item.id == newSelectionModel[0]
      );
    } else {
      setSectorSelectionModel(newSelectionModel);
    }

    if (filteredRow.length) {
      // console.log("inside");
      setSelectedSectorId(filteredRow[0].id);
    }
    // console.log("filter", filteredRow);
  };

  const handleRowEditCommit = (params) => {
    const updatedRows = industrySectors.map((row) => {
      if (row.id === params.id) {
        return { ...row, name: params.value };
      }
      return row;
    });
    setIndustrySectors(updatedRows);
  };

  const handleAddRow = () => {
    const newId =
      industrySectors.length > 0
        ? industrySectors[industrySectors.length - 1].id + 1
        : 1;

    setIndustrySectors([...industrySectors, { id: newId, name: "" }]);
  };

  const deleteSector = async () => {
    if (sectorSelectionModel.length > 0) {
      try {
        const data = { sectorId: selectedSectorId };
        // console.log("userId", data);
        const res = await DeleteSector(data);
        fetchSectors();
      } catch (err) {
        console.log("err", err);
      }
    } else {
      alert("no sector selected");
    }
  };

  const updateSector = async (data) => {
    try {
      const res = await UpdateSector(data);
      fetchSectors();
    } catch (err) {
      console.log("err", err);
    }
  };

  const createSector = async (data) => {
    try {
      const res = await CreateSector(data);
      fetchSectors();
    } catch (err) {
      console.log("err", err);
    }
  };

  const updateSectorHandler = (params) => {
    const id = params.id;
    const filteredData = industrySectors.filter((item) => item.id == id);
    // console.log("past", filteredData[0].name, params.value);
    if (filteredData[0].name == "") {
      createSector({ name: params.value });
    } else {
      updateSector({ name: params.value, id: params.id });
    }
    console.log("params", params);
  };

  const fetchAdsManagerUser = async () => {
    try {
      const res = await GetAdsManagerUsers();
      // Providing default values for null fields

      const processedData = res?.map((user) => ({
        ...user,
        email: user.email || "",
        phoneNumber: user.phoneNumber || "",
        website: user.website || "",
        ein: user.ein || "",
        deliveryAddress: user.deliveryAddress || "",
        bussinessAddress: user.bussinessAddress || "",
      }));
      // setData(res);
      // console.log(processedData, "ppppp");
      setData(processedData);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const editUser = (row) => {
    if (selectionModel.length > 0) {
      setShowEditRowModal(true);
    } else {
      alert("no user selected");
    }
  };

  const deleteUser = async () => {
    if (selectionModel.length > 0) {
      try {
        const data = { userId: selectedUserId };
        // console.log("userId", data);
        const res = await DeleteAdmasterUser(data);
        fetchAdsManagerUser();
      } catch (err) {
        console.log("err", err);
      }
    } else {
      alert("no user selected");
    }
  };

  const handleRejectWithMessage = async () => {
    rejectData();
  };

  useEffect(() => {
    fetchAdsManagerUser();
  }, [showForm, updateRows, updateState]);

  useEffect(() => {
    fetchSectors();
  }, []);

  return (
    <div className={style.container}>
      <AcceptRejectModel
        handleClose={(_) => setShowApproveModel(false)}
        open={showApproveModel}
        onClick={approveData}
        title="Approve User"
      />
      <AcceptRejectModel
        showReject={true}
        title="Reject User"
        handleClose={(_) => setShowRejectionModel(false)}
        open={showRejectionModel}
        onClick={handleRejectWithMessage}
        rejectMessage={rejectMessage}
        setRejectMessage={setRejectMessage}
        reasonAdd={true}
      />
      <div className={style.header}>
        <div className={style.title}>All EstuAds user</div>
        <div className="flex justify-center items-center gap-4">
          {selectedUserId && selectionModel?.length > 0 && (
            <div
              className="hover:cursor-pointer hover:text-red-500 hover:scale-125 transition-all duration-300"
              onClick={deleteUser}
            >
              <DeleteOutlineIcon />
            </div>
          )}
          {/* <div className="hover:cursor-pointer" onClick={editUser}>
            <EditIcon />
          </div> */}
          <Button
            onClick={(_) => setShowEditSector(true)}
            bgColor="#2CCCD3"
            color="white"
            borderRadius="10px"
            icon={<UserIcon />}
            text={"Edit Industry"}
          />
          <Button
            onClick={(_) => setShowForm(true)}
            bgColor="#2CCCD3"
            color="white"
            borderRadius="10px"
            icon={<UserIcon />}
            text={"Create EstuAds"}
          />
        </div>
      </div>
      <SoftDialog
        title={step === 0 ? "Create new EstuAds user" : ""}
        open={showForm}
        handleClose={(_) => {
          setStep(0);
          setShowForm(false);
        }}
      >
        {step === 0 ? (
          <FormComponent
            setStep={setStep}
            step={step}
            formData={formData}
            setFormData={setFormData}
          />
        ) : (
          <SuccessFulllStep />
        )}
      </SoftDialog>
      <SoftDialog
        title={"Edit EstuAds User"}
        open={showEditRowModal}
        handleClose={(_) => {
          setShowEditRowModal(false);
        }}
      >
        <EditRowComponent
          userId={selectedUserId}
          setShowEditRowModal={setShowEditRowModal}
          formData={updateFormData}
          setFormData={setUpdateFormData}
          setUpdateRows={setUpdateRows}
          setShowApproveModel={setShowApproveModel}
          setShowRejectionModel={setShowRejectionModel}
          setSelectedDataValue={setSelectedDataValue}
          row={selectedRow}
          // setSelectedRow
        />
      </SoftDialog>
      <SoftDialog
        title={
          <div className={style.header}>
            <div className={style.title}>Industry Sectors</div>
            <div className="flex justify-center items-center gap-4 mr-12"></div>
          </div>
        }
        open={showEditSector}
        handleClose={(_) => {
          setShowEditSector(false);
        }}
      >
        <Box sx={{ height: "400px", width: "400px" }}>
          <DataGrid
            rows={industrySectors?.length > 0 ? industrySectors : []}
            columns={[
              { field: "name", headerName: "name", width: 200, editable: true },
            ]}
            pagination
            pageSize={10}
            onRowEditCommit={handleRowEditCommit}
            checkboxSelection
            disableSelectionOnClick
            selectionModel={sectorSelectionModel}
            onSelectionModelChange={handleSelectionChangeonSectors}
            onCellEditCommit={updateSectorHandler}
          />
          <div className="flex gap-2 items-center absolute bottom-8 left-8 cursor-pointer">
            <div onClick={handleAddRow}>
              <AddIcon />
            </div>
            <div className="hover:cursor-pointer" onClick={deleteSector}>
              <DeleteOutlineIcon />
            </div>
          </div>
        </Box>
      </SoftDialog>
      {loading ? (
        <div className={`flex flex-row justify-center align-center`}>
          <Loader />
        </div>
      ) : (
        <>
          <div
            className={`relative flex flex-column gap-2 align-center h-auto`}
          >
            <Box sx={{ height: "83vh", width: "100%" }}>
              {Data?.length > 0 ? (
                <>
                  <DataGrid
                    checkboxSelection
                    onSelectionModelChange={handleSelectionChange}
                    rows={Data}
                    columns={columns}
                    pagination
                    pageSize={10}
                    selectionModel={selectionModel}
                    filterModel={filterModel}
                  />
                </>
              ) : null}
            </Box>
          </div>
        </>
      )}
    </div>
  );
};

export default EstuAds;

const FormComponent = ({ formData, setFormData, setStep }) => {
  const fileInputRef = useRef(null);

  const handleSubmit = () => {
    SaveAdsManagerUsers(formData).then((Res) => {
      setStep((prev) => prev + 1);
      setFormData({
        instituteName: "",
        phoneNumber: "",
        email: "",
        password: "",
        website: "",
        industry: "",
        profilePhoto: "",
      });
    });
  };

  return (
    <div className={`flex flex-col gap-2 align-start mt-[15px]`}>
      <div className={`flex gap-3 align-center justify-evenly`}>
        <div className={`flex flex-col gap-4 align-start`}>
          <div className={`flex flex-col gap-1 align-start`}>
            <div className={style.form_title}>
              <p>Register user</p>
            </div>
            <div className={style.form__des}>
              <p>Fill the details of the Estu Ads user you want to create</p>
            </div>
          </div>
          <GenericFormComponent
            formConfig={FormConfig}
            formData={formData}
            setFormData={setFormData}
            handleSubmit={handleSubmit}
          />
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={(e) => ImageUpload(e, setFormData, "profilePhoto")}
          />
        </div>
        <div
          className={`flex flex-col p-3 h-max`}
          onClick={(_) => fileInputRef.current.click()}
        >
          <div className={style.uploadIcon}>
            {formData.profilePhoto !== "" ? (
              <img
                style={{ width: 100, height: 100 }}
                src={`${process.env.REACT_APP_IMAGE_BASEURL}${formData.profilePhoto}`}
              />
            ) : (
              <PhotoIcon />
            )}
          </div>
          <div className={style.uploadIcontext}>Upload from computer</div>
        </div>
      </div>
    </div>
  );
};

const SuccessFulllStep = () => {
  return (
    <div
      className={`flex flex-col p-5 gap-10 w-100`}
      style={{ alignItems: "center" }}
    >
      <div className={"w-[100px] h-[100px] mx-3"}>
        <CheckIcon />
      </div>
      <div className={style.successtext}>User created successfully!</div>
    </div>
  );
};
