import moment from "moment";
import { SaveDiscount } from "../../../api/api";
import GenericFormComponent from "../../../components/Form/Form";
import { DiscountFormConfig } from "./formConfig";
import { toast } from "react-toastify";

const FormComponent = ({ formData, setFormData, setStep }) => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("formData", formData);

    if (!formData.validFrom) {
      return toast.info("Valid from feild required");
    }
    if (!formData.validTo) {
      formData.validTo = "2099-12-31";
    }

    if (!isEndDateValid(formData.validFrom, formData.validTo)) {
      return toast.info("End date can not be before start date");
    }
    if (!isDateValidFromToday(formData.validTo)) {
      return toast.info("End date can not be acceptable before today!");
    }
    try {
      await SaveDiscount(formData);
      setStep(1);
    } catch (error) {
      console.error(error);
      toast.error(
        error?.response?.data?.error
          ? error?.response?.data?.error
          : "Something went wrong"
      );
    }
  };
  const isEndDateValid = (startDate, endDate) => {
    if (!startDate || !endDate) {
      return false; // Handle cases where either date is missing
    }

    const formattedStartDate = moment(startDate, "YYYY-MM-DD");
    const formattedEndDate = moment(endDate, "YYYY-MM-DD");

    // Check if endDate is after or equal to startDate (inclusive)
    return formattedEndDate.isSameOrAfter(formattedStartDate);
  };
  const isDateValidFromToday = (endDate) => {
    const formattedEndDate = moment(endDate, "YYYY-MM-DD");
    const today = moment();

    // Check if endDate is not before today
    return !formattedEndDate.isBefore(today);
  };

  return (
    <GenericFormComponent
      formConfig={DiscountFormConfig}
      formData={formData}
      setFormData={setFormData}
      handleSubmit={handleSubmit}
    />
  );
};

export default FormComponent;
